import React from "react";
import { Routes, Route } from "react-router-dom";
import ContactIndex from ".";

function ContactRouter({ rootLink, response, setResponse, alert, setAlert }) {
  return (
    <>
      <Routes>
        <Route
          path='/'
          element={
            <ContactIndex
              rootLink={rootLink}
              response={response}
              setResponse={setResponse}
              alert={alert}
              setAlert={setAlert}
            />
          }
        />
      </Routes>
    </>
  );
}

export default ContactRouter;
