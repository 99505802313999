import React, { useState, useEffect } from "react";
import NavUser from "../../components/nav/NavUser";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Phone,
  Smartphone,
  Tv,
  Lightbulb,
  Wifi,
  AccountBalance,
  CurrencyExchange,
  MoveDown,
  Receipt,
} from "@mui/icons-material";
import ReactTimeAgo from "react-time-ago";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import Loading1 from "../../components/Loading/Loading1";
//import '.style.css'
import { Pagination, Autoplay, Navigation } from "swiper";
import { Helmet } from "react-helmet";
import SiteHelmet from "../../components/Helmet/Helmet";

function UserIndex({ rootLink, response, setResponse, alert, setAlert }) {
  const [details, setDetails] = useState([]);
  const [recents, setRecents] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const user = Cookies.get("id");
  const [isLoading, setIsLoading] = useState(true);

  //USER DETAILS
  useEffect(() => {
    const formData = new FormData();
    formData.append("userDetails", user);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/checker.php", true);
    http.onload = function () {
      setDetails(JSON.parse(this.responseText));

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  }, [user]);
  //END OF USER DETAILS

  //LOAD TRANSACTIONS
  useEffect(() => {
    const formData = new FormData();
    formData.append("last5transactions", user);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/checker.php", true);
    http.onload = function () {
      setTransactions(JSON.parse(this.responseText));

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  }, [user]);
  //END OF LOAD TRANSACTIONS

  //LOAD RECENTS
  useEffect(() => {
    const formData = new FormData();
    formData.append("loadUpdates", "load");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/checker.php", true);
    http.onload = function () {
      setRecents(JSON.parse(this.responseText));

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  }, [user]);
  //END OF LOAD RECENTS

  //COMING SOON
  const comingSoon = () => {
    const msg = "Coming Soon";

    const arr = { status: "error", response: msg };
    setResponse(arr);
    setAlert(true);
  };
  //END OF COMING SOON

  return (
    <>
      <SiteHelmet page={"Dashboard"} />

      <div className='dashboard'>
        <div className='menu'>
          <h3>Hello {details.first_name}</h3>
          <div className='accountCard'>
            <div className='title'>Balance</div>
            <div className='amount'>
              &#8358;
              {details.balance}
            </div>

            {details.account_number !== "" && details.bank !== "" ? (
              <div className='dashAccDetails'>
                <div className='items'>
                  Account Number: <p>{details.account_number}</p>
                </div>
                <div className='items'>
                  Bank: <p>{details.bank}</p>
                </div>
              </div>
            ) : null}

            <div className='button'>
              <Link to={"../deposit"}>Deposit</Link>
            </div>
          </div>

          <div className='iconContainer'>
            <Link to={"../data"}>
              <div className='icon'>
                <Wifi />
              </div>
              Data
            </Link>
            <Link to={"../airtime"}>
              <div className='icon'>
                <Smartphone />
              </div>
              Airtime
            </Link>
            <Link to={"../tv"}>
              <div className='icon'>
                <Tv />
              </div>
              TV Subscription
            </Link>

            <Link to={"../betting"}>
              <div className='icon'>
                <Receipt />
              </div>
              Bet Funding
            </Link>

            <Link to={"../transfer"}>
              <div className='icon'>
                <MoveDown />
              </div>
              Transfer Fund
            </Link>
          </div>

          {recents.length > 0 ? (
            <div className='recents'>
              <div className='imageContainer'>
                <Swiper
                  pagination={{ dynamicBullets: true }}
                  modules={[Pagination, Autoplay, Navigation]}
                  className='mySwiper'
                  autoplay={{
                    delay: 10000,
                    disableOnInteraction: false,
                  }}
                  loop={true}
                  centeredSlides={true}
                  navigation={true}
                >
                  {recents.map((item) => {
                    return (
                      <>
                        <SwiperSlide key={item.ref}>
                          <>
                            {item.link === "" ? (
                              <img
                                src={"/resources/images/updates/" + item.image}
                              />
                            ) : (
                              <a href={item.link}>
                                <img
                                  src={
                                    "/resources/images/updates/" + item.image
                                  }
                                />
                              </a>
                            )}
                          </>
                        </SwiperSlide>
                      </>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          ) : null}
        </div>

        <div className='transactionsSection'>
          <h2>Transactions</h2>
          {transactions.length > 0 ? (
            transactions.map((item) => {
              return (
                <Link
                  to={"./transactions/" + item.ref}
                  key={item.ref}
                  className='dashboardTransactions'
                >
                  <div className='title'>
                    <div className='icon'>
                      {item.transaction === "Deposit" ||
                      item.transaction === "Debit" ? (
                        <AccountBalance />
                      ) : item.transaction === "Transfer" ? (
                        <MoveDown />
                      ) : item.transaction === "TV Subscription" ? (
                        <Tv />
                      ) : item.transaction === "Data" ? (
                        <Wifi />
                      ) : item.transaction === "Bet Funding" ? (
                        <Receipt />
                      ) : item.transaction === "Airtime" ? (
                        <Smartphone />
                      ) : null}
                    </div>

                    <div className='nameWraper'>
                      <div className='name'>{item.transaction}</div>
                      <div
                        className={
                          item.status === "Successful" ||
                          item.status === "Completed"
                            ? "status success"
                            : item.status === "Failed" ||
                              item.status === "Cancelled"
                            ? "status failed"
                            : "status pending"
                        }
                      >
                        {item.status}
                      </div>
                    </div>
                  </div>

                  <div className='details'>
                    <div className='amount'>&#8358;{item.amount}</div>
                    <div className='date'>
                      <ReactTimeAgo date={item.started} />
                    </div>
                  </div>
                </Link>
              );
            })
          ) : (
            <center style={{ padding: "20px" }}>
              <h3 style={{ margin: "10px" }}>Welcome to DataHub</h3>
              <i>You're yet to perform your first transaction</i>
            </center>
          )}

          {transactions.length > 5 ? (
            <div className='viewMore'>
              <Link to={"../transactions"}>View More</Link>
            </div>
          ) : null}
        </div>

        <Loading1 loading={isLoading} />
      </div>
    </>
  );
}

export default UserIndex;
