import { Snackbar, Alert, AlertProps, IconButton } from "@mui/material";
import { forwardRef, useState, useContext } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import Person from "@mui/icons-material/Person";
import Menu from "@mui/icons-material/Menu";
import Cookies from "js-cookie";
import { RootLink } from "../../App";

const SnackbarAlert = forwardRef(function SnackbarAlert(props, ref) {
  return <Alert elevation={6} ref={ref} {...props} />;
});

function Nav({ response, setResponse, alert, setAlert }) {
  const [sideBar, setSideBar] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const rootLink = useContext(RootLink);
  const [dropMenu, setDropMenu] = useState(false);
  const navigate = useNavigate();

  if (alert === true) {
    setTimeout(() => {
      setAlert(false);
    }, 5000);
  }

  //SWITCH TOGGLE DROP MENU
  const toggleDropMenu = () => {
    if (dropMenu === true) {
      setDropMenu(false);
    } else {
      setDropMenu(true);
    }
  };
  //END OF SWITCH TOGGLE DROP MENU

  return (
    <>
      <nav>
        <div className='adminLogo general' onClick={() => navigate("/")}>
          <img src={rootLink + "resources/logo/zicopawa.png"} />
        </div>

        <IconButton size='large' className='adminHamburger'>
          <Menu onClick={() => toggleDropMenu()} />
        </IconButton>

        <div className='list'>
          <Link to='/contact' onClick={() => setDropMenu(false)}>
            Contact Us
          </Link>
          <Link to='/login' onClick={() => setDropMenu(false)}>
            Log In
          </Link>
          <Link
            to='/register'
            className='signUp'
            onClick={() => setDropMenu(false)}
          >
            Sign Up
          </Link>
        </div>
      </nav>

      <div className={dropMenu ? "dropMenu homeMenu" : "dropMenu disabled"}>
        <Link to='/contact' onClick={() => setDropMenu(false)}>
          Contact Us
        </Link>
        <Link to='/login' onClick={() => setDropMenu(false)}>
          Log In
        </Link>
        <Link
          to='/register'
          className='signUp'
          onClick={() => setDropMenu(false)}
        >
          Sign Up
        </Link>
      </div>

      <Snackbar
        open={alert}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <SnackbarAlert color={response.status}>
          {response.response}
        </SnackbarAlert>
      </Snackbar>
    </>
  );
}

export default Nav;
