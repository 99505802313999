import React, { useState } from "react";
import TransactionsIndex from ".";
import { Routes, Route } from "react-router-dom";
import ViewTransaction from "./view";
import { Helmet } from "react-helmet";
import SiteHelmet from "../../../components/Helmet/Helmet";

function TransactionsRouter({
  rootLink,
  response,
  setResponse,
  alert,
  setAlert,
}) {
  return (
    <>
      <SiteHelmet page={"Transactions"} />

      <Routes>
        <Route
          path='/'
          element={
            <TransactionsIndex
              rootLink={rootLink}
              response={response}
              setResponse={setResponse}
              alert={alert}
              setAlert={setAlert}
            />
          }
        />
        <Route
          path='/:id'
          element={
            <ViewTransaction
              rootLink={rootLink}
              response={response}
              setResponse={setResponse}
              alert={alert}
              setAlert={setAlert}
            />
          }
        />
      </Routes>
    </>
  );
}

export default TransactionsRouter;
