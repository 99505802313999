import { useEffect, useState, forwardRef } from "react";
import Cookies from "js-cookie";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TextField, Paper, Stack, Snackbar, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ForgetResponse from "./ForgetResponse";
import { Email } from "@mui/icons-material";

function ForgottenPassword({ rootLink, setResponse, setAlert, setForgotten }) {
  const [email, setEmail] = useState("");
  const [btnLoader, setBtnLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [action, setAction] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (email === "") {
      const msg = "Email is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    } else if (!isEmail(email)) {
      const msg = "Invalid email";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }
    if (email && isEmail(email)) {
      setBtnLoader(true);

      const formData = new FormData();
      formData.append("forgottenEmail", email);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/access/forgotten.php", true);
      http.onload = function () {
        const jsonData = JSON.parse(this.responseText);

        // window.alert(this.responseText);
        if (jsonData.status === "success") {
          setResponse(jsonData);
          setAlert(true);

          setTimeout(() => {
            setAction(true);
          }, 1000);
        } else {
          setResponse(jsonData);
          setAlert(true);
          setBtnLoader(false);
        }
      };
      http.send(formData);
    }
  };
  //END OF FORM SUBMIT

  function isEmail(email) {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  }

  return (
    <div className='loginContainer'>
      <img
        onClick={() => navigate("/")}
        className='onlyLogo'
        src='/resources/logo/zicopawa.png'
      />

      {action === false ? (
        <div className='loginWrapper'>
          <h2>Forgotten Password</h2>

          <form onSubmit={formSubmit}>
            <label htmlFor='email'>Email</label>{" "}
            <input
              type='text'
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Email'
              fullWidth
              name='email'
            />
            <button
              loading={btnLoader ? true : false}
              className='width100'
              onClick={formSubmit}
            >
              Proceed
            </button>
          </form>

          <br />

          <div className='link' onClick={() => setForgotten(false)}>
            Log In
          </div>

          <Link className='link' to={"/register"}>
            Register
          </Link>
        </div>
      ) : (
        <ForgetResponse
          rootLink={rootLink}
          email={email}
          setResponse={setResponse}
          setAlert={setAlert}
          setAction={setAction}
        />
      )}
    </div>
  );
}

export default ForgottenPassword;
