import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useState, useEffect } from "react";
import Loading1 from "../../Loading/Loading1";

function EditPackage({
  modal,
  rootLink,
  setResponse,
  setModal,
  setAlert,
  reload,
  cable,
  reference,
}) {
  const [packageName, setPackageName] = useState("");
  const [packageCode, setPackageCode] = useState("");
  const [packagePrice, setPackagePrice] = useState("");
  const [cableCode, setCableCode] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);

  useEffect(() => {
    loadPackage();
  }, []);

  //LOAD PACKAGE
  const loadPackage = () => {
    const formData = new FormData();
    formData.append("loadPackage", reference);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/tvPackage.php", true);
    http.onload = function () {
      setPackageName(JSON.parse(this.responseText).package);
      setPackageCode(JSON.parse(this.responseText).code);
      setPackagePrice(JSON.parse(this.responseText).price);
      setTimeout(
        () => setCableCode(JSON.parse(this.responseText).cable_code),
        1000
      );
    };
    http.send(formData);
  };
  //END OF LOAD PACKAGE

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (packageName === "") {
      const msg = "Package name is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (packageCode === "") {
      const msg = "Package code is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (packagePrice === "") {
      const msg = "Package price is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (cableCode === "") {
      const msg = "An error occurred";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (packageName && packageCode && packagePrice && cableCode) {
      setLoadingBtn(true);

      const formData = new FormData();
      formData.append("editPackage", reference);
      formData.append("cable", cable);
      formData.append("packageName", packageName);
      formData.append("packageCode", packageCode);
      formData.append("packagePrice", packagePrice);
      formData.append("cableCode", cableCode);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/admin/tvPackage.php", true);
      http.onload = function () {
        setResponse(JSON.parse(this.responseText));
        setModal(false);
        setAlert(true);
        setLoadingBtn(false);
        reload();
      };
      http.send(formData);
    }
  };
  //END OF FORM SUBMIT

  return (
    <Dialog open={modal} fullWidth>
      <DialogTitle sx={{ textAlign: "center" }}>Edit Package</DialogTitle>

      {cableCode === "" ? (
        <Loading1 loading={isLoading} />
      ) : (
        <>
          <DialogContent>
            <label htmlFor='name'>Package Name</label>
            <input
              placeholder='Package Name'
              type='text'
              onChange={(e) => setPackageName(e.target.value)}
              sx={{ marginTop: "5px" }}
              value={packageName}
              name='name'
              required
            />

            <label htmlFor='code'>Package Code</label>
            <input
              placeholder='Package Code'
              type='text'
              onChange={(e) => setPackageCode(e.target.value)}
              value={packageCode}
              name='code'
              required
            />

            <label htmlFor='price'>Package Price</label>
            <input
              placeholder='Package Price'
              type='number'
              onChange={(e) => setPackagePrice(e.target.value)}
              value={packagePrice}
              name='price'
              required
            />

            <DialogActions
              sx={{
                justifyContent: "center",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              <Button
                color='error'
                variant='contained'
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={loadingBtn ? true : false}
                onClick={formSubmit}
                variant='contained'
              >
                Edit
              </LoadingButton>
            </DialogActions>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}

export default EditPackage;
