import { useEffect, useState, forwardRef } from "react";
import Cookies from "js-cookie";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TextField, Paper, Stack, Snackbar, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ArrowBack } from "@mui/icons-material";

function ForgetResponse({ rootLink, setResponse, setAlert, email, setAction }) {
  const [btnLoader, setBtnLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [timer, setTimer] = useState(60);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    handleTimer();
    window.scrollTo(0, 0);
  }, [pathname]);

  //FORM SUBMIT
  const resendEmail = () => {
    const formData = new FormData();
    formData.append("forgottenEmail", email);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/access/forgotten.php", true);
    http.onload = function () {
      setResponse(JSON.parse(this.responseText));
      setAlert(true);

      setTimer(60);
      handleTimer();

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF FORM SUBMIT

  //TIMER COUNT
  const handleTimer = () => {
    setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);
  };
  //END OF TIMER COUNT

  function isEmail(email) {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  }

  return (
    <div className='loginContainer'>
      <img
        onClick={() => navigate("/")}
        className='onlyLogo'
        src='/resources/logo/zicopawa.png'
      />

      <div className='loginWrapper'>
        <h2>Confirmation Link Sent Successfully</h2>

        <center>
          We have successfully sent an email containing the confirmation link to
          this <b>{email}</b>. Click on the link in the message so that we can
          verify the validity of the email.
          <br />
          <br />
          {timer >= 0 ? (
            <div className='button loading'>Resend</div>
          ) : (
            <div className='button' onClick={resendEmail}>
              Resend
            </div>
          )}
        </center>
        <br />
        <Link to={"/login"}>
          <ArrowBack />
        </Link>
      </div>
    </div>
  );
}

export default ForgetResponse;
