import React, { useState, useEffect } from "react";
import ConfirmBetting from "../../../components/modal/User/Betting/Confirm";
import Cookies from "js-cookie";
import { ArrowBack } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SiteHelmet from "../../../components/Helmet/Helmet";
import Loading1 from "../../../components/Loading/Loading1";

function BettingIndex({ rootLink, response, setResponse, alert, setAlert }) {
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState("");
  const [networkCode, setCompanyCode] = useState("");
  const [customerID, setCustomerID] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [amount, setAmount] = useState("");
  const [smallLoader, setSmallLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const user = Cookies.get("id");
  const navigate = useNavigate();

  useEffect(() => {
    loadCompanies();
  }, []);

  //LOAD COMPANIES
  const loadCompanies = () => {
    const formData = new FormData();
    formData.append("loadCompanies", "load");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/user/betting.php", true);
    http.onload = function () {
      setCompanies(JSON.parse(this.responseText));
      setCompanyCode(JSON.parse(this.responseText).company_code);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD COMPANIES

  //LOAD CUSTOMER DETAILS
  const loadCustomerDetails = () => {
    setCustomerName("");
    if (customerID.length >= 5) {
      if (company === "") {
        const msg = "Betting company is required";

        const arr = { status: "error", response: msg };
        setResponse(arr);
        setAlert(true);
      }

      if (customerID === "") {
        const msg = "Customer ID is required";

        const arr = { status: "error", response: msg };
        setResponse(arr);
        setAlert(true);
      }

      if (amount === "") {
        const msg = "Amount is required";

        const arr = { status: "error", response: msg };
        setResponse(arr);
        setAlert(true);
      }

      if (isNaN(amount)) {
        const msg = "Amount is invalid";

        const arr = { status: "error", response: msg };
        setResponse(arr);
        setAlert(true);
      }

      if (isNaN(customerID)) {
        const msg = "Customer ID is invalid";

        const arr = { status: "error", response: msg };
        setResponse(arr);
        setAlert(true);
      }

      if (
        company &&
        customerID &&
        amount &&
        !isNaN(amount) &&
        !isNaN(customerID)
      ) {
        setSmallLoader(true);
        const formData = new FormData();
        formData.append("loadCustomerDetails", customerID);
        formData.append("company", company);

        //AJAX
        let http = new XMLHttpRequest();
        http.open("POST", rootLink + "backend/user/betting.php", true);
        http.onload = function () {
          const jsonData = JSON.parse(this.responseText);

          if (jsonData.status === "success") {
            setCustomerName(jsonData.response);
          } else {
            setResponse(jsonData);
            setAlert(true);
          }

          setTimeout(() => {
            setSmallLoader(false);
          }, 1000);
        };
        http.send(formData);
      }
    }
  };
  //END OF LOAD CUSTOMER DETAILS

  //HANDLE CUSTOMER NAME
  const handleCustomerName = (input) => {
    setCustomerName("");

    if (input.length >= 5) {
      setSmallLoader(true);
      const formData = new FormData();
      formData.append("loadCustomerDetails", input);
      formData.append("company", company);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/user/betting.php", true);
      http.onload = function () {
        const jsonData = JSON.parse(this.responseText);

        if (jsonData.status === "success") {
          setCustomerName(jsonData.response);
        }

        setTimeout(() => {
          setSmallLoader(false);
        }, 1000);
      };
      http.send(formData);
    }
  };
  //END OF HANDLE CUSTOMER NAME

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (company === "") {
      const msg = "Betting company is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (isNaN(amount)) {
      const msg = "Amount is invalid";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (amount === "") {
      const msg = "Amount is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (amount < 100) {
      const msg = "Minimum amount is 100";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (customerID === "") {
      const msg = "Customer ID is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (company && !isNaN(amount) && amount && amount >= 100 && customerID) {
      setModal(true);
    }
  };
  //END OF FORM SUBMIT

  return (
    <>
      <SiteHelmet page={"Bet Funding"} />

      <div className='firstWrapper'>
        <div className='backButton'>
          <IconButton onClick={() => navigate("../../")}>
            <ArrowBack />
          </IconButton>
          <h1 className='firstHeader'>Bet Funding</h1>
        </div>

        <div className='firstContent padding10'>
          <form onSubmit={formSubmit}>
            <label htmlFor='company'>Betting Company</label>
            <select
              onChange={(e) => {
                setCompany(e.target.value);
              }}
              name='company'
            >
              <option value=''>Select Betting Company</option>
              {companies.map((item) => {
                return (
                  <React.Fragment key={item.ref}>
                    <option disabled={item.disabled === "true" ? true : false}>
                      {item.company}
                    </option>
                  </React.Fragment>
                );
              })}
            </select>

            <label htmlFor='amount'>Amount</label>
            <input
              type='number'
              onChange={(e) => setAmount(e.target.value)}
              placeholder='Amount'
              name='amount'
            />

            <label htmlFor='customerID'>Customer ID</label>
            <input
              type='text'
              onChange={(e) => {
                setCustomerID(e.target.value);
                handleCustomerName(e.target.value);
              }}
              onBlur={loadCustomerDetails}
              placeholder='Customer ID'
              name='customerID'
            />

            {!smallLoader ? (
              <div
                className={
                  customerName === "" ? "cardName hide" : "cableName show"
                }
              >
                {customerName}
              </div>
            ) : (
              <div className='smallLoader'></div>
            )}

            <br />

            <button
              className={
                company === "" ||
                customerID === "" ||
                amount === "" ||
                isNaN(customerID) === "" ||
                isNaN(amount) ||
                customerName === ""
                  ? "width100 disabled"
                  : "width100"
              }
              disabled={
                company === "" ||
                customerID === "" ||
                amount === "" ||
                isNaN(customerID) === "" ||
                isNaN(amount) ||
                customerName === ""
                  ? true
                  : false
              }
              onClick={formSubmit}
            >
              Fund
            </button>
          </form>
        </div>

        {modal ? (
          <ConfirmBetting
            company={company}
            amount={amount}
            customerID={customerID}
            customerName={customerName}
            modal={modal}
            setModal={setModal}
            setResponse={setResponse}
            setAlert={setAlert}
            rootLink={rootLink}
            user={user}
          />
        ) : null}
      </div>

      <Loading1 loading={isLoading} />
    </>
  );
}

export default BettingIndex;
