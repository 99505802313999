import React, { useState, useEffect } from "react";
import Nav from "../../../components/nav/Nav";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import {
  ArrowBack,
  Email,
  Facebook,
  Instagram,
  LocationOn,
  Phone,
  Telegram,
  Twitter,
  WhatsApp,
} from "@mui/icons-material";
import Sender from "../../../components/SendMessage/Sender";
import Loading1 from "../../../components/Loading/Loading1";
import { Helmet } from "react-helmet";
import SiteHelmet from "../../../components/Helmet/Helmet";

function ContactIndex({ rootLink, response, setResponse, alert, setAlert }) {
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    loadContent();
  }, []);

  //LOAD CONTENT
  const loadContent = () => {
    const formData = new FormData();
    formData.append("loadFooterContent", "load");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/checker.php", true);
    http.onload = function () {
      setContent(JSON.parse(this.responseText));

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD CONTENT

  return (
    <>
      <SiteHelmet page={"Contact Us"} />

      <div className='firstWrapper'>
        <div className='backButton'>
          <IconButton onClick={() => navigate("/")}>
            <ArrowBack />
          </IconButton>
          <h1 className='firstHeader'>Contact Us</h1>
        </div>

        {content.length > 0 ? (
          <>
            <div className='firstContent'>
              <div className='contactLocation'>
                {content[2].name !== "" ? (
                  <div className='item'>
                    <LocationOn />
                    <p>{content[2].name}</p>
                  </div>
                ) : null}

                {content[1].name !== "" ? (
                  <div className='item'>
                    <Phone />
                    <p>
                      <a href={"tel:" + content[1].name}>{content[1].name}</a>
                    </p>
                  </div>
                ) : null}

                {content[0].name !== "" ? (
                  <div className='item'>
                    <Email />
                    <p>
                      <a href={"mailto:" + content[0].name}>
                        {content[0].name}
                      </a>
                    </p>
                  </div>
                ) : null}
              </div>
            </div>

            <div className='firstContent' style={{ marginTop: "30px" }}>
              <div className='contactLocation'>
                {content.map((item) => {
                  return (
                    <React.Fragment key={item.id}>
                      {item.link !== "" ? (
                        <div className='item'>
                          {item.platform === "whatsapp" ? (
                            <WhatsApp sx={{ color: "green" }} />
                          ) : item.platform === "facebook" ? (
                            <Facebook sx={{ color: "blue" }} />
                          ) : item.platform === "twitter" ? (
                            <Twitter sx={{ color: "rgb(0, 191, 255)" }} />
                          ) : item.platform === "telegram" ? (
                            <Telegram sx={{ color: "rgb(0, 150, 230)" }} />
                          ) : item.platform === "instagram" ? (
                            <Instagram sx={{ color: "rgb(255, 0, 0)" }} />
                          ) : null}

                          <a
                            className={
                              item.platform === "whatsapp"
                                ? "whatsapp"
                                : item.platform === "facebook"
                                ? "facebook"
                                : item.platform === "twitter"
                                ? "twitter"
                                : item.platform === "telegram"
                                ? "telegram"
                                : item.platform === "instagram"
                                ? "instagram"
                                : null
                            }
                            href={item.link}
                          >
                            {item.name}
                          </a>
                        </div>
                      ) : null}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </>
        ) : null}

        <div className='firstContent' style={{ marginTop: "30px" }}>
          <center>
            <h2 style={{ margin: "10px" }}>Send Us A Message</h2>
          </center>
          <Sender
            setAlert={setAlert}
            setResponse={setResponse}
            rootLink={rootLink}
          />
        </div>
        <Loading1 loading={isLoading} />
      </div>
    </>
  );
}

export default ContactIndex;
