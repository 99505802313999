import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Loading1 from "../../../components/Loading/Loading1";

function ViewTransaction({ rootLink, response, setResponse, alert, setAlert }) {
  const [content, setContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [orderID, setOrderID] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    loadTransaction();
  }, []);

  useEffect(() => {
    if (content.status !== "") {
      if (content.status === "Pending" || content.status === "Processing") {
        loadQuery();
      }
    }
  }, [content.status]);

  //LOAD TRANSACTION DETAILS
  const loadTransaction = () => {
    const formData = new FormData();
    formData.append("loadTransaction", id);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/user/transactions.php", true);
    http.onload = function () {
      setContent(JSON.parse(this.responseText));
      setOrderID(JSON.parse(this.responseText).order_id);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD TRANSACTION DETAILS

  //QUERYLOAD TRANSACTION
  const loadQuery = () => {
    const formData = new FormData();
    formData.append("queryTransaction", orderID);
    formData.append("ref", id);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/query/data.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);

      if (jsonData.status === "success") {
        loadTransaction();
      }
    };
    http.send(formData);
  };
  //END OF LOAD QUERY TRANSACTION

  //QUERY TRANSACTION
  const query = () => {
    const formData = new FormData();
    formData.append("queryTransaction", orderID);
    formData.append("ref", id);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/query/data.php", true);
    http.onload = function () {
      setResponse(JSON.parse(this.responseText));
      setAlert(true);

      loadTransaction();
    };
    http.send(formData);
  };
  //END OF QUERY TRANSACTION

  return (
    <div className='transactionView'>
      <IconButton onClick={() => navigate("../")}>
        <ArrowBack />
      </IconButton>

      <div className='head'>
        <div className='transaction'>{content.transaction}</div>
        <div className='amount'>&#8358;{content.amount}</div>
        <div
          className={
            content.status === "Completed" || content.status === "Successful"
              ? "status success"
              : content.status === "Failed" || content.status === "Cancelled"
              ? "status failed"
              : "status"
          }
        >
          {content.status}
        </div>
      </div>
      <div className='body'>
        <div className='items'>
          <p>Transaction ID:</p>
          {content.ref}
        </div>

        {content.method ? (
          <div className='items'>
            <p>Method:</p>
            {content.method}
          </div>
        ) : null}
        {content.beneficiary ? (
          content.transaction === "Deposit" &&
          content.method === "Fund Transfer" ? (
            <div className='items'>
              <p>Sender:</p>
              {content.beneficiary}
            </div>
          ) : content.method === "Bank Transfer" ? (
            <div className='items'>
              <p>Sender:</p>
              {content.receiver}
            </div>
          ) : (
            <div className='items'>
              <p>Beneficiary:</p>
              {content.beneficiary}
            </div>
          )
        ) : null}

        {content.receiver && content.transaction === "Transfer" ? (
          <div className='items'>
            <p>Receiver's Name:</p>
            {content.receiver}
          </div>
        ) : null}

        {content.operator && content.method !== "Bank Transfer" ? (
          <div className='items'>
            <p>Network:</p>
            {content.operator}
          </div>
        ) : null}

        {content.order_id && content.method === "Bank Transfer" ? (
          <div className='items'>
            <p>Session ID:</p>
            {content.order_id}
          </div>
        ) : null}

        {content.package ? (
          <div className='items'>
            <p>Data Plan:</p>
            {content.package}
          </div>
        ) : null}
        <div className='items'>
          <p>Date:</p>
          {content.started}
        </div>
      </div>

      <center>
        {content.order_id !== "" ? (
          content.status === "Pending" || content.status === "Processing" ? (
            <button onClick={query}>Query</button>
          ) : null
        ) : null}
      </center>

      <Loading1 loading={isLoading} />
    </div>
  );
}

export default ViewTransaction;
