import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import SearchUser from "../../../components/modal/Users/Search";
import { Person, ArrowBack } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import ReactPaginate from "react-paginate";
import Loading1 from "../../../components/Loading/Loading1";

function UsersIndex({ rootLink, response, setResponse, alert, setAlert }) {
  const [content, setContent] = useState([]);
  const [users, setUsers] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [active, setActive] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [ban, setBan] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadUsers();
    loadAllUsers();
  }, []);

  //LOAD CONTENT
  const loadUsers = () => {
    const formData = new FormData();
    formData.append("loadUsersNumber", "load");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/users.php", true);
    http.onload = function () {
      setUsers(JSON.parse(this.responseText).total_users);
      setActive(JSON.parse(this.responseText).active_users);
      setBan(JSON.parse(this.responseText).ban_users);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD CONTENT

  //LOAD USERS
  const loadAllUsers = () => {
    const formData = new FormData();
    formData.append("loadAllUsers", "load");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/users.php", true);
    http.onload = function () {
      setAllUsers(JSON.parse(this.responseText));

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD USERS

  //PAGINATION
  const itemsPerPage = 10;
  const pagesVisited = pageNumber * itemsPerPage;

  const displayItems = allUsers
    .slice(pagesVisited, pagesVisited + itemsPerPage)
    .map((item) => {
      return (
        <Link to={"../" + item.username} key={item.user_id} className='result'>
          <div className='left'>
            <div className='name'>{item.first_name + " " + item.last_name}</div>
            <div className='username'>{item.username}</div>
          </div>
          <div className='right balance'>&#8358;{item.balance}</div>
        </Link>
      );
    });

  const pageCount = Math.ceil(allUsers.length / itemsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  //END OF PAGINATION

  return (
    <>
      <div className='firstWrapper'>
        <SearchUser
          rootLink={rootLink}
          setContent={setContent}
          content={content}
        />
        <div className='backButton'>
          <IconButton onClick={() => navigate("../../")}>
            <ArrowBack />
          </IconButton>
          <h1 className='firstHeader'>Users</h1>
        </div>

        <div className='firstContent'>
          <center>
            <h1>Total Users: {users === "" ? 0 : users}</h1>
            <h2>Active Users: {active === "" ? 0 : active}</h2>
            <h2 style={{ color: "red" }}>
              Banned Users: {ban === "" ? 0 : ban}
            </h2>
          </center>
        </div>

        <div style={{ marginTop: "30px" }} className='firstContent'>
          <center style={{ padding: "10px" }}>
            <h2>All Users</h2>
          </center>
          <div className='usersList'>{displayItems}</div>

          <br />
          {allUsers.length < itemsPerPage + 1 ? null : (
            <div className='pagination'>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationContainer"}
                previousLinkClassName={"prevBtn"}
                nextLinkClassName={"nextBtn"}
                activeClassName={"activePagination"}
              />
            </div>
          )}
        </div>
      </div>

      <Loading1 loading={isLoading} />
    </>
  );
}

export default UsersIndex;
