import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

function EditDetails({
  rootLink,
  setResponse,
  setAlert,
  username,
  modal,
  setModal,
  loadDetails,
  loadTransactions,
}) {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [availEmail, setAvailEmail] = useState(true);
  const [availPhone, setAvailPhone] = useState(true);
  const [phone, setPhone] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [action, setAction] = useState("");

  useEffect(() => {
    loadContent();
  }, []);

  //LOAD CONTENT
  const loadContent = () => {
    const formData = new FormData();
    formData.append("loadDetails", username);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/users.php", true);
    http.onload = function () {
      setFname(JSON.parse(this.response).first_name);
      setLname(JSON.parse(this.response).last_name);
      setEmail(JSON.parse(this.response).email);
      setPhone(JSON.parse(this.response).phone);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //LOAD CONTENT

  //EMAIL AVAILABILITY
  const available_email = (input) => {
    const formData = new FormData();
    formData.append("emailUserCheck", input);
    formData.append("username", username);
    const ajax = new XMLHttpRequest();
    ajax.open("POST", rootLink + "backend/access/register.php", true);
    ajax.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      if (jsonData.available === "yes") {
        setAvailEmail(true);
      } else {
        setAvailEmail(false);
      }
    };
    ajax.send(formData);
  };
  //END OF EMAIL AVAILABILITY

  //PHONE AVAILABILITY
  const available_phone = (input) => {
    const formData = new FormData();
    formData.append("phoneUserCheck", input);
    formData.append("username", username);
    const ajax = new XMLHttpRequest();
    ajax.open("POST", rootLink + "backend/access/register.php", true);
    ajax.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      if (jsonData.available === "yes") {
        setAvailPhone(true);
      } else {
        setAvailPhone(false);
      }
    };
    ajax.send(formData);
  };
  //END OF PHONE AVAILABILITY

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (fname === "") {
      const msg = "First name is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (lname === "") {
      const msg = "Last name is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (email === "") {
      const msg = "Email is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (availEmail === false) {
      const msg = "Email already exist";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (!isEmail(email)) {
      const msg = "Email is invalid";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (phone === "") {
      const msg = "Phone number is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (isNaN(phone)) {
      const msg = "Phone number is invalid";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (availPhone === false) {
      const msg = "Phone number already exist";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (
      fname &&
      lname &&
      email &&
      isEmail(email) &&
      availEmail === true &&
      phone &&
      !isNaN(phone) &&
      availPhone === true
    ) {
      setLoadingBtn(true);

      const formData = new FormData();

      formData.append("EditUserDetails", username);
      formData.append("fname", fname);
      formData.append("lname", lname);
      formData.append("email", email);
      formData.append("phone", phone);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/admin/users.php", true);
      http.onload = function () {
        const jsonData = JSON.parse(this.responseText);

        setResponse(jsonData);
        loadDetails();
        setModal(false);
        setAlert(true);
        setLoadingBtn(false);
        loadTransactions();
      };
      http.send(formData);
      //END OF AJAX
    }
  };
  //END OF FORM SUBMIT

  function isEmail(email) {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  }

  return (
    <Dialog open={modal} fullWidth>
      <DialogTitle sx={{ textAlign: "center" }}>Edit User Details</DialogTitle>

      <>
        <DialogContent>
          <label htmlFor='fname'>First Name</label>
          <input
            type='text'
            onChange={(e) => setFname(e.target.value)}
            name='fname'
            value={fname}
            placeholder='First Name'
          />

          <label htmlFor='lname'>Last Name</label>
          <input
            type='text'
            onChange={(e) => setLname(e.target.value)}
            name='lname'
            value={lname}
            placeholder='Last Name'
          />

          <label htmlFor='email'>Email</label>
          <input
            type='email'
            onChange={(e) => {
              available_email(e.target.value);
              setEmail(e.target.value);
            }}
            name='email'
            value={email}
            placeholder='Email'
          />

          <label htmlFor='phone'>Phone Number</label>
          <input
            type='phone'
            onChange={(e) => {
              available_phone(e.target.value);
              setPhone(e.target.value);
            }}
            name='phone'
            value={phone}
            placeholder='Phone Number'
          />

          <DialogActions
            sx={{
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Button
              color='error'
              variant='contained'
              onClick={() => {
                setModal(false);
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loadingBtn ? true : false}
              onClick={formSubmit}
              variant='contained'
            >
              Save
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </>
    </Dialog>
  );
}

export default EditDetails;
