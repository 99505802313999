import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

function TransactionStatus({
  rootLink,
  setResponse,
  setAlert,
  id,
  modal,
  setModal,
  loadContent,
  status,
  username,
  orderID,
}) {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    setLoadingBtn(true);

    const formData = new FormData();
    formData.append("UpdateStatus", id);
    formData.append("status", status);
    formData.append("username", username);
    formData.append("orderID", orderID);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/transactions.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);

      setResponse(jsonData);
      loadContent();
      setModal(false);
      setAlert(true);
      setLoadingBtn(false);

      if (jsonData.status === "success") {
        setTimeout(() => {
          navigate("/admin/users/" + username);
        }, 3000);
      }
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF FORM SUBMIT

  return (
    <Dialog open={modal} fullWidth>
      <DialogTitle sx={{ textAlign: "center" }}>
        You're about to set this transaction status as <strong>{status}</strong>
      </DialogTitle>

      <>
        <DialogContent>
          <DialogActions
            sx={{
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Button
              variant='contained'
              onClick={() => {
                setModal(false);
              }}
              color={status === "Failed" ? "primary" : "error"}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loadingBtn ? true : false}
              onClick={formSubmit}
              variant='contained'
              color={status === "Failed" ? "error" : "primary"}
            >
              Continue
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </>
    </Dialog>
  );
}

export default TransactionStatus;
