import { Snackbar, Alert, AlertProps, IconButton } from "@mui/material";
import { forwardRef, useState, useContext } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import Person from "@mui/icons-material/Person";
import Menu from "@mui/icons-material/Menu";
import Cookies from "js-cookie";
import { RootLink } from "../../App";

const SnackbarAlert = forwardRef(function SnackbarAlert(props, ref) {
  return <Alert elevation={6} ref={ref} {...props} />;
});

function NavAdmin({ response, setResponse, alert, setAlert }) {
  const [dropMenu, setDropMenu] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [sideMenu, setSideMenu] = useState(false);
  const rootLink = useContext(RootLink);
  const navigate = useNavigate();

  if (alert === true) {
    setTimeout(() => {
      setAlert(false);
    }, 5000);
  }

  //SWITCH TOGGLE SIDE MENU
  const toggleSideMenu = () => {
    if (sideMenu === true) {
      setSideMenu(false);
    } else {
      setSideMenu(true);
    }
    setDropMenu(false);
  };
  //END OF SWITCH TOGGLE SIDE MENU

  //SWITCH TOGGLE DROP MENU
  const toggleDropMenu = () => {
    if (dropMenu === true) {
      setDropMenu(false);
    } else {
      setDropMenu(true);
    }
    setSideMenu(false);
  };
  //END OF SWITCH TOGGLE DROP MENU

  //LOG OUT
  const logout = () => {
    const msg = "Logged out successfully";

    const arr = { status: "success", response: msg };
    setResponse(arr);
    setAlert(true);

    setTimeout(() => {
      Cookies.remove("token");
      Cookies.remove("id");
      Cookies.remove("ref");
      navigate("/login");
    }, 2000);
  };
  //END OF LOG OUT

  return (
    <>
      <nav className='adminNav'>
        <IconButton
          onClick={() => toggleSideMenu()}
          size='large'
          className='adminHamburger'
        >
          <Menu />
        </IconButton>

        <div className='adminLogo' onClick={() => navigate("/admin/")}>
          <img src={rootLink + "resources/logo/zicopawa.png"} />
        </div>

        <IconButton className='person' onClick={() => toggleDropMenu()}>
          <Person />
        </IconButton>

        <div className='list'>
          <Link to='/user' onClick={() => setDropMenu(false)}>
            Switch to User Mode
          </Link>
          <Link to='/admin/password' onClick={() => setDropMenu(false)}>
            Change Password
          </Link>
          <div
            onClick={() => {
              logout();
              setDropMenu(false);
            }}
          >
            Log Out
          </div>
        </div>
      </nav>

      <div className={dropMenu ? "dropMenu" : "dropMenu disabled"}>
        <Link to='/user' onClick={() => setDropMenu(false)}>
          Switch to User Mode
        </Link>
        <Link to='/admin/password' onClick={() => setDropMenu(false)}>
          Change Password
        </Link>
        <div
          onClick={() => {
            logout();
            setDropMenu(false);
          }}
        >
          Log Out
        </div>
      </div>

      <div className={sideMenu ? "sideMenu" : "sideMenu disabled"}>
        <div className='linkWrapper'>
          <NavLink
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideMenu(false)}
            to='/admin/'
          >
            Dashboard
          </NavLink>
          <NavLink
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideMenu(false)}
            to='/admin/data'
          >
            Data Manager
          </NavLink>
          <NavLink
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideMenu(false)}
            to='/admin/airtime'
          >
            Airtime Manager
          </NavLink>
          <NavLink
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideMenu(false)}
            to='/admin/tv'
          >
            TV Manager
          </NavLink>
          <NavLink
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideMenu(false)}
            to='/admin/betting'
          >
            Betting Manager
          </NavLink>
          <NavLink
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideMenu(false)}
            to='/admin/transactions'
          >
            Transactions
          </NavLink>
          <NavLink
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideMenu(false)}
            to='/admin/users'
          >
            Users
          </NavLink>
          <NavLink
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideMenu(false)}
            to='/admin/announcement'
          >
            Announcement
          </NavLink>
          <NavLink
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideMenu(false)}
            to='/admin/messages'
          >
            Messages
          </NavLink>
          <NavLink
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideMenu(false)}
            to='/admin/socials'
          >
            Socials
          </NavLink>
          <NavLink
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideMenu(false)}
            to='/admin/updates'
          >
            Image Slider
          </NavLink>
          <NavLink
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideMenu(false)}
            to='/admin/terms'
          >
            Terms and Service
          </NavLink>
          <NavLink
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideMenu(false)}
            to='/admin/policies'
          >
            Privacy Policy
          </NavLink>
          <NavLink
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideMenu(false)}
            to='/admin/email'
          >
            General Email
          </NavLink>
        </div>
      </div>

      <Snackbar
        open={alert}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <SnackbarAlert color={response.status}>
          {response.response}
        </SnackbarAlert>
      </Snackbar>
    </>
  );
}

export default NavAdmin;
