import React from "react";
import UpdatesIndex from ".";
import { Routes, Route } from "react-router-dom";

function UpdatesRouter({ rootLink, response, setResponse, alert, setAlert }) {
  return (
    <Routes>
      <Route
        path='/'
        element={
          <UpdatesIndex
            rootLink={rootLink}
            response={response}
            setResponse={setResponse}
            alert={alert}
            setAlert={setAlert}
          />
        }
      />
    </Routes>
  );
}

export default UpdatesRouter;
