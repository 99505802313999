import { RootLink } from "../../App";
import { useContext } from "react";

function Loading1({ loading }) {
  const rootLink = useContext(RootLink);

  return (
    <>
      {loading ? (
        <div className='preloader'>
          <img src={rootLink + "resources/logo/logo.png"} />
        </div>
      ) : null}
    </>
  );
}

export default Loading1;
