import React from "react";
import { Helmet } from "react-helmet";

function SiteHelmet({ page }) {
  return (
    <Helmet>
      <title>
        ZICOPAWA DATA HUB - {page ? page : "Reaching you with the best"}
      </title>
      <meta name='description' content='Purchase your cheap data bundle' />
      <meta
        name='keywords'
        content='Data, data bundle, free browsing, cheap data in Nigeria, best data in Nigeria, airtime, Tv subscription, best data subscription in Nigeria, payment, zicopawa, zicopawa data hub'
      />
    </Helmet>
  );
}

export default SiteHelmet;
