import React from "react";
import { Routes, Route } from "react-router-dom";
import EmailIndex from ".";
import ViewMessage from "./view";
import ReplyMessage from "./reply";

function MessageRouter({ rootLink, response, setResponse, alert, setAlert }) {
  return (
    <Routes>
      <Route
        path={"/"}
        element={
          <EmailIndex
            rootLink={rootLink}
            response={response}
            setResponse={setResponse}
            alert={alert}
            setAlert={setAlert}
          />
        }
      />
      <Route
        path={"/:id"}
        element={
          <ViewMessage
            rootLink={rootLink}
            response={response}
            setResponse={setResponse}
            alert={alert}
            setAlert={setAlert}
          />
        }
      />
      <Route
        path={"/:id/reply"}
        element={
          <ReplyMessage
            rootLink={rootLink}
            response={response}
            setResponse={setResponse}
            alert={alert}
            setAlert={setAlert}
          />
        }
      />
    </Routes>
  );
}

export default MessageRouter;
