import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useState } from "react";
import Loading1 from "../../Loading/Loading1";

function AddNetwork({
  modal,
  rootLink,
  setResponse,
  setModal,
  setAlert,
  reload,
}) {
  const [network, setNetwork] = useState("");
  const [networkCode, setNetworkCode] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (network === "") {
      const msg = "Network name is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (networkCode === "") {
      const msg = "Network code is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (network && networkCode) {
      setLoadingBtn(true);

      const formData = new FormData();
      formData.append("AddNetwork", "Add");
      formData.append("network", network);
      formData.append("networkCode", networkCode);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/admin/dataManager.php", true);
      http.onload = function () {
        setResponse(JSON.parse(this.responseText));
        setModal(false);
        setAlert(true);
        setLoadingBtn(false);
        reload();
      };
      http.send(formData);
    }
  };
  //END OF FORM SUBMIT

  return (
    <Dialog open={modal} fullWidth>
      <DialogTitle sx={{ textAlign: "center" }}>Add Network</DialogTitle>

      <DialogContent>
        <label htmlFor='network'>Network Name</label>{" "}
        <input
          placeholder='Network Name'
          type='text'
          onChange={(e) => setNetwork(e.target.value)}
          sx={{ marginTop: "5px" }}
          name='network'
          required
        />
        <label htmlFor='networkCode'>Network Code</label>{" "}
        <input
          placeholder='Network Code'
          type='text'
          onChange={(e) => setNetworkCode(e.target.value)}
          name='networkCode'
          required
        />
        <DialogActions
          sx={{
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "5px",
          }}
        >
          <Button
            color='error'
            variant='contained'
            onClick={() => {
              setModal(false);
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loadingBtn ? true : false}
            onClick={formSubmit}
            variant='contained'
          >
            Add
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default AddNetwork;
