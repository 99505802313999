import React, { useState, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

function AddUpdate({
  rootLink,
  setResponse,
  setAlert,
  loadUpdates,
  modal,
  setModal,
}) {
  const filesRef = useRef();
  const [link, setLink] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (filesRef.current.files.length == 0) {
      const msg = "Image is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (filesRef.current.files.length > 0) {
      setLoadingBtn(true);
      const formData = new FormData();

      formData.append("AddUpdate", "Add");
      formData.append("link", link);
      formData.append("image", filesRef.current.files[0]);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/admin/updates.php", true);
      http.onload = function () {
        const jsonData = JSON.parse(this.responseText);

        setResponse(jsonData);
        loadUpdates();
        setModal(false);
        setAlert(true);
        setLoadingBtn(false);
      };
      http.send(formData);
      //END OF AJAX
    }
  };
  //END OF FORM SUBMIT

  return (
    <Dialog open={modal} fullWidth>
      <DialogTitle sx={{ textAlign: "center" }}>Add Image</DialogTitle>

      <>
        <DialogContent>
          <label htmlFor='image'>Image</label>
          <input
            ref={filesRef}
            allowed='jpg, jpeg, png'
            type='file'
            name='image'
          />
          <label htmlFor='link'>Link</label>
          <input
            type='text'
            onChange={(e) => setLink(e.target.value)}
            name='link'
            placeholder='Link'
          />

          <DialogActions
            sx={{
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Button
              color='error'
              variant='contained'
              onClick={() => {
                setModal(false);
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loadingBtn ? true : false}
              onClick={formSubmit}
              variant='contained'
            >
              Add
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </>
    </Dialog>
  );
}

export default AddUpdate;
