import React, { useState, useEffect } from "react";
import Nav from "../../../components/nav/Nav";
import Loading1 from "../../../components/Loading/Loading1";
import DeleteNetwork from "../../../components/modal/DataManager/DeleteNetwork";
import { useParams, useNavigate } from "react-router-dom";
import AddPackage from "../../../components/modal/TVPackage/AddPackage";
import EditPackage from "../../../components/modal/TVPackage/EditPackage";
import DeletePackage from "../../../components/modal/TVPackage/DeletePackage";
import { Delete, Edit, PlayArrow, Pause, ArrowBack } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import DisablePackage from "../../../components/modal/TVPackage/Disable";

function TVPlans({ rootLink, response, setResponse, alert, setAlert }) {
  const { cable } = useParams();
  const [addPackage, setAddPackage] = useState(false);
  const [content, setContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [reference, setReference] = useState("");
  const [editPackage, setEditPackage] = useState(false);
  const [deletePackage, setDeletePackage] = useState(false);
  const [disablePackage, setDisablePackage] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadPackages();
  }, []);

  //LOAD DATA PLANS
  const loadPackages = () => {
    const formData = new FormData();
    formData.append("loadPackages", cable);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/tvPackage.php", true);
    http.onload = function () {
      setContent(JSON.parse(this.responseText));

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD DATA PLANS

  //EDIT MODAL
  const openEditModal = (ref) => {
    setReference(ref);
    setEditPackage(true);
  };

  //END OF EDIT MODAL

  //DISABLE MODAL
  const openDisableModal = (ref) => {
    setReference(ref);
    setDisablePackage(true);
  };

  //END OF DISABLE MODAL

  //DELETE MODAL
  const openDeleteModal = (ref) => {
    setReference(ref);
    setDeletePackage(true);
  };

  //END OF DELETE MODAL

  return (
    <>
      <div className='firstWrapper'>
        <div className='backButton'>
          <IconButton onClick={() => navigate("../")}>
            <ArrowBack />
          </IconButton>
          <h1 className='firstHeader'>{cable} Packages</h1>
        </div>

        <div className='firstContent'>
          <div onClick={() => setAddPackage(true)} className='addButton'>
            New Package
          </div>

          {content.length === "" ? (
            <h2 className='center'>No package is available</h2>
          ) : (
            <>
              <div>
                {content.map((item) => {
                  return (
                    <div key={item.ref} className='listItem'>
                      <div className='left'>
                        <div className='bigText'>{item.package}</div>
                        <div className='smallText'>Code: {item.code}</div>
                        <div className='smallText'>
                          Price: &#8358;{item.price}
                        </div>
                      </div>
                      <div className='right flex'>
                        <IconButton onClick={() => openEditModal(item.ref)}>
                          <Edit color='primary' />
                        </IconButton>
                        <IconButton onClick={() => openDisableModal(item.ref)}>
                          {item.disabled === "false" ? (
                            <Pause color='primary' />
                          ) : (
                            <PlayArrow />
                          )}
                        </IconButton>{" "}
                        <IconButton onClick={() => openDeleteModal(item.ref)}>
                          <Delete color='error' />
                        </IconButton>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}

          {addPackage ? (
            <AddPackage
              modal={addPackage}
              setModal={setAddPackage}
              setResponse={setResponse}
              setAlert={setAlert}
              rootLink={rootLink}
              reload={loadPackages}
              cable={cable}
            />
          ) : null}

          {editPackage ? (
            <EditPackage
              reference={reference}
              modal={editPackage}
              setModal={setEditPackage}
              setResponse={setResponse}
              setAlert={setAlert}
              rootLink={rootLink}
              reload={loadPackages}
              cable={cable}
            />
          ) : null}

          {disablePackage ? (
            <DisablePackage
              reference={reference}
              modal={disablePackage}
              setModal={setDisablePackage}
              setResponse={setResponse}
              setAlert={setAlert}
              rootLink={rootLink}
              reload={loadPackages}
              cable={cable}
            />
          ) : null}

          {deletePackage ? (
            <DeletePackage
              reference={reference}
              modal={deletePackage}
              setModal={setDeletePackage}
              setResponse={setResponse}
              setAlert={setAlert}
              rootLink={rootLink}
              reload={loadPackages}
              cable={cable}
            />
          ) : null}

          <Loading1 loading={isLoading} />
        </div>
      </div>
    </>
  );
}

export default TVPlans;
