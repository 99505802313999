import React, { useState, useEffect } from "react";
import AddUpdate from "../../../components/modal/Updates/AddUpdate";
import { Delete, Edit, ArrowBack } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import EditUpdate from "../../../components/modal/Updates/EditUpdate";
import DeleteUpdate from "../../../components/modal/Updates/DeleteUpdate";
import { useNavigate } from "react-router-dom";
import Loading1 from "../../../components/Loading/Loading1";

function UpdatesIndex({ rootLink, response, setResponse, alert, setAlert }) {
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [id, setId] = useState("");
  const [content, setContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    loadUpdates();
  }, []);

  //LOAD CONTENT
  const loadUpdates = () => {
    const formData = new FormData();
    formData.append("loadUpdates", "load");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/updates.php", true);
    http.onload = function () {
      setContent(JSON.parse(this.responseText));

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD CONTENT

  //OPEN EDIT MODAL
  const openEdit = (ref) => {
    setId(ref);
    setEditModal(true);
  };
  //END OF OPEN EDIT MODAL

  //OPEN DELETE MODAL
  const openDelete = (ref) => {
    setId(ref);
    setDeleteModal(true);
  };
  //END OF OPEN DELETE MODAL

  return (
    <>
      <div className='firstWrapper'>
        <div className='backButton'>
          <IconButton onClick={() => navigate("../../")}>
            <ArrowBack />
          </IconButton>
          <h1 className='firstHeader'>Image Slider</h1>
        </div>

        <div className='firstContent'>
          <button className='width100' onClick={() => setModal(true)}>
            Add Image
          </button>

          <div>
            {content.map((item) => {
              return (
                <div key={item.ref} className='listItem'>
                  <div className='left width50'>
                    <img src={"/resources/images/updates/" + item.image} />
                    <div>{item.link}</div>
                  </div>

                  <div className='right flex center'>
                    <IconButton onClick={() => openEdit(item.ref)}>
                      <Edit color='primary' />
                    </IconButton>
                    <IconButton onClick={() => openDelete(item.ref)}>
                      <Delete color='error' />
                    </IconButton>
                  </div>
                </div>
              );
            })}
          </div>

          <AddUpdate
            setResponse={setResponse}
            setAlert={setAlert}
            rootLink={rootLink}
            modal={modal}
            setModal={setModal}
            loadUpdates={loadUpdates}
          />

          {editModal ? (
            <EditUpdate
              setResponse={setResponse}
              setAlert={setAlert}
              rootLink={rootLink}
              modal={editModal}
              setModal={setEditModal}
              loadUpdates={loadUpdates}
              id={id}
            />
          ) : null}

          {deleteModal ? (
            <DeleteUpdate
              setResponse={setResponse}
              setAlert={setAlert}
              rootLink={rootLink}
              modal={deleteModal}
              setModal={setDeleteModal}
              loadUpdates={loadUpdates}
              id={id}
            />
          ) : null}
        </div>
      </div>

      <Loading1 loading={isLoading} />
    </>
  );
}

export default UpdatesIndex;
