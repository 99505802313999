import { useEffect, useState, forwardRef } from "react";
import Cookies from "js-cookie";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TextField, Paper, Stack, Snackbar, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Loading1 from "../Loading/Loading1";
import ForgottenPassword from "./Forgotten";
import ForgetResponse from "./ForgetResponse";

const SnackbarAlert = forwardRef(function SnackbarAlert(props, ref) {
  return <Alert elevation={6} ref={ref} {...props} />;
});

function LoginPage({ rootLink, loggedin, setLoggedin, setRole, setIsLoading }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [saveLogin, setSaveLogin] = useState();
  const [response, setResponse] = useState({
    status: "",
    response: "",
    id: "",
    saved: "",
  });
  const [alert, setAlert] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [forgotten, setForgotten] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    checkLog();

    window.scrollTo(0, 0);

    setTimeout(() => {
      setIsLoading(false);
    }, 60000);
  }, [pathname]);

  //CHECK LOG STATUS
  const checkLog = () => {
    const ref = Cookies.get("ref");
    const id = Cookies.get("id");
    const token = Cookies.get("token");

    if (ref && id && token) {
      const data = new FormData();
      data.append("checkAccess", "check");
      data.append("id", id);
      data.append("ref", ref);
      data.append("token", token);

      const https = new XMLHttpRequest();
      https.open("POST", rootLink + "backend/access/login.php", true);
      https.onload = function () {
        const res = JSON.parse(this.responseText);

        if (res.status === "success") {
          //Check
          setLoggedin(true);
          setRole(res.role);
        } else {
          setLoggedin(false);
          Cookies.remove("ref");
          Cookies.remove("id");
          Cookies.remove("token");
          navigate("/user");
        }

        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
      };
      https.send(data);
    }
  };
  //END OF CHECK LOG STAUS

  //LOG IN
  const login = (e) => {
    e.preventDefault();
    setBtnLoader(true);

    if (username === "") {
      const msg = "Username is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (password === "") {
      const msg = "Password is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (username && password) {
      //setBtnLoader(true);

      let saveLogIn;

      if (saveLogin === true) {
        saveLogIn = "yes";
      } else {
        saveLogIn = "no";
      }

      const formData = new FormData();
      formData.append("logUser", "login");
      formData.append("username", username.replace(/ /g, ""));
      formData.append("password", password);
      formData.append("save", saveLogIn);

      const ajax = new XMLHttpRequest();
      ajax.open("POST", rootLink + "backend/access/login.php", true);
      ajax.onload = function () {
        const jsonData = JSON.parse(this.responseText);

        if (jsonData.status === "success") {
          if (jsonData.remember === "yes") {
            Cookies.set("ref", jsonData.ref, { expires: 30 });
            Cookies.set("id", jsonData.id, { expires: 30 });
            Cookies.set("token", jsonData.token, { expires: 30 });
            setRole(jsonData.role);
          } else {
            Cookies.set("ref", jsonData.ref, { expires: 1 });
            Cookies.set("id", jsonData.id, { expires: 1 });
            Cookies.set("token", jsonData.token, { expires: 1 });
          }
          setTimeout(() => {
            setResponse(JSON.parse(this.responseText));
            setAlert(true);
          }, 500);

          setTimeout(() => {
            if (jsonData.role === "admin") {
              setLoggedin(true);
              navigate("/admin/");
            } else {
              setLoggedin(true);
              navigate("/user/");
            }
          }, 2000);
        } else {
          setResponse(JSON.parse(this.responseText));
          setAlert(true);

          setLoggedin(false);
        }

        setBtnLoader(false);
      };
      ajax.send(formData);
    }
  };
  //END OF LOG IN

  if (response.response !== "") {
    setTimeout(() => {
      setResponse({
        status: "",
        response: "",
      });
      setAlert(false);
    }, 5500);
  }

  return (
    <>
      <div className='loginContainer'>
        {!forgotten ? (
          <>
            <img
              onClick={() => navigate("/")}
              className='onlyLogo'
              src='/resources/logo/zicopawa.png'
            />

            <div className='loginWrapper'>
              <h2>Log In</h2>
              <form onSubmit={login}>
                <br />
                <input
                  type='text'
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder='Username / Email / Phone Number'
                  fullWidth
                  name='username'
                />
                <input
                  type='password'
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder='Password'
                  fullWidth
                  name='password'
                />

                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    marginBottom: "5px",
                    padding: "5px",
                  }}
                >
                  <input
                    type='checkbox'
                    onChange={(e) => setSaveLogin(e.target.checked)}
                  />
                  <div style={{ fontSize: "1.1rem" }}>Remember me </div>{" "}
                </div>
                <button
                  loading={btnLoader ? true : false}
                  variant='contained'
                  onClick={login}
                  className='width100'
                >
                  Log In
                </button>
              </form>
              <br />
              <div className='link' onClick={() => setForgotten(true)}>
                Forgotten Password
              </div>
              Are you new here?{" "}
              <Link className='link' to={"/register"}>
                Register
              </Link>
            </div>
          </>
        ) : (
          <ForgottenPassword
            setResponse={setResponse}
            setAlert={setAlert}
            rootLink={rootLink}
            setForgotten={setForgotten}
          />
        )}

        {response.status !== "" ? (
          <Snackbar
            open={alert}
            autoHideDuration={5000}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <SnackbarAlert color={response.status}>
              {response.response}
            </SnackbarAlert>
          </Snackbar>
        ) : null}
      </div>
    </>
  );
}

export default LoginPage;
