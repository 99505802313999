import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useState, useEffect } from "react";
import Loading1 from "../../Loading/Loading1";

function AddDataPlan({
  modal,
  rootLink,
  setResponse,
  setModal,
  setAlert,
  reload,
  network,
}) {
  const [dataPlan, setDataPlan] = useState("");
  const [dataCode, setDataCode] = useState("");
  const [dataPrice, setDataPrice] = useState("");
  const [networkCode, setNetworkCode] = useState("");
  const [networkRef, setNetworkRef] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);

  useEffect(() => {
    loadNetwork();
  }, []);

  //LOAD NETWORK CODE
  const loadNetwork = () => {
    const formData = new FormData();
    formData.append("loadNetworkCode", network);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/dataPlan.php", true);
    http.onload = function () {
      setTimeout(() => {
        setNetworkCode(JSON.parse(this.responseText).network_code);
        setNetworkRef(JSON.parse(this.responseText).ref);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD NETWORK CODE

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (dataPlan === "") {
      const msg = "Data plan is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (dataCode === "") {
      const msg = "Data code is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (dataPrice === "") {
      const msg = "Data price is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (networkCode === "") {
      const msg = "An error occurred";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (networkRef === "") {
      const msg = "An error occurred";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (dataPlan && dataCode && dataPrice && networkCode && networkRef) {
      setLoadingBtn(true);

      const formData = new FormData();
      formData.append("addDataPlan", "Add");
      formData.append("network", network);
      formData.append("dataPlan", dataPlan);
      formData.append("dataCode", dataCode);
      formData.append("dataPrice", dataPrice);
      formData.append("networkCode", networkCode);
      formData.append("networkRef", networkRef);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/admin/dataPlan.php", true);
      http.onload = function () {
        setResponse(JSON.parse(this.responseText));
        setModal(false);
        setAlert(true);
        setLoadingBtn(false);
        reload();
      };
      http.send(formData);
    }
  };
  //END OF FORM SUBMIT

  return (
    <Dialog open={modal} fullWidth>
      <DialogTitle sx={{ textAlign: "center" }}>Add Data Plan</DialogTitle>

      {networkCode === "" ? (
        <Loading1 loading={isLoading} />
      ) : (
        <>
          <DialogContent>
            <label htmlFor=''>Data Plan</label>
            <input
              placeholder='Data Plan'
              type='text'
              onChange={(e) => setDataPlan(e.target.value)}
              sx={{ marginTop: "5px" }}
              fullWidth
              required
            />

            <label htmlFor=''>Data Plan Code</label>
            <input
              placeholder='Data Plan Code'
              type='text'
              onChange={(e) => setDataCode(e.target.value)}
              fullWidth
              required
            />

            <label htmlFor=''>Data Price</label>
            <input
              placeholder='Data Price'
              type='number'
              onChange={(e) => setDataPrice(e.target.value)}
              fullWidth
              required
            />

            <DialogActions
              sx={{
                justifyContent: "center",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              <Button
                color='error'
                variant='contained'
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={loadingBtn ? true : false}
                onClick={formSubmit}
                variant='contained'
              >
                Add
              </LoadingButton>
            </DialogActions>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}

export default AddDataPlan;
