import React, { useState, useEffect } from "react";
import HTMLReactParser from "html-react-parser";
import { ArrowBack } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SiteHelmet from "../../components/Helmet/Helmet";
import Loading1 from "../../components/Loading/Loading1";

function PoliciesIndex({ rootLink, response, setResponse, alert, setAlert }) {
  const [content, setContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    loadContent();
  }, []);

  //LOAD CONTENT
  const loadContent = () => {
    const formData = new FormData();
    formData.append("loadPolicies", "load");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/checker.php", true);
    http.onload = function () {
      setContent(JSON.parse(this.responseText));

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD CONTENT

  return (
    <>
      <SiteHelmet page={"Privacy Policy"} />

      <div className='firstWrapper'>
        <div className='backButton'>
          <IconButton onClick={() => navigate("/")}>
            <ArrowBack />
          </IconButton>
          <h1 className='firstHeader'>Privacy Policy</h1>
        </div>

        <div className='firstContent'>
          {content.message ? HTMLReactParser(content.message) : null}
        </div>
      </div>

      <Loading1 loading={isLoading} />
    </>
  );
}

export default PoliciesIndex;
