import React, { useState } from "react";
import Cookies from "js-cookie";

function Sender({ setAlert, response, alert, setResponse, rootLink }) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [category, setCategory] = useState("");
  const [message, setMessage] = useState("");
  const user = Cookies.get("id");

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (name === "") {
      const msg = "Name is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (email === "") {
      const msg = "Email is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (!isEmail(email)) {
      const msg = "Invalid email";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (phone === "") {
      const msg = "Phone number is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (isNaN(phone)) {
      const msg = "Phone number is invalid";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (category === "") {
      const msg = "Select a category";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (message === "") {
      const msg = "Message can not be empty";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (
      name &&
      email &&
      isEmail(email) &&
      phone &&
      !isNaN(phone) &&
      category &&
      message
    ) {
      const formData = new FormData();
      formData.append("SendMessage", user);
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("category", category);
      formData.append("message", message);

      const ajax = new XMLHttpRequest();
      ajax.open("POST", rootLink + "backend/server/contact.php", true);
      ajax.onload = function () {
        const jsonData = JSON.parse(this.responseText);

        setResponse(jsonData);
        setAlert(true);

        if (jsonData.status === "success") {
          setName("");
          setEmail("");
          setPhone("");
          setCategory("");
          setMessage("");
        }
      };
      ajax.send(formData);
    }
  };
  //END OF FORM SUBMIT

  function isEmail(email) {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  }

  return (
    <form onSubmit={formSubmit}>
      <input
        type='text'
        onChange={(e) => setName(e.target.value)}
        placeholder='Full Name'
      />
      <input
        type='email'
        onChange={(e) => setEmail(e.target.value)}
        placeholder='Email'
      />
      <input
        type='number'
        onChange={(e) => setPhone(e.target.value)}
        placeholder='Phone Number'
      />

      <input
        type='text'
        onChange={(e) => setCategory(e.target.value)}
        placeholder='Select category'
      />
      <textarea
        onChange={(e) => setMessage(e.target.value)}
        placeholder='Message'
      />

      <button
        className={
          name === "" ||
          category === "" ||
          message === "" ||
          email === "" ||
          !isEmail(email) ||
          phone === "" ||
          isNaN(phone)
            ? "width100 disabled"
            : "width100"
        }
        disabled={
          name &&
          email &&
          isEmail(email) &&
          phone &&
          !isNaN(phone) &&
          category &&
          message
            ? false
            : true
        }
        onClick={formSubmit}
      >
        Send
      </button>
    </form>
  );
}

export default Sender;
