import React from "react";
import { Routes, Route } from "react-router-dom";
import DepositIndex from ".";
import BankDeposit from "./bank";

function DepositRouter({ rootLink, response, setResponse, alert, setAlert }) {
  return (
    <>
      <Routes>
        <Route
          path='/'
          element={
            <DepositIndex
              rootLink={rootLink}
              response={response}
              setResponse={setResponse}
              alert={alert}
              setAlert={setAlert}
            />
          }
        />
        <Route
          path='/bank'
          element={
            <BankDeposit
              rootLink={rootLink}
              response={response}
              setResponse={setResponse}
              alert={alert}
              setAlert={setAlert}
            />
          }
        />
      </Routes>
    </>
  );
}

export default DepositRouter;
