import React, { useState, useEffect } from "react";
import { Routes, Route, NavLink, Link, useLocation } from "react-router-dom";
import AdminIndex from ".";
import Cookies from "js-cookie";
import DataManagerRouter from "./DataManager/router";
import AirtimeManagerRouter from "./AirtimeManager/router";
import TermsRouter from "./Terms/router";
import PolicyRouter from "./Policy/router";
import TransactionsRouter from "./Transactions/router";
import NavAdmin from "../../components/nav/NavAdmin";
import UpdatesRouter from "./Updates/router";
import UsersRouter from "./Users/router";
import AnnouncementRouter from "./Announcement/router";
import EmailRouter from "./Email/router";
import MessageRouter from "./Messages/router";
import SocialIndex from "./Socials";
import LoginPage from "../../components/Login/Login";
import NotAdmin from "../../components/Login/NotAdmin";
import "../../styles/admin/dataManager.css";
import "../../styles/admin/message.css";
import "../../styles/admin/user.css";
import "../../styles/admin/nav.css";
import "../../styles/admin/adminDashboard.css";
import "../../styles/admin/search.css";
import "../../styles/admin/stat.css";
import "../../styles/overall/list2.css";
import PasswordRouter from "./Password/router";
import TVManagerRouter from "./TV/router";
import BettingManagerRouter from "./Betting/router";
import { Person } from "@mui/icons-material";
import Loading1 from "../../components/Loading/Loading1";
import { Helmet } from "react-helmet";
import SiteHelmet from "../../components/Helmet/Helmet";

function AdminRouter({ rootLink }) {
  const [loggedin, setLoggedin] = useState(false);
  const [alert, setAlert] = useState(false);
  const [response, setResponse] = useState({
    status: "",
    response: "",
  });
  const [role, setRole] = useState("");
  const user = Cookies.get("id");
  const [details, setDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();

  useEffect(
    function () {
      window.scrollTo(0, 0);
    },
    [pathname]
  );

  return (
    <>
      {loggedin === true ? (
        role === "admin" ? (
          <>
            <SiteHelmet />

            <NavAdmin
              response={response}
              setResponse={setResponse}
              alert={alert}
              setAlert={setAlert}
            />
            <div className='userBody'>
              <div className='sideMenu'>
                <div className='linkWrapper' style={{ marginTop: "5px" }}>
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "navLink active" : "navLink"
                    }
                    to='/admin/'
                  >
                    Dashboard
                  </NavLink>
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "navLink active" : "navLink"
                    }
                    to='/admin/data'
                  >
                    Data Manager
                  </NavLink>
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "navLink active" : "navLink"
                    }
                    to='/admin/airtime'
                  >
                    Airtime Manager
                  </NavLink>
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "navLink active" : "navLink"
                    }
                    to='/admin/tv'
                  >
                    TV Manager
                  </NavLink>
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "navLink active" : "navLink"
                    }
                    to='/admin/betting'
                  >
                    Betting Manager
                  </NavLink>
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "navLink active" : "navLink"
                    }
                    to='/admin/transactions'
                  >
                    Transactions
                  </NavLink>
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "navLink active" : "navLink"
                    }
                    to='/admin/users'
                  >
                    Users
                  </NavLink>
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "navLink active" : "navLink"
                    }
                    to='/admin/announcement'
                  >
                    Announcement
                  </NavLink>
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "navLink active" : "navLink"
                    }
                    to='/admin/messages'
                  >
                    Messages
                  </NavLink>
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "navLink active" : "navLink"
                    }
                    to='/admin/socials'
                  >
                    Socials
                  </NavLink>
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "navLink active" : "navLink"
                    }
                    to='/admin/updates'
                  >
                    Image Slider
                  </NavLink>
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "navLink active" : "navLink"
                    }
                    to='/admin/terms'
                  >
                    Terms and Service
                  </NavLink>
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "navLink active" : "navLink"
                    }
                    to='/admin/policies'
                  >
                    Privacy Policy
                  </NavLink>
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "navLink active" : "navLink"
                    }
                    to='/admin/email'
                  >
                    General Email
                  </NavLink>
                </div>
              </div>

              <div className='userContent'>
                <Routes>
                  <Route
                    path='/'
                    element={
                      <AdminIndex
                        rootLink={rootLink}
                        response={response}
                        setResponse={setResponse}
                        alert={alert}
                        setAlert={setAlert}
                      />
                    }
                  />
                  <Route
                    path='/data/*'
                    element={
                      <DataManagerRouter
                        rootLink={rootLink}
                        response={response}
                        setResponse={setResponse}
                        alert={alert}
                        setAlert={setAlert}
                      />
                    }
                  />

                  <Route
                    path='/tv/*'
                    element={
                      <TVManagerRouter
                        rootLink={rootLink}
                        response={response}
                        setResponse={setResponse}
                        alert={alert}
                        setAlert={setAlert}
                      />
                    }
                  />
                  <Route
                    path='/betting'
                    element={
                      <BettingManagerRouter
                        rootLink={rootLink}
                        response={response}
                        setResponse={setResponse}
                        alert={alert}
                        setAlert={setAlert}
                      />
                    }
                  />
                  <Route
                    path='/terms'
                    element={
                      <TermsRouter
                        rootLink={rootLink}
                        response={response}
                        setResponse={setResponse}
                        alert={alert}
                        setAlert={setAlert}
                      />
                    }
                  />
                  <Route
                    path='/policies'
                    element={
                      <PolicyRouter
                        rootLink={rootLink}
                        response={response}
                        setResponse={setResponse}
                        alert={alert}
                        setAlert={setAlert}
                      />
                    }
                  />

                  <Route
                    path='/airtime/*'
                    element={
                      <AirtimeManagerRouter
                        rootLink={rootLink}
                        response={response}
                        setResponse={setResponse}
                        alert={alert}
                        setAlert={setAlert}
                      />
                    }
                  />

                  <Route
                    path='/updates/*'
                    element={
                      <UpdatesRouter
                        rootLink={rootLink}
                        response={response}
                        setResponse={setResponse}
                        alert={alert}
                        setAlert={setAlert}
                      />
                    }
                  />

                  <Route
                    path='/users/*'
                    element={
                      <UsersRouter
                        rootLink={rootLink}
                        response={response}
                        setResponse={setResponse}
                        alert={alert}
                        setAlert={setAlert}
                      />
                    }
                  />

                  <Route
                    path='/transactions/*'
                    element={
                      <TransactionsRouter
                        rootLink={rootLink}
                        response={response}
                        setResponse={setResponse}
                        alert={alert}
                        setAlert={setAlert}
                      />
                    }
                  />

                  <Route
                    path='/messages/*'
                    element={
                      <MessageRouter
                        rootLink={rootLink}
                        response={response}
                        setResponse={setResponse}
                        alert={alert}
                        setAlert={setAlert}
                      />
                    }
                  />
                  <Route
                    path='/announcement'
                    element={
                      <AnnouncementRouter
                        rootLink={rootLink}
                        response={response}
                        setResponse={setResponse}
                        alert={alert}
                        setAlert={setAlert}
                      />
                    }
                  />
                  <Route
                    path='/email/*'
                    element={
                      <EmailRouter
                        rootLink={rootLink}
                        response={response}
                        setResponse={setResponse}
                        alert={alert}
                        setAlert={setAlert}
                      />
                    }
                  />

                  <Route
                    path='/socials'
                    element={
                      <SocialIndex
                        rootLink={rootLink}
                        response={response}
                        setResponse={setResponse}
                        alert={alert}
                        setAlert={setAlert}
                      />
                    }
                  />
                  <Route
                    path='/password'
                    element={
                      <PasswordRouter
                        rootLink={rootLink}
                        response={response}
                        setResponse={setResponse}
                        alert={alert}
                        setAlert={setAlert}
                      />
                    }
                  />
                </Routes>
              </div>
            </div>
          </>
        ) : (
          <NotAdmin />
        )
      ) : (
        <>
          <SiteHelmet page={"Login"} />

          <LoginPage
            rootLink={rootLink}
            setLoggedin={setLoggedin}
            loggedin={loggedin}
            setRole={setRole}
            setIsLoading={setIsLoading}
          />
        </>
      )}

      <Loading1 loading={isLoading} />
    </>
  );
}

export default AdminRouter;
