import React, { useState, useEffect } from "react";
import Nav from "../../../components/nav/Nav";
import Loading1 from "../../../components/Loading/Loading1";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, TextField, Stack, ButtonGroup } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ArrowBack } from "@mui/icons-material";

const Editor = React.lazy(() => import("../../../components/Editor/Editor"));

function AnnouncementIndex({
  rootLink,
  response,
  setResponse,
  alert,
  setAlert,
}) {
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [ready, setReady] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadAnnouncement();
  }, []);

  //LOAD CONTENT
  const loadAnnouncement = () => {
    const formData = new FormData();
    formData.append("loadAnnouncement", "load");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/announcement.php", true);
    http.onload = function () {
      setContent(this.responseText);

      setReady(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD CONTENT

  //FORM SUBMIT
  const formSubmit = () => {
    const formData = new FormData();
    formData.append("editAnnouncement", content);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/announcement.php", true);
    http.onload = function () {
      setResponse(JSON.parse(this.responseText));

      setAlert(true);
      loadAnnouncement();

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };

  //END OF FORM SUBMIT

  //CLEAR ANNOUNCEMENT
  const clearAnnouncement = () => {
    const formData = new FormData();
    formData.append("clearAnnouncement", "clear");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/announcement.php", true);
    http.onload = function () {
      setResponse(JSON.parse(this.responseText));
      setAlert(true);
      loadAnnouncement();

      setTimeout(() => {
        setIsLoading(false);
        window.location.reload();
      }, 1000);
    };
    http.send(formData);
  };
  //CLEAR ANNOUNCEMENT

  return (
    <>
      <div className='firstWrapper'>
        <div className='backButton'>
          <IconButton onClick={() => navigate("../../")}>
            <ArrowBack />
          </IconButton>
          <h1 className='firstHeader'>Announcement</h1>
        </div>

        <div className='firstContent'>
          {ready ? <Editor value={content} setValue={setContent} /> : null}
          <br />

          <center>
            <ButtonGroup>
              <LoadingButton
                onClick={clearAnnouncement}
                loading={loadingBtn ? true : false}
                variant='outlined'
                color='error'
              >
                Clear
              </LoadingButton>
              <LoadingButton
                onClick={formSubmit}
                loading={loadingBtn ? true : false}
                variant='contained'
              >
                POST
              </LoadingButton>
            </ButtonGroup>
          </center>

          <Loading1 loading={isLoading} />
        </div>
      </div>
    </>
  );
}

export default AnnouncementIndex;
