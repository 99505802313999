import React, { useState, useEffect, useLayoutEffect } from "react";
import { Routes, Route, NavLink, Link, useLocation } from "react-router-dom";
import UserIndex from ".";
import DataRouter from "./Data/router";
import TransactionsRouter from "./Transactions/router";
import AirtimeRouter from "./Airtime/router";
import DepositRouter from "./Deposit/router";
import BettingRouter from "./Betting/router";
import TVRouter from "./TV/router";
import PasswordRouter from "./Password/router";
import Footer from "../../components/Footer/Footer";
import PageNotFound from "./404";
import Cookies from "js-cookie";
import LoginPage from "../../components/Login/Login";
import NavUser from "../../components/nav/NavUser";
import HTMLReactParser from "html-react-parser";
import AnnounceModal from "../../components/modal/User/Announce/Announce";
import {
  NotificationsActive,
  Person,
  WindowRounded,
} from "@mui/icons-material";
import "../../styles/user/color.css";
import "../../styles/user/dashboard.css";
import "../../styles/user/announce.css";
import "../../styles/user/userBody.css";
import "../../styles/user/nav.css";
import "../../styles/user/cable.css";
import "../../styles/user/transactionView.css";
import Loading1 from "../../components/Loading/Loading1";
import { Helmet } from "react-helmet";
import TransferRouter from "./Transfer/router";
import SiteHelmet from "../../components/Helmet/Helmet";

function UserRouter({ rootLink }) {
  const [loggedin, setLoggedin] = useState(false);
  const [alert, setAlert] = useState(false);
  const [response, setResponse] = useState({
    status: "",
    response: "",
  });
  const [announcement, setAnnouncement] = useState("");
  const [modal, setModal] = useState(false);
  const user = Cookies.get("id");
  const [details, setDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    if (user) {
      loadDetails();
    }
  }, [user]);

  useLayoutEffect(
    function () {
      window.scrollTo(0, 0);
    },
    [pathname]
  );

  //USER DETAILS
  const loadDetails = () => {
    const formData = new FormData();
    formData.append("userDetails", user);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/checker.php", true);
    http.onload = function () {
      setDetails(JSON.parse(this.responseText));

      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    };
    http.send(formData);
  };
  //END OF USER DETAILS

  //LOAD ANNOUNCEMENT
  useEffect(() => {
    const formData = new FormData();
    formData.append("loadAnnouncement", "load");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/checker.php", true);
    http.onload = function () {
      setAnnouncement(JSON.parse(this.responseText).message);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  }, []);
  //END OF LOAD ANNOUNCEMENT

  return (
    <>
      {loggedin === true ? (
        <>
          <NavUser
            response={response}
            setResponse={setResponse}
            alert={alert}
            setAlert={setAlert}
          />

          <SiteHelmet />

          <div className='userBody'>
            <div className='sideMenu1'>
              <div className='sideDetails'>
                <div className='person'>
                  <Person />
                </div>
                <div className='name' style={{ fontWeight: "bold" }}>
                  {details.first_name ? details.first_name : null}{" "}
                  {details.last_name ? details.last_name : null}
                </div>
                <div className='name'>
                  <i>{details.username ? details.username : null}</i>
                </div>
                <div className='balance'>
                  &#8358;{details.balance ? details.balance : 0}
                </div>

                <Link to='/user/deposit'>Deposit</Link>
              </div>

              <div className='linkWrapper'>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "navLink active" : "navLink"
                  }
                  to='/user/'
                >
                  Dashboard
                </NavLink>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "navLink active" : "navLink"
                  }
                  to='/user/data'
                >
                  Data
                </NavLink>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "navLink active" : "navLink"
                  }
                  to='/user/airtime'
                >
                  Airtime
                </NavLink>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "navLink active" : "navLink"
                  }
                  to='/user/tv'
                >
                  TV Subscription
                </NavLink>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "navLink active" : "navLink"
                  }
                  to='/user/betting'
                >
                  Bet Funding
                </NavLink>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "navLink active" : "navLink"
                  }
                  to='/user/transfer'
                >
                  Transfer Fund
                </NavLink>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "navLink active" : "navLink"
                  }
                  to='/user/transactions'
                >
                  Transactions
                </NavLink>
              </div>
            </div>

            <div className='userContent'>
              <Routes>
                <Route
                  path='/'
                  element={
                    <UserIndex
                      rootLink={rootLink}
                      response={response}
                      setResponse={setResponse}
                      alert={alert}
                      setAlert={setAlert}
                    />
                  }
                />
                <Route
                  path='/airtime'
                  element={
                    <AirtimeRouter
                      rootLink={rootLink}
                      response={response}
                      setResponse={setResponse}
                      alert={alert}
                      setAlert={setAlert}
                    />
                  }
                />
                <Route
                  path='/data'
                  element={
                    <DataRouter
                      rootLink={rootLink}
                      response={response}
                      setResponse={setResponse}
                      alert={alert}
                      setAlert={setAlert}
                    />
                  }
                />
                <Route
                  path='/betting'
                  element={
                    <BettingRouter
                      rootLink={rootLink}
                      response={response}
                      setResponse={setResponse}
                      alert={alert}
                      setAlert={setAlert}
                    />
                  }
                />
                <Route
                  path='/tv'
                  element={
                    <TVRouter
                      rootLink={rootLink}
                      response={response}
                      setResponse={setResponse}
                      alert={alert}
                      setAlert={setAlert}
                    />
                  }
                />
                <Route
                  path='/transactions/*'
                  element={
                    <TransactionsRouter
                      rootLink={rootLink}
                      response={response}
                      setResponse={setResponse}
                      alert={alert}
                      setAlert={setAlert}
                    />
                  }
                />

                <Route
                  path='/transfer'
                  element={
                    <TransferRouter
                      rootLink={rootLink}
                      response={response}
                      setResponse={setResponse}
                      alert={alert}
                      setAlert={setAlert}
                    />
                  }
                />
                <Route
                  path='/deposit/*'
                  element={
                    <DepositRouter
                      rootLink={rootLink}
                      response={response}
                      setResponse={setResponse}
                      alert={alert}
                      setAlert={setAlert}
                    />
                  }
                />
                <Route
                  path='/password'
                  element={
                    <PasswordRouter
                      rootLink={rootLink}
                      response={response}
                      setResponse={setResponse}
                      alert={alert}
                      setAlert={setAlert}
                    />
                  }
                />

                <Route
                  path='*'
                  element={
                    <PageNotFound
                      rootLink={rootLink}
                      response={response}
                      setResponse={setResponse}
                      alert={alert}
                      setAlert={setAlert}
                    />
                  }
                />
              </Routes>
            </div>

            {announcement !== "" ? (
              <div onClick={() => setModal(true)} className='announcement'>
                <NotificationsActive />
              </div>
            ) : null}

            <AnnounceModal
              rootLink={rootLink}
              modal={modal}
              setModal={setModal}
            />
          </div>

          <Footer rootLink={rootLink} />
        </>
      ) : (
        <>
          <SiteHelmet page={"Login"} />

          <LoginPage
            rootLink={rootLink}
            setLoggedin={setLoggedin}
            loggedin={loggedin}
            setIsLoading={setIsLoading}
          />
        </>
      )}

      <Loading1 loading={isLoading} />
    </>
  );
}

export default UserRouter;
