import { Search, Cancel, CancelOutlined } from "@mui/icons-material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

function SearchUser({ rootLink, setContent, content }) {
  const [text, setText] = useState("");
  const [show, setShow] = useState(false);

  //SEARCH USER
  const search = (input) => {
    const formData = new FormData();
    formData.append("searchUser", input);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/users.php", true);
    http.onload = function () {
      setContent(JSON.parse(this.responseText));
    };
    http.send(formData);
  };
  //END OF SEARCH USER

  //DEPLAY SEARCH
  const display = () => {
    if (show === true) {
      setShow(false);
      setText("");
    } else {
      setShow(true);
    }
  };
  //END OF DEPLAY SEARCH

  return (
    <>
      <div className={show === true ? "search flex" : "search"}>
        <input
          type='text'
          onChange={(e) => {
            search(e.target.value);
            setText(e.target.value);
          }}
          className={show ? "show" : "hide"}
          placeholder='Search with username, user ID, email or phone number'
        />
        <button className={show ? "show" : "hide"} onClick={display}>
          {!show ? <Search /> : <CancelOutlined />}
        </button>
      </div>

      {show ? (
        <div className='searchResult'>
          {content.length > 0 ? (
            <>
              {content.map((item) => {
                return (
                  <Link
                    to={"../" + item.username}
                    key={item.user_id}
                    className='result'
                  >
                    <div className='left'>
                      <div className='name'>
                        {item.first_name + " " + item.last_name}
                      </div>
                      <div className='username'>{item.username}</div>
                    </div>
                    <div className='right balance'>&#8358;{item.balance}</div>
                  </Link>
                );
              })}
            </>
          ) : (
            <center>
              <h3>No result found</h3>
            </center>
          )}
        </div>
      ) : null}
    </>
  );
}

export default SearchUser;
