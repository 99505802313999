import React from "react";
import UsersIndex from ".";
import ViewUser from "./view";
import { Routes, Route } from "react-router-dom";
import UserTransactions from "./transactions";
import ViewUserTransaction from "./viewTransaction";

function UsersRouter({ rootLink, response, setResponse, alert, setAlert }) {
  return (
    <>
      <Routes>
        <Route
          path='/'
          element={
            <UsersIndex
              rootLink={rootLink}
              response={response}
              setResponse={setResponse}
              alert={alert}
              setAlert={setAlert}
            />
          }
        />

        <Route
          path='/:username'
          element={
            <ViewUser
              rootLink={rootLink}
              response={response}
              setResponse={setResponse}
              alert={alert}
              setAlert={setAlert}
            />
          }
        />

        <Route
          path='/:username/transactions'
          element={
            <UserTransactions
              rootLink={rootLink}
              response={response}
              setResponse={setResponse}
              alert={alert}
              setAlert={setAlert}
            />
          }
        />

        <Route
          path='/:username/transactions/:id'
          element={
            <ViewUserTransaction
              rootLink={rootLink}
              response={response}
              setResponse={setResponse}
              alert={alert}
              setAlert={setAlert}
            />
          }
        />
      </Routes>
    </>
  );
}

export default UsersRouter;
