import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

function ResetPassword({
  rootLink,
  setResponse,
  setAlert,
  username,
  modal,
  setModal,
  loadDetails,
}) {
  const [password, setPassword] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (password === "") {
      const msg = "Password is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (password) {
      setLoadingBtn(true);

      const formData = new FormData();

      formData.append("ResetPassword", username);
      formData.append("password", password);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/admin/users.php", true);
      http.onload = function () {
        const jsonData = JSON.parse(this.responseText);

        setResponse(jsonData);
        loadDetails();
        setModal(false);
        setAlert(true);
        setLoadingBtn(false);
      };
      http.send(formData);
      //END OF AJAX
    }
  };
  //END OF FORM SUBMIT

  return (
    <Dialog open={modal} fullWidth>
      <DialogTitle sx={{ textAlign: "center" }}>Reset Password</DialogTitle>

      <>
        <DialogContent>
          <label htmlFor='password'>Password</label>
          <input
            type='password'
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            name='password'
            placeholder='Password'
          />

          <DialogActions
            sx={{
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Button
              color='error'
              variant='contained'
              onClick={() => {
                setModal(false);
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loadingBtn ? true : false}
              onClick={formSubmit}
              variant='contained'
            >
              Reset
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </>
    </Dialog>
  );
}

export default ResetPassword;
