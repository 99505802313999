import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

function ModalAirtime({
  rootLink,
  setResponse,
  setAlert,
  username,
  modal,
  setModal,
  loadDetails,
  loadTransactions,
}) {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [networks, setNetworks] = useState([]);
  const [network, setNetwork] = useState("");
  const [user, setUser] = useState("");
  const [networkCode, setNetworkCode] = useState("");
  const [balance, setBalance] = useState("");
  const [phone, setPhone] = useState("");
  const [amount, setAmount] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    loadNetworks();
    loadUserDetails();
  }, []);

  //LOAD USER DETAILS
  const loadUserDetails = () => {
    const formData = new FormData();
    formData.append("loadUserDetails", username);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/checker.php", true);
    http.onload = function () {
      setBalance(JSON.parse(this.responseText).balance);
      setSecretKey(JSON.parse(this.responseText).secret_key);
      setUser(JSON.parse(this.responseText).user_id);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD USER DETAILS

  //LOAD NETWORKS
  const loadNetworks = () => {
    const formData = new FormData();
    formData.append("loadNetWorks", "load");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/user/airtime.php", true);
    http.onload = function () {
      setNetworks(JSON.parse(this.responseText));
      setNetworkCode(JSON.parse(this.responseText).network_code);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD NETWORKS

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (network === "") {
      const msg = "Network is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (isNaN(amount)) {
      const msg = "Amount is invalid";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (amount === "") {
      const msg = "Amount is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (amount > balance) {
      const msg = "Insufficient fund";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (phone === "") {
      const msg = "Phone number is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (isNaN(phone)) {
      const msg = "Phone number is invalid";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (
      network &&
      !isNaN(amount) &&
      amount &&
      amount <= balance &&
      phone &&
      !isNaN(phone)
    ) {
      setLoadingBtn(true);

      const formData = new FormData();
      formData.append("PurchaseAirtime", "Buy");
      formData.append("network", network);
      formData.append("network_code", networkCode);
      formData.append("amount", amount);
      formData.append("mobile_number", phone);
      formData.append("user_id", user);
      formData.append("api_key", secretKey);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "api/airtime.php", true);
      http.onload = function () {
        const jsonData = JSON.parse(this.responseText);

        setResponse(JSON.parse(this.responseText));
        setModal(false);
        setAlert(true);
        setLoadingBtn(false);

        loadDetails();
        loadTransactions();
      };
      http.send(formData);

      //CODE HERE
    }
  };
  //END OF FORM SUBMIT

  return (
    <Dialog open={modal} fullWidth>
      <DialogTitle sx={{ textAlign: "center" }}>Buy Airtime</DialogTitle>

      <>
        <DialogContent>
          <select
            onChange={(e) => {
              setNetwork(e.target.value);
            }}
          >
            <option value=''>Select Network</option>
            {networks.map((item) => {
              return (
                <React.Fragment key={item.ref}>
                  <option disabled={item.disabled === "true" ? true : false}>
                    {item.network}
                  </option>
                </React.Fragment>
              );
            })}
          </select>

          <input
            type='number'
            onChange={(e) => setAmount(e.target.value)}
            placeholder='Amount'
          />

          <input
            type='number'
            onChange={(e) => setPhone(e.target.value)}
            placeholder='Phone Number'
          />

          <DialogActions
            sx={{
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Button
              color='error'
              variant='contained'
              onClick={() => {
                setModal(false);
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loadingBtn ? true : false}
              onClick={formSubmit}
              variant='contained'
            >
              Buy
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </>
    </Dialog>
  );
}

export default ModalAirtime;
