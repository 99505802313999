import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useState, useEffect } from "react";
import Loading1 from "../../Loading/Loading1";

function EditNetwork({
  modal,
  rootLink,
  setResponse,
  setModal,
  setAlert,
  reload,
  reference,
}) {
  const [network, setNetwork] = useState("");
  const [networkCode, setNetworkCode] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadNetwork();
  }, []);

  //LOAD NETWORK
  const loadNetwork = () => {
    const formData = new FormData();
    formData.append("loadNetwork", reference);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/dataManager.php", true);
    http.onload = function () {
      setTimeout(() => {
        setNetwork(JSON.parse(this.responseText).network);
        setNetworkCode(JSON.parse(this.responseText).network_code);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD NETWORK

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (network === "") {
      const msg = "Network name is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (networkCode === "") {
      const msg = "Network code is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (network && networkCode) {
      setLoadingBtn(true);

      const formData = new FormData();
      formData.append("EditNetwork", reference);
      formData.append("network", network);
      formData.append("networkCode", networkCode);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/admin/dataManager.php", true);
      http.onload = function () {
        setResponse(JSON.parse(this.responseText));
        setModal(false);
        setAlert(true);
        setLoadingBtn(false);

        if (JSON.parse(this.responseText).status === "success") {
          reload();
          setModal(false);
        }
      };
      http.send(formData);
    }
  };
  //END OF FORM SUBMIT

  return (
    <Dialog open={modal} fullWidth>
      <DialogTitle sx={{ textAlign: "center" }}>
        Edit {network} Network
      </DialogTitle>

      {network === "" ? (
        <Loading1 loading={isLoading} />
      ) : (
        <>
          <DialogContent>
            <label htmlFor='network'>Network Name</label>{" "}
            <input
              placeholder='Network Name'
              type='text'
              onChange={(e) => setNetwork(e.target.value)}
              sx={{ marginTop: "5px" }}
              value={network}
              name='network'
              required
            />
            <label htmlFor='networkCode'>Network Code</label>{" "}
            <input
              placeholder='Network Code'
              type='text'
              onChange={(e) => setNetworkCode(e.target.value)}
              name='networkCode'
              value={networkCode}
              required
            />
            <DialogActions
              sx={{
                justifyContent: "center",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              <Button
                color='error'
                variant='contained'
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={loadingBtn ? true : false}
                onClick={formSubmit}
                variant='contained'
              >
                Edit
              </LoadingButton>
            </DialogActions>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}

export default EditNetwork;
