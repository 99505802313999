import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useState, useEffect } from "react";
import Loading1 from "../../../Loading/Loading1";
import { useNavigate } from "react-router-dom";

function ConfirmData({
  modal,
  rootLink,
  setResponse,
  setModal,
  setAlert,
  reference,
  phone,
  user,
}) {
  const [dataPlan, setDataPlan] = useState("");
  const [dataCode, setDataCode] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [dataPrice, setDataPrice] = useState("");
  const [networkCode, setNetworkCode] = useState("");
  const [network, setNetwork] = useState("");
  const [networkRef, setNetworkRef] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [content, setContent] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    loadDataDetails();
    loadSecretKey();
  }, []);

  //LOAD DATA DETAILS
  const loadDataDetails = () => {
    const formData = new FormData();
    formData.append("loadDataDetails", reference);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/user/data.php", true);
    http.onload = function () {
      setNetworkCode(JSON.parse(this.responseText).network_code);
      setNetworkRef(JSON.parse(this.responseText).ref);
      setDataPrice(JSON.parse(this.responseText).data_price);
      setDataCode(JSON.parse(this.responseText).data_code);
      setDataPlan(JSON.parse(this.responseText).data_plan);
      setNetwork(JSON.parse(this.responseText).network);

      setTimeout(() => {
        setContent(JSON.parse(this.responseText).data_plan);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD DATA DETAILS

  //LOAD SECRET KEY
  const loadSecretKey = () => {
    const formData = new FormData();
    formData.append("loadSecretKey", user);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/user/data.php", true);
    http.onload = function () {
      setSecretKey(JSON.parse(this.responseText).secret_key);

      setTimeout(() => {
        setContent(JSON.parse(this.responseText).data_plan);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD SECRET KEY

  //PURCHASE DATA
  const continuePurchase = () => {
    setLoadingBtn(true);

    const formData = new FormData();
    formData.append("data_plan", reference);
    formData.append("mobile_number", phone);
    formData.append("user_id", user);
    formData.append("data_code", dataCode);
    formData.append("network_code", networkCode);
    formData.append("api_key", secretKey);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "api/data.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);

      setResponse(JSON.parse(this.responseText));
      setModal(false);
      setAlert(true);

      if (jsonData.status === "success") {
        setTimeout(() => {
          navigate("/user/transactions/" + jsonData.ref);
        }, 3000);
      }
      setLoadingBtn(false);
    };
    http.send(formData);
  };
  //END OF PURCHASE DATA

  return (
    <Dialog open={modal} fullWidth>
      {dataPlan === "" ? (
        <Loading1 loading={isLoading} />
      ) : (
        <>
          <DialogContent>
            <center>
              Data Plan
              <h2>
                {network} - {dataPlan}
              </h2>
              Amount
              <h2>&#8358;{dataPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h2>
              Phone Number
              <h2>{phone}</h2>
            </center>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Button
              color='error'
              variant='contained'
              onClick={() => {
                setModal(false);
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loadingBtn ? true : false}
              variant='contained'
              onClick={continuePurchase}
            >
              Continue
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export default ConfirmData;
