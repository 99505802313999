import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useState, useEffect } from "react";
import Loading1 from "../../../Loading/Loading1";

function ConfirmBetting({
  modal,
  rootLink,
  setResponse,
  setModal,
  setAlert,
  company,
  amount,
  customerID,
  user,
  customerName,
}) {
  const [companyCode, setCompanyCode] = useState("");
  const [companyRef, setCompanyRef] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [content, setContent] = useState("");
  const [secretKey, setSecretKey] = useState("");

  useEffect(() => {
    loadSecretKey();
    loadBettingDetails();
  }, []);

  //LOAD SECRET KEY
  const loadSecretKey = () => {
    const formData = new FormData();
    formData.append("loadSecretKey", user);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/user/data.php", true);
    http.onload = function () {
      setSecretKey(JSON.parse(this.responseText).secret_key);
    };
    http.send(formData);
  };
  //END OF LOAD SECRET KEY

  //LOAD BETTING COMPANY
  const loadBettingDetails = () => {
    const formData = new FormData();
    formData.append("loadThisCompany", company);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/user/betting.php", true);
    http.onload = function () {
      setCompanyCode(JSON.parse(this.responseText).company_code);
      setCompanyRef(JSON.parse(this.responseText).ref);

      setTimeout(() => {
        setContent(JSON.parse(this.responseText).data_plan);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD BETTING COMPANY

  //BET FUNDING
  const continuePurchase = () => {
    setLoadingBtn(true);

    const formData = new FormData();
    formData.append("FundBet", "Fund");
    formData.append("company", company);
    formData.append("company_code", companyCode);
    formData.append("amount", amount);
    formData.append("customer_id", customerID);
    formData.append("customer_name", customerName);
    formData.append("user_id", user);
    formData.append("api_key", secretKey);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "api/betting.php", true);
    http.onload = function () {
      setResponse(JSON.parse(this.responseText));
      setModal(false);
      setAlert(true);
      setLoadingBtn(false);
    };
    http.send(formData);
  };
  //END OF BET FUNDING

  return (
    <Dialog open={modal} fullWidth>
      <DialogContent sx={{ wordWrap: "break-word" }}>
        <center>
          Betting Company
          <h2>{company}</h2>
          Amount
          <h2>&#8358;{amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h2>
          Customer ID
          <h2>{customerID}</h2>
          Customer Name
          <h2>{customerName}</h2>
        </center>
      </DialogContent>

      <DialogActions
        sx={{
          justifyContent: "center",
          flexWrap: "wrap",
          gap: "5px",
        }}
      >
        <Button
          color='error'
          variant='contained'
          onClick={() => {
            setModal(false);
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={loadingBtn ? true : false}
          variant='contained'
          onClick={continuePurchase}
        >
          Continue
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmBetting;
