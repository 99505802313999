import React from "react";
import { Link } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import {useNavigate} from 'react-router-dom'

function NotAdmin() {
const navigate = useNavigate()

  return (
    <div className='loginContainer'>
      <img
        onClick={() => navigate("/")}
        className='onlyLogo'
        src='/resources/logo/zicopawa.png'
      />

      <div className='loginWrapper'>
        <h2>You're not an admin</h2>

        <Link to='/user'>
          <ArrowBack />
        </Link>
      </div>
    </div>
  );
}

export default NotAdmin;
