import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  AccountBalance,
  Wifi,
  Smartphone,
  Search,
  CancelOutlined,
  MoveDown,
  Receipt,
  Tv,
} from "@mui/icons-material";
import ReactTimeAgo from "react-time-ago";

function SearchTransactions({ rootLink, setContent, content }) {
  const [text, setText] = useState("");
  const [show, setShow] = useState(false);

  //SEARCH USER
  const search = (input) => {
    const formData = new FormData();
    formData.append("searchTransactions", input);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/transactions.php", true);
    http.onload = function () {
      setContent(JSON.parse(this.responseText));
    };
    http.send(formData);
  };
  //END OF SEARCH USER

  //DEPLAY SEARCH
  const display = () => {
    if (show === true) {
      setShow(false);
      setText("");
    } else {
      setShow(true);
    }
  };
  //END OF DEPLAY SEARCH

  return (
    <>
      <div className={show === true ? "search flex" : "search"}>
        <input
          type='text'
          onChange={(e) => {
            search(e.target.value);
            setText(e.target.value);
          }}
          placeholder='Search with username, transanction ID or amount'
          className={show ? "show" : "hide"}
        />
        <button className={show ? "show" : "hide"} onClick={display}>
          {!show ? <Search /> : <CancelOutlined />}
        </button>
      </div>

      {show ? (
        <div className='searchResult'>
          {content.length > 0 ? (
            <>
              {content.map((item) => {
                return (
                  <Link
                    to={"../" + item.ref}
                    key={item.ref}
                    className='dashboardTransactions'
                  >
                    <div className='title'>
                      <div className='icon'>
                        {item.transaction === "Deposit" ||
                        item.transaction === "Debit" ? (
                          <AccountBalance />
                        ) : item.transaction === "Transfer" ? (
                          <MoveDown />
                        ) : item.transaction === "TV Subscription" ? (
                          <Tv />
                        ) : item.transaction === "Data" ? (
                          <Wifi />
                        ) : item.transaction === "Bet Funding" ? (
                          <Receipt />
                        ) : item.transaction === "Airtime" ? (
                          <Smartphone />
                        ) : null}
                      </div>

                      <div className='nameWraper'>
                        <div className='name'>{item.transaction}</div>
                        <div
                          className={
                            item.status === "Successful" ||
                            item.status === "Completed"
                              ? "status success"
                              : item.status === "Failed" ||
                                item.status === "Cancelled"
                              ? "status failed"
                              : "status pending"
                          }
                        >
                          {item.status}
                        </div>
                      </div>
                    </div>

                    <div className='details'>
                      <div className='amount'>&#8358;{item.amount}</div>
                      <div className='date'>
                        <ReactTimeAgo date={item.started} />
                      </div>
                    </div>
                  </Link>
                );
              })}
            </>
          ) : (
            <center>
              <h3>No result found</h3>
            </center>
          )}
        </div>
      ) : null}
    </>
  );
}

export default SearchTransactions;
