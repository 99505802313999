import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  Phone,
  Person,
  Email,
  CalendarMonth,
  Reply,
  ArrowBack,
} from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";

function ViewMessage({ rootLink, response, setResponse, alert, setAlert }) {
  const [content, setContent] = useState([]);
  const [replies, setReplies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    loadMessage();
    loadReplies();
  }, []);

  //LOAD MESSAGE DETAILS
  const loadMessage = () => {
    const formData = new FormData();
    formData.append("loadMessage", id);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/messages.php", true);
    http.onload = function () {
      setContent(JSON.parse(this.responseText));

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD MESSAGE DETAILS

  //LOAD REPLIES
  const loadReplies = () => {
    const formData = new FormData();
    formData.append("loadReplies", id);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/messages.php", true);
    http.onload = function () {
      setReplies(JSON.parse(this.responseText));

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD REPLIES

  return (
    <>
      <IconButton onClick={() => navigate("../")}>
        <ArrowBack />
      </IconButton>

      <div className='messageWrapper'>
        <div className='infoCard'>
          <div className='name'>
            <Person />{" "}
            {content.username === "" ? (
              "Anonymous"
            ) : (
              <Link to={"/admin/users/" + content.username}>
                {content.username}
              </Link>
            )}
          </div>
          <div>
            <Email /> {content.email}
          </div>
          <div>
            <Phone />
            <a href={"tel:" + content.phone}>{content.phone}</a>
          </div>
          <div>
            <CalendarMonth />
            {content.date}
          </div>
        </div>

        <div className='messageSection'>
          <div className='subject'>{content.category}</div>
          <div className='message'>{content.message}</div>

          <center>
            <div className='actionSection'>
              <Button onClick={() => navigate("./reply")}>
                <Reply /> Reply
              </Button>
            </div>
          </center>

          {replies.length > 0 ? (
            <div className='repliesSection'>
              <h2 style={{ textAlign: "center" }}>
                {replies.length > 1 ? "Replies" : "Reply"}
              </h2>
              <div className='repliesWrapper'>
                {replies.map((item) => {
                  return (
                    <div key={item.ref} className='reply'>
                      <div className='date'>{item.date}</div>
                      <p>{item.message}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default ViewMessage;
