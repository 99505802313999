import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useState, useEffect } from "react";
import Loading1 from "../../Loading/Loading1";

function EditNetwork({
  modal,
  rootLink,
  setResponse,
  setModal,
  setAlert,
  reload,
  reference,
}) {
  const [company, setCompany] = useState("");
  const [companyCode, setCompanyCode] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadCompany();
  }, []);

  //LOAD COMPANY
  const loadCompany = () => {
    const formData = new FormData();
    formData.append("loadCompany", reference);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/bettingManager.php", true);
    http.onload = function () {
      setTimeout(() => {
        setCompany(JSON.parse(this.responseText).company);
        setCompanyCode(JSON.parse(this.responseText).company_code);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD COMPANY

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (company === "") {
      const msg = "Betting company name is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (companyCode === "") {
      const msg = "Betting company code is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (company && companyCode) {
      setLoadingBtn(true);

      const formData = new FormData();
      formData.append("EditCompany", reference);
      formData.append("company", company);
      formData.append("companyCode", companyCode);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/admin/bettingManager.php", true);
      http.onload = function () {
        setResponse(JSON.parse(this.responseText));
        setModal(false);
        setAlert(true);
        setLoadingBtn(false);

        if (JSON.parse(this.responseText).status === "success") {
          reload();
          setModal(false);
        }
      };
      http.send(formData);
    }
  };
  //END OF FORM SUBMIT

  return (
    <Dialog open={modal} fullWidth>
      <DialogTitle sx={{ textAlign: "center" }}>Edit {company}</DialogTitle>

      {company === "" ? (
        <Loading1 loading={isLoading} />
      ) : (
        <>
          <DialogContent>
            <label htmlFor='company'>Betting Company Name</label>
            <input
              placeholder='Betting Company Name'
              type='text'
              name='company'
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              sx={{ marginTop: "5px" }}
              required
            />

            <label htmlFor='code'>Betting Company Code</label>
            <input
              placeholder='Betting Company Code'
              name='code'
              type='text'
              value={companyCode}
              onChange={(e) => setCompanyCode(e.target.value)}
              required
            />

            <DialogActions
              sx={{
                justifyContent: "center",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              <Button
                color='error'
                variant='contained'
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={loadingBtn ? true : false}
                onClick={formSubmit}
                variant='contained'
              >
                Edit
              </LoadingButton>
            </DialogActions>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}

export default EditNetwork;
