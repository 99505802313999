import React, { useState, useEffect } from "react";
import Nav from "../../../components/nav/Nav";
import AddCompany from "../../../components/modal/BettingManager/AddCompany";
import Loading1 from "../../../components/Loading/Loading1";
import EditCompany from "../../../components/modal/BettingManager/EditCompany";
import DeleteCompany from "../../../components/modal/BettingManager/DeleteCompany";
import DisableCompany from "../../../components/modal/BettingManager/Disable";
import { Link, useNavigate } from "react-router-dom";
import {
  Delete,
  Edit,
  Pause,
  PlayArrow,
  Stop,
  ArrowBack,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";

function BettingManagerIndex({
  rootLink,
  response,
  setResponse,
  alert,
  setAlert,
}) {
  const [addCompany, setAddCompany] = useState(false);
  const [content, setContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [reference, setReference] = useState("");
  const [editCompany, setEditCompany] = useState(false);
  const [deleteCompany, setDeleteCompany] = useState(false);
  const [disableCompany, setDisableCompany] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadCompanies();
  }, []);

  //LOAD COMPANIES
  const loadCompanies = () => {
    const formData = new FormData();
    formData.append("loadCompanies", "load");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/bettingManager.php", true);
    http.onload = function () {
      setContent(JSON.parse(this.responseText));

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD COMPANIES

  //EDIT MODAL
  const openEditModal = (ref) => {
    setReference(ref);
    setEditCompany(true);
  };

  //END OF EDIT MODAL

  //DISABLE MODAL
  const openDisableModal = (ref) => {
    setReference(ref);
    setDisableCompany(true);
  };

  //END OF DISABLE MODAL

  //DELETE MODAL
  const openDeleteModal = (ref) => {
    setReference(ref);
    setDeleteCompany(true);
  };

  //END OF DELETE MODAL

  return (
    <>
      <div className='firstWrapper'>
        <div className='backButton'>
          <IconButton onClick={() => navigate("../../")}>
            <ArrowBack />
          </IconButton>
          <h1 className='firstHeader'>Betting Manager</h1>
        </div>

        <div className='firstContent'>
          <div onClick={() => setAddCompany(true)} className='addButton'>
            New Company
          </div>

          {content.length === "" ? (
            <h2 className='center'>No company is available</h2>
          ) : (
            <>
              <div>
                {content.map((item) => {
                  return (
                    <div key={item.ref} className='listItem'>
                      <div className='left'>
                        <div className='bigText'>{item.company}</div>
                        <div className='smallText'>
                          Code: {item.company_code}
                        </div>
                      </div>
                      <div className='right flex'>
                        <IconButton onClick={() => openEditModal(item.ref)}>
                          <Edit color='primary' />
                        </IconButton>
                        <IconButton onClick={() => openDisableModal(item.ref)}>
                          {item.disabled === "false" ? (
                            <Pause color='primary' />
                          ) : (
                            <PlayArrow />
                          )}
                        </IconButton>{" "}
                        <IconButton onClick={() => openDeleteModal(item.ref)}>
                          <Delete color='error' />
                        </IconButton>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}

          {addCompany ? (
            <AddCompany
              modal={addCompany}
              setModal={setAddCompany}
              setResponse={setResponse}
              setAlert={setAlert}
              rootLink={rootLink}
              reload={loadCompanies}
            />
          ) : null}

          {editCompany ? (
            <EditCompany
              reference={reference}
              modal={editCompany}
              setModal={setEditCompany}
              setResponse={setResponse}
              setAlert={setAlert}
              rootLink={rootLink}
              reload={loadCompanies}
            />
          ) : null}

          {disableCompany ? (
            <DisableCompany
              reference={reference}
              modal={disableCompany}
              setModal={setDisableCompany}
              setResponse={setResponse}
              setAlert={setAlert}
              rootLink={rootLink}
              reload={loadCompanies}
            />
          ) : null}

          {deleteCompany ? (
            <DeleteCompany
              reference={reference}
              modal={deleteCompany}
              setModal={setDeleteCompany}
              setResponse={setResponse}
              setAlert={setAlert}
              rootLink={rootLink}
              reload={loadCompanies}
            />
          ) : null}

          <Loading1 loading={isLoading} />
        </div>
      </div>
    </>
  );
}

export default BettingManagerIndex;
