import React, { useState, useEffect } from "react";
import Nav from "../../../components/nav/Nav";
import Loading1 from "../../../components/Loading/Loading1";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ArrowBack } from "@mui/icons-material";
import HTMLReactParser from "html-react-parser";

const Editor = React.lazy(() => import("../../../components/Editor/Editor"));

function EmailIndex({ rootLink, response, setResponse, alert, setAlert }) {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [ready, setReady] = useState(false);
  const [content, setContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadEmails();
  }, []);

  //LOAD CONTENT
  const loadEmails = () => {
    const formData = new FormData();
    formData.append("loadEmails", "load");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/email.php", true);
    http.onload = function () {
      setContent(JSON.parse(this.responseText));
      setReady(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD CONTENT

  //FORM SUBMIT
  const formSubmit = () => {
    const formData = new FormData();
    formData.append("sendBulkEmail", "email");
    formData.append("subject", subject);
    formData.append("message", message);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/email.php", true);
    http.onload = function () {
      setResponse(JSON.parse(this.responseText));
      setAlert(true);
      loadEmails();

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };

  //END OF FORM SUBMIT

  return (
    <>
      <div className='firstWrapper'>
        <div className='backButton'>
          <IconButton onClick={() => navigate("../../")}>
            <ArrowBack />
          </IconButton>
          <h1 className='firstHeader'>Bulk Email</h1>
        </div>

        <div className='firstContent'>
          <label htmlFor='subject'>Subject</label>
          <input
            type='text'
            onChange={(e) => setSubject(e.target.value)}
            name='subject'
            placeholder='Subject'
          />

          <label htmlFor='message'>Message</label>
          {ready ? <Editor value={message} setValue={setMessage} /> : null}
          <br />

          <button
            className='width100'
            onClick={formSubmit}
            loading={loadingBtn ? true : false}
            variant='contained'
          >
            Send
          </button>
        </div>

        {content.length > 0 ? (
          <>
            <h2 style={{ padding: "10px" }}>Previous Emails</h2>
            <div className='firstContent'>
              {content.map((item) => {
                return (
                  <Link
                    to={"./" + item.ref}
                    key={item.ref}
                    className='listItem'
                  >
                    <div className='left width80'>
                      <div className='subject'>{item.subject}</div>
                      <div className='message'>
                        {item.message ? HTMLReactParser(item.message) : null}
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </>
        ) : null}
      </div>
      <Loading1 loading={isLoading} />
    </>
  );
}

export default EmailIndex;
