import React, { useState, useEffect } from "react";
import Nav from "../../../components/nav/Nav";
import Loading1 from "../../../components/Loading/Loading1";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ArrowBack } from "@mui/icons-material";

const Editor = React.lazy(() => import("../../../components/Editor/Editor"));

function PolicyIndex({ rootLink, response, setResponse, alert, setAlert }) {
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [ready, setReady] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadPolicies();
  }, []);

  //LOAD CONTENT
  const loadPolicies = () => {
    const formData = new FormData();
    formData.append("loadPolicies", "load");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/policies.php", true);
    http.onload = function () {
      setContent(this.responseText);

      setReady(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD CONTENT

  //FORM SUBMIT
  const formSubmit = () => {
    const formData = new FormData();
    formData.append("editPolicies", content);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/policies.php", true);
    http.onload = function () {
      setResponse(JSON.parse(this.responseText));
      setAlert(true);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };

  //END OF FORM SUBMIT

  return (
    <>
      <div className='firstWrapper'>
        <div className='backButton'>
          <IconButton onClick={() => navigate("../../")}>
            <ArrowBack />
          </IconButton>
          <h1 className='firstHeader'>Privacy Policy</h1>
        </div>

        <div className='firstContent'>
          {ready ? <Editor value={content} setValue={setContent} /> : null}
          <br />

          <button
            onClick={formSubmit}
            loading={loadingBtn ? true : false}
            className='width100'
          >
            Save
          </button>

          <Loading1 loading={isLoading} />
        </div>
      </div>
    </>
  );
}

export default PolicyIndex;
