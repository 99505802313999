import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

function BanUser({
  rootLink,
  setResponse,
  setAlert,
  username,
  modal,
  setModal,
  loadDetails,
}) {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    setLoadingBtn(true);

    const formData = new FormData();

    formData.append("BanUser", username);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/users.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);

      setResponse(jsonData);
      loadDetails();
      setModal(false);
      setAlert(true);
      setLoadingBtn(false);
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF FORM SUBMIT

  return (
    <Dialog open={modal} fullWidth>
      <DialogTitle sx={{ textAlign: "center" }}>
        You're about to ban <strong>{username}</strong>
      </DialogTitle>

      <>
        <DialogContent>
          <DialogActions
            sx={{
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Button
              variant='contained'
              onClick={() => {
                setModal(false);
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loadingBtn ? true : false}
              onClick={formSubmit}
              variant='contained'
              color='error'
            >
              Ban
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </>
    </Dialog>
  );
}

export default BanUser;
