import React, { useState, useEffect } from "react";
import ConfirmAirtime from "../../../components/modal/User/Airtime/Confirm";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { IconButton } from "@mui/material";

function PasswordIndex({ rootLink, response, setResponse, alert, setAlert }) {
  const [current, setCurrent] = useState([]);
  const [newPass, setNewPass] = useState("");
  const [confirm, setConfirm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const user = Cookies.get("id");
  const navigate = useNavigate();

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (current === "") {
      const msg = "Current password is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (newPass === "") {
      const msg = "Enter new password";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (newPass !== confirm) {
      const msg = "New password does not match with confirm password";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (confirm === "") {
      const msg = "Confirm new password";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (current && newPass && confirm && newPass === confirm) {
      const formData = new FormData();

      formData.append("changePassword", "load");
      formData.append("current", current);
      formData.append("newPass", newPass);
      formData.append("confirm", confirm);
      formData.append("user_id", user);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/server/password.php", true);
      http.onload = function () {
        const jsonData = JSON.parse(this.responseText);
        setResponse(JSON.parse(this.responseText));
        setAlert(true);

        if (jsonData.status === "success") {
          Cookies.remove("id");
          Cookies.remove("ref");
          Cookies.remove("token");

          setTimeout(() => {
            navigate("/login");
          }, 3000);
        }
      };
      http.send(formData);
    }
  };
  //END OF FORM SUBMIT

  return (
    <div className='firstWrapper'>
      <div className='backButton'>
        <IconButton onClick={() => navigate("../../")}>
          <ArrowBack />
        </IconButton>
        <h1 className='firstHeader'>Change Password</h1>
      </div>

      <div className='firstContent padding10'>
        <form onSubmit={formSubmit}>
          <label htmlFor='current'>Current Password</label>
          <input
            onChange={(e) => setCurrent(e.target.value)}
            placeholder='Current Password'
            type='password'
            fullWidth
            name='current'
          />
          <label htmlFor='newPass'>New Password</label>
          <input
            onChange={(e) => setNewPass(e.target.value)}
            placeholder='New Password'
            type='password'
            fullWidth
            name='newPass'
          />
          <label htmlFor='confirm'>Confirm Password</label>
          <input
            onChange={(e) => setConfirm(e.target.value)}
            placeholder='Confirm Password'
            type='password'
            fullWidth
            name='confirm'
          />

          <button
            className='width100'
            onClick={formSubmit}
            loading={loadingBtn ? true : false}
          >
            Change Password
          </button>
        </form>
      </div>
    </div>
  );
}

export default PasswordIndex;
