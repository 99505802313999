import { useEffect, useState, forwardRef } from "react";
import Cookies from "js-cookie";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Paper, Stack, Snackbar, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Loading1 from "../Loading/Loading1";

const SnackbarAlert = forwardRef(function SnackbarAlert(props, ref) {
  return <Alert elevation={6} ref={ref} {...props} />;
});

function Register({ rootLink }) {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [availEmail, setAvailEmail] = useState(false);
  const [phone, setPhone] = useState("");
  const [availPhone, setAvailPhone] = useState(false);
  const [username, setUsername] = useState("");
  const [availUser, setAvailUser] = useState(false);
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [agree, setAgree] = useState();
  const [response, setResponse] = useState({
    status: "",
    response: "",
    id: "",
    saved: "",
  });
  const [alert, setAlert] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (fname === "") {
      const msg = "First name is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (lname === "") {
      const msg = "Last name is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (email === "") {
      const msg = "Email is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (availEmail === false) {
      const msg = "Email already exist";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (!isEmail(email)) {
      const msg = "Email is invalid";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (phone === "") {
      const msg = "Phone number is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (availPhone === false) {
      const msg = "Phone number already exist";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (isNaN(phone)) {
      const msg = "Phone number is invalid";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (username === "") {
      const msg = "Username is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (availUser === false) {
      const msg = "Username already exist";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (password === "") {
      const msg = "Password is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (cPassword === "") {
      const msg = "Confirm password is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (password.length < 8) {
      const msg = "Password must be atleast 8 characters";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (cPassword !== password) {
      const msg = "Confirm password must match with the password";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (!agree) {
      const msg = "You must agree to our terms and conditions";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (
      fname &&
      lname &&
      email &&
      isEmail(email) &&
      availEmail === true &&
      phone &&
      !isNaN(phone) &&
      availPhone === true &&
      username &&
      availUser === true &&
      password &&
      cPassword &&
      password.length >= 8 &&
      cPassword === password &&
      agree
    ) {
      setBtnLoader(true);

      const formData = new FormData();
      formData.append("RegisterUser", "register");
      formData.append("fname", fname);
      formData.append("lname", lname);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("username", username.replace(/ /g, ""));
      formData.append("password", password);

      const ajax = new XMLHttpRequest();
      ajax.open("POST", rootLink + "backend/access/register.php", true);
      ajax.onload = function () {
        const jsonData = JSON.parse(this.responseText);


        if (jsonData.status === "success") {
          if (jsonData.remember === "yes") {
            Cookies.set("ref", jsonData.ref, { expires: 30 });
            Cookies.set("id", jsonData.id, { expires: 30 });
            Cookies.set("token", jsonData.token, { expires: 30 });
          } else {
            Cookies.set("ref", jsonData.ref, { expires: 1 });
            Cookies.set("id", jsonData.id, { expires: 1 });
            Cookies.set("token", jsonData.token, { expires: 1 });
          }
          setResponse(JSON.parse(this.responseText));
          setAlert(true);

          setTimeout(() => {
            navigate("/user");
          }, 2000);
        } else {
          setResponse(JSON.parse(this.responseText));
          setAlert(true);
        }

        if (jsonData.status === "success") {
          //Clear Fields
          setFname("");
          setLname("");
          setEmail("");
          setPhone("");
          setUsername("");
          setPassword("");
          setCPassword("");
        }

        setBtnLoader(false);
      };
      ajax.send(formData);
    }
  };
  //END OF FORM SUBMIT

  //USERNAME AVAILABILITY
  const available_username = (input) => {
    const formData = new FormData();
    formData.append("usernameCheck", input);
    const ajax = new XMLHttpRequest();
    ajax.open("POST", rootLink + "backend/access/register.php", true);
    ajax.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      if (jsonData.available === "yes") {
        setAvailUser(true);
      } else {
        setAvailUser(false);
      }
    };
    ajax.send(formData);
  };
  //END OF USERNAME AVAILABILITY

  //EMAIL AVAILABILITY
  const available_email = (input) => {
    const formData = new FormData();
    formData.append("emailCheck", input);
    const ajax = new XMLHttpRequest();
    ajax.open("POST", rootLink + "backend/access/register.php", true);
    ajax.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      if (jsonData.available === "yes") {
        setAvailEmail(true);
      } else {
        setAvailEmail(false);
      }
    };
    ajax.send(formData);
  };
  //END OF EMAIL AVAILABILITY

  //PHONE AVAILABILITY
  const available_phone = (input) => {
    const formData = new FormData();
    formData.append("phoneCheck", input);
    const ajax = new XMLHttpRequest();
    ajax.open("POST", rootLink + "backend/access/register.php", true);
    ajax.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      if (jsonData.available === "yes") {
        setAvailPhone(true);
      } else {
        setAvailPhone(false);
      }
    };
    ajax.send(formData);
  };
  //END OF PHONE AVAILABILITY

  function isEmail(email) {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  }

  if (response.response !== "") {
    setTimeout(() => {
      setResponse({
        status: "",
        response: "",
      });
    }, 5500);

    setTimeout(() => {
      setAlert(false);
    }, 5000);
  }

  return (
    <div className='loginContainer'>
      <img
        onClick={() => navigate("/")}
        className='onlyLogo'
        src='/resources/logo/zicopawa.png'
      />

      <div className='loginWrapper'>
        <h2>Registration</h2>
        <form>
          <label htmlFor='fname'>First Name</label>
          <input
            type='text'
            onChange={(e) => setFname(e.target.value)}
            placeholder='First Name'
            fullWidth
            name='fname'
          />
          <label htmlFor='lname'>Last Name</label>
          <input
            type='text'
            onChange={(e) => setLname(e.target.value)}
            placeholder='Last Name'
            fullWidth
            name='lname'
          />
          <label htmlFor='email'>Email</label>
          <input
            type='email'
            onChange={(e) => {
              setEmail(e.target.value);
              available_email(e.target.value);
            }}
            placeholder='Email'
            fullWidth
            name='email'
          />
          <label htmlFor='phone'>Phone Number</label>{" "}
          <input
            type='tel'
            onChange={(e) => {
              setPhone(e.target.value);
              available_phone(e.target.value);
            }}
            placeholder='Phone Number'
            fullWidth
            name='phone'
          />
          <label htmlFor='username'>Username</label>
          <input
            type='text'
            onChange={(e) => {
              setUsername(e.target.value);
              available_username(e.target.value);
            }}
            onBlur={(e) => available_username(e.target.value)}
            placeholder='Username'
            fullWidth
            name='username'
          />
          <label htmlFor='password'>Password</label>
          <input
            type='password'
            onChange={(e) => setPassword(e.target.value)}
            placeholder='Password'
            fullWidth
            name='password'
          />
          <label htmlFor='cPassword'>Confirm Password</label>
          <input
            type='password'
            onChange={(e) => setCPassword(e.target.value)}
            placeholder='Confirm Password'
            fullWidth
            name='cPassword'
          />
          <div
            style={{
              display: "flex",
              gap: "10px",
              marginBottom: "5px",
              padding: "5px",
            }}
          >
            <input
              type='checkbox'
              onChange={(e) => setAgree(e.target.checked)}
            />
            <div style={{ fontSize: "1.1rem" }}>
              I agree to the{" "}
              <a className='link' href='/#/terms' target='blank'>
                terms and conditions
              </a>{" "}
              and also the{" "}
              <a className='link' href='/#/policy' target='blank'>
                policies
              </a>
              .
            </div>
          </div>
          <button
            loading={btnLoader ? true : false}
            onClick={formSubmit}
            className={
              fname === "" ||
              lname === "" ||
              email === "" ||
              !isEmail(email) ||
              availEmail !== true ||
              phone === "" ||
              isNaN(phone) ||
              availPhone !== true ||
              username === "" ||
              availUser !== true ||
              password === "" ||
              cPassword === "" ||
              password.length < 8 ||
              cPassword !== password ||
              !agree
                ? "width100 disabled"
                : "width100"
            }
            disabled={
              fname === "" ||
              lname === "" ||
              email === "" ||
              !isEmail(email) ||
              availEmail !== true ||
              phone === "" ||
              isNaN(phone) ||
              availPhone !== true ||
              username === "" ||
              availUser !== true ||
              password === "" ||
              cPassword === "" ||
              password.length < 8 ||
              cPassword !== password ||
              !agree
                ? true
                : false
            }
          >
            Register
          </button>
        </form>
        <br />
        Already have an account{" "}
        <Link className='link' to={"/login"}>
          Log In
        </Link>
      </div>

      <Snackbar
        open={alert}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <SnackbarAlert color={response.status}>
          {response.response}
        </SnackbarAlert>
      </Snackbar>
    </div>
  );
}

export default Register;
