import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useState, useEffect } from "react";
import Loading1 from "../../../Loading/Loading1";
import HTMLReactParser from "html-react-parser";

function AnnounceModal({ modal, rootLink, setResponse, setModal, setAlert }) {
  const [announcement, setAnnouncement] = useState("");

  //LOAD ANNOUNCEMENT
  useEffect(() => {
    const formData = new FormData();
    formData.append("loadAnnouncement", "load");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/checker.php", true);
    http.onload = function () {
      setAnnouncement(JSON.parse(this.responseText).message);
    };
    http.send(formData);
  }, []);
  //END OF LOAD ANNOUNCEMENT

  return (
    <Dialog open={modal} fullWidth>
      <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
        Announcement
      </DialogTitle>
      <DialogContent sx={{ wordBreak: "keep-all", wordWrap: "break-word" }}>
        {announcement ? HTMLReactParser(announcement) : null}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          flexWrap: "wrap",
          gap: "5px",
          marginTop: "10px",
        }}
      >
        <Button
          color='primary'
          variant='outlined'
          onClick={() => {
            setModal(false);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AnnounceModal;
