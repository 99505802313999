import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Loading1 from "../../../components/Loading/Loading1";

const Editor = React.lazy(() => import("../../../components/Editor/Editor"));

function ReplyMessage({ rootLink, response, setResponse, alert, setAlert }) {
  const [content, setContent] = useState([]);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [ready, setReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    loadMessage();
  }, []);

  //LOAD MESSAGE DETAILS
  const loadMessage = () => {
    const formData = new FormData();
    formData.append("loadMessage", id);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/messages.php", true);
    http.onload = function () {
      setSubject(JSON.parse(this.responseText).category);
      setReady(true);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD MESSAGE DETAILS

  //REPLY MESSAGE
  const formSubmit = (e) => {
    e.preventDefault();

    if (message === "") {
      const msg = "Message is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (message) {
      const formData = new FormData();
      formData.append("ReplyMessage", id);
      formData.append("subject", "RE: " + subject);
      formData.append("message", message);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/admin/messages.php", true);
      http.onload = function () {
        setResponse(JSON.parse(this.responseText));
        setAlert(true);

        setTimeout(() => {
          navigate("/admin/messages/" + id);
        }, 5000);
      };
      http.send(formData);
    }
  };
  //END OF REPLY MESSAGE

  return (
    <>
      <div className='firstWrapper'>
        <div className='backButton'>
          <IconButton onClick={() => navigate("../" + id)}>
            <ArrowBack />
          </IconButton>
          <h1 className='firstHeader'>Reply Message</h1>
        </div>

        <div className='firstContent'>
          <label htmlFor='message'>Message</label>
          {ready ? <Editor value={message} setValue={setMessage} /> : null}
          <br />

          <button
            className='width100'
            onClick={formSubmit}
            loading={loadingBtn ? true : false}
            variant='contained'
          >
            Send
          </button>
        </div>
      </div>

      <Loading1 loading={isLoading} />
    </>
  );
}

export default ReplyMessage;
