import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useState } from "react";
import Loading1 from "../../Loading/Loading1";

function AddCable({
  modal,
  rootLink,
  setResponse,
  setModal,
  setAlert,
  reload,
}) {
  const [cable, setCable] = useState("");
  const [cableCode, setCableCode] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (cable === "") {
      const msg = "Cable name is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (cableCode === "") {
      const msg = "Cable code is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (cable && cableCode) {
      setLoadingBtn(true);

      const formData = new FormData();
      formData.append("AddCable", "Add");
      formData.append("cable", cable);
      formData.append("cableCode", cableCode);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/admin/tvManager.php", true);
      http.onload = function () {
        setResponse(JSON.parse(this.responseText));
        setModal(false);
        setAlert(true);
        setLoadingBtn(false);
        reload();
      };
      http.send(formData);
    }
  };
  //END OF FORM SUBMIT

  return (
    <Dialog open={modal} fullWidth>
      <DialogTitle sx={{ textAlign: "center" }}>Add Cable</DialogTitle>

      <DialogContent>
        <label htmlFor='cable'>Cable Name</label>{" "}
        <input
          placeholder='Cable Name'
          type='text'
          onChange={(e) => setCable(e.target.value)}
          sx={{ marginTop: "5px" }}
          name='cable'
          required
        />
        <label htmlFor='cableCode'>Cable Code</label>{" "}
        <input
          placeholder='Cable Code'
          type='text'
          onChange={(e) => setCableCode(e.target.value)}
          name='cableCode'
          required
        />
        <DialogActions
          sx={{
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "5px",
          }}
        >
          <Button
            color='error'
            variant='contained'
            onClick={() => {
              setModal(false);
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loadingBtn ? true : false}
            onClick={formSubmit}
            variant='contained'
          >
            Add
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default AddCable;
