import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";

function ResetPassword({ rootLink, response, setResponse, alert, setAlert }) {
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [saveLogin, setSaveLogin] = useState();
  const [btnLoader, setBtnLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [verify, setVerify] = useState({
    status: "",
    response: "",
  });
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    verifyToken();
  }, [token]);

  //VERIFY TOKEN
  const verifyToken = () => {
    const data = new FormData();
    data.append("VerifyToken", "check");
    data.append("token", token);

    const https = new XMLHttpRequest();
    https.open("POST", rootLink + "backend/access/forgotten.php", true);
    https.onload = function () {
      const res = JSON.parse(this.responseText);

      setVerify(res);

      if (res.status === "error") {
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      }

      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    };
    https.send(data);
  };
  //END OF VERIFY TOKEN

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();
    setBtnLoader(true);

    if (password === "") {
      const msg = "New password is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (cpassword === "") {
      const msg = "Confirm password is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (password !== cpassword) {
      const msg = "Password does not match with confirm password";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (password && cpassword && password === cpassword) {
      const formData = new FormData();
      formData.append("ResetPassword", token);
      formData.append("password", password);
      formData.append("cpassword", cpassword);

      const ajax = new XMLHttpRequest();
      ajax.open("POST", rootLink + "backend/access/forgotten.php", true);
      ajax.onload = function () {
        const jsonData = JSON.parse(this.responseText);
        setResponse(JSON.parse(this.responseText));
        setAlert(true);

        setTimeout(() => {
          navigate("/login");
        }, 3000);

        setBtnLoader(false);
      };
      ajax.send(formData);
    }
  };
  //END OF FORM SUBMIT

  return (
    <div className='loginContainer'>
      <div className='loginWrapper'>
        {verify.status === "success" ? (
          <>
            <h2>Reset Password</h2>
            <form onSubmit={formSubmit}>
              <label htmlFor='password'>New Password</label>
              <input
                type='password'
                onChange={(e) => setPassword(e.target.value)}
                placeholder='New Password'
                fullWidth
                name='password'
              />
              <label htmlFor='cpassword'>Confirm Password</label>
              <input
                type='password'
                onChange={(e) => setCPassword(e.target.value)}
                placeholder='Confirm Password'
                fullWidth
                name='cpassword'
              />

              <button
                loading={btnLoader ? true : false}
                variant='contained'
                onClick={formSubmit}
                className='width100'
              >
                Reset
              </button>
            </form>
          </>
        ) : (
          <>
            <h2>{verify.response}</h2>

            <br />
            <center>
              <Link to={"/login"}>
                <ArrowBack />
              </Link>
            </center>
          </>
        )}
      </div>
    </div>
  );
}

export default ResetPassword;
