import React, { useState, useEffect } from "react";
import Nav from "../../../components/nav/Nav";
import Loading1 from "../../../components/Loading/Loading1";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ArrowBack } from "@mui/icons-material";

function MessageIndex({ rootLink, response, setResponse, alert, setAlert }) {
  const [content, setContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadEmails();
  }, []);

  //LOAD CONTENT
  const loadEmails = () => {
    const formData = new FormData();
    formData.append("loadMessages", "load");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/messages.php", true);
    http.onload = function () {
      setContent(JSON.parse(this.responseText));

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD CONTENT

  return (
    <>
      <div className='firstWrapper'>
        <div className='backButton'>
          <IconButton onClick={() => navigate("../../")}>
            <ArrowBack />
          </IconButton>
          <h1 className='firstHeader'>Messages</h1>
        </div>

        <div className='firstContent'>
          {content.map((item) => {
            return (
              <Link to={"./" + item.ref} key={item.ref} className='listItem'>
                <div className='left text'>
                  <div className='sender'>
                    {item.username === "" ? "Anonymous" : item.username}
                  </div>
                  <div
                    className={
                      item.seen === "no" ? "subject unseen" : "subject"
                    }
                  >
                    {item.category}
                  </div>
                  <div
                    className={
                      item.seen === "no" ? "message unseen" : "message"
                    }
                  >
                    {item.message}
                  </div>
                </div>
              </Link>
            );
          })}
          {content.length === 0 ? (
            <div style={{ textAlign: "center", fontSize: "1.1rem" }}>
              No message
            </div>
          ) : null}
        </div>

        <Loading1 loading={isLoading} />
      </div>
    </>
  );
}

export default MessageIndex;
