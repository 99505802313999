import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useState, useEffect } from "react";
import Loading1 from "../../../Loading/Loading1";

function ConfirmTV({
  modal,
  rootLink,
  setResponse,
  setModal,
  setAlert,
  reference,
  card,
  user,
  cardName,
}) {
  const [packageName, setPackageName] = useState("");
  const [packageCode, setPackageCode] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [price, setPrice] = useState("");
  const [cableCode, setCableCode] = useState("");
  const [cable, setCable] = useState("");
  const [cableRef, setCableRef] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [content, setContent] = useState("");

  useEffect(() => {
    loadPackageDetails();
    loadSecretKey();
  }, []);

  //LOAD PACKAGE DETAILS
  const loadPackageDetails = () => {
    const formData = new FormData();
    formData.append("loadPackageDetails", reference);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/user/tv.php", true);
    http.onload = function () {
      setCableCode(JSON.parse(this.responseText).cable_code);
      setCableRef(JSON.parse(this.responseText).ref);
      setPrice(JSON.parse(this.responseText).price);
      setPackageCode(JSON.parse(this.responseText).code);
      setPackageName(JSON.parse(this.responseText).package);
      setCable(JSON.parse(this.responseText).cable);

      setTimeout(() => {
        setContent(JSON.parse(this.responseText).data_plan);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD PACKAGE DETAILS

  //LOAD SECRET KEY
  const loadSecretKey = () => {
    const formData = new FormData();
    formData.append("loadSecretKey", user);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/user/data.php", true);
    http.onload = function () {
      setSecretKey(JSON.parse(this.responseText).secret_key);

      setTimeout(() => {
        setContent(JSON.parse(this.responseText).data_plan);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD SECRET KEY

  //TV SUBSCRIPTION
  const continuePurchase = () => {
    setLoadingBtn(true);

    const formData = new FormData();
    formData.append("tv_package", reference);
    formData.append("card_number", card);
    formData.append("user_id", user);
    formData.append("package_code", packageCode);
    formData.append("cable_code", cableCode);
    formData.append("cardName", cardName);
    formData.append("api_key", secretKey);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "api/tv.php", true);
    http.onload = function () {
      setResponse(JSON.parse(this.responseText));
      setModal(false);
      setAlert(true);
      setLoadingBtn(false);
    };
    http.send(formData);
  };
  //END OF TV SUBSCRIPTION

  return (
    <Dialog open={modal} fullWidth>
      {packageName === "" ? (
        <Loading1 loading={isLoading} />
      ) : (
        <>
          <DialogContent
            sx={{
              wordWrap: "break-word",
            }}
          >
            <center>
              TV Package
              <h2>
                {cable} - {packageName}
              </h2>
              Amount
              <h2>&#8358;{price.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h2>
              Smart Card Number
              <h2>{card}</h2>
              Smart Card Name
              <h2>{cardName}</h2>
            </center>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Button
              color='error'
              variant='contained'
              onClick={() => {
                setModal(false);
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loadingBtn ? true : false}
              variant='contained'
              onClick={continuePurchase}
            >
              Continue
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export default ConfirmTV;
