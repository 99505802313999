import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

function ModalData({
  rootLink,
  setResponse,
  setAlert,
  username,
  modal,
  setModal,
  loadDetails,
  loadTransactions,
}) {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [networks, setNetworks] = useState([]);
  const [network, setNetwork] = useState("");
  const [networkRef, setNetworkRef] = useState("");
  const [networkCode, setNetworkCode] = useState("");
  const [plans, setPlans] = useState([]);
  const [plan, setPlan] = useState("");
  const [dataPlan, setDataPlan] = useState("");
  const [dataPrice, setDataPrice] = useState("");
  const [phone, setPhone] = useState("");
  const [reference, setReference] = useState("");
  const [dataCode, setDataCode] = useState("");
  const [amount, setAmount] = useState("");
  const [user, setUser] = useState("");
  const [balance, setBalance] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    loadNetworks();
    loadUserDetails();
  }, []);

  //LOAD USER DETAILS
  const loadUserDetails = () => {
    const formData = new FormData();
    formData.append("loadUserDetails", username);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/checker.php", true);
    http.onload = function () {
      setBalance(JSON.parse(this.responseText).balance);
      setSecretKey(JSON.parse(this.responseText).secret_key);
      setUser(JSON.parse(this.responseText).user_id);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD USER DETAILS

  //LOAD NETWORKS
  const loadNetworks = () => {
    const formData = new FormData();
    formData.append("loadNetWorks", "load");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/user/data.php", true);
    http.onload = function () {
      setNetworks(JSON.parse(this.responseText));

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD NETWORKS

  //LOAD DATA PLANS
  const loadDataPlans = (network) => {
    const formData = new FormData();
    formData.append("loadDataPlans", network);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/user/data.php", true);
    http.onload = function () {
      setPlans(JSON.parse(this.responseText));

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD DATA PLANS

  //LOAD DATA DETAILS
  const loadDataDetails = (ref) => {
    const formData = new FormData();
    formData.append("loadDataDetails", ref);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/user/data.php", true);
    http.onload = function () {
      setNetworkCode(JSON.parse(this.responseText).network_code);
      setNetworkRef(JSON.parse(this.responseText).ref);
      setDataPrice(JSON.parse(this.responseText).data_price);
      setDataCode(JSON.parse(this.responseText).data_code);
      setDataPlan(JSON.parse(this.responseText).data_plan);
      setNetwork(JSON.parse(this.responseText).network);
    };
    http.send(formData);
  };
  //END OF LOAD DATA DETAILS

  //LOAD AMOUNT
  const loadAmount = (ref) => {
    const formData = new FormData();
    formData.append("loadDataDetails", ref);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/user/data.php", true);
    http.onload = function () {
      setAmount(JSON.parse(this.responseText).data_price);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD AMOUNT

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (network === "") {
      const msg = "Network is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (plan === "") {
      const msg = "Data plan is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (amount === "") {
      const msg = "Amount is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (amount > balance) {
      const msg = "Insufficient fund";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (phone === "") {
      const msg = "Phone number is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (isNaN(phone)) {
      const msg = "Phone number is invalid";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (
      network &&
      plan &&
      amount &&
      amount <= balance &&
      phone &&
      !isNaN(phone)
    ) {
      setLoadingBtn(true);

      const formData = new FormData();
      formData.append("data_plan", plan);
      formData.append("mobile_number", phone);
      formData.append("user_id", user);
      formData.append("data_code", dataCode);
      formData.append("network_code", networkCode);
      formData.append("api_key", secretKey);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "api/data.php", true);
      http.onload = function () {
        const jsonData = JSON.parse(this.responseText);

        setResponse(JSON.parse(this.responseText));
        setModal(false);
        setAlert(true);

        loadDetails();
        loadTransactions();
        setLoadingBtn(false);
      };
      http.send(formData);
    }
  };
  //END OF FORM SUBMIT

  return (
    <Dialog open={modal} fullWidth>
      <DialogTitle sx={{ textAlign: "center" }}>Buy Data</DialogTitle>

      <>
        <DialogContent>
          <label htmlFor='network'>Network</label>
          <select
            onChange={(e) => {
              setNetwork(e.target.value);
              loadDataPlans(e.target.value);
            }}
            name='network'
            value={network}
          >
            <option value=''>Select Network</option>
            {networks.map((item) => {
              return (
                <React.Fragment key={item.ref}>
                  <option disabled={item.disabled === "true" ? true : false}>
                    {item.network}
                  </option>
                </React.Fragment>
              );
            })}
          </select>

          <label htmlFor='data'>Data Plan</label>
          <select
            onChange={(e) => {
              setPlan(e.target.value);
              loadDataDetails(e.target.value);
              loadAmount(e.target.value);
            }}
            name='data'
            value={plan}
          >
            <option value=''>Data Plan</option>
            {plans.map((item) => {
              return (
                <React.Fragment key={item.ref}>
                  <option
                    disabled={item.disabled === "true" ? true : false}
                    value={item.ref}
                  >
                    {item.data_plan}
                  </option>
                </React.Fragment>
              );
            })}
          </select>

          <label htmlFor='amount'>Amount</label>
          <input
            type='number'
            value={amount}
            name='amount'
            placeholder='Amount'
            readOnly
          />

          <label htmlFor='phone'>Phone Number</label>
          <input
            type='number'
            onChange={(e) => setPhone(e.target.value)}
            placeholder='Phone Number'
            name='phone'
            value={phone}
          />

          <DialogActions
            sx={{
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Button
              color='error'
              variant='contained'
              onClick={() => {
                setModal(false);
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loadingBtn ? true : false}
              onClick={formSubmit}
              variant='contained'
            >
              Buy
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </>
    </Dialog>
  );
}

export default ModalData;
