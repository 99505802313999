import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Accessibility,
  ArrowBack,
  Computer,
  Lock,
  Paid,
  Security,
  Smartphone,
  Speed,
  Tv,
  Wifi,
} from "@mui/icons-material";
import { Fade } from "react-awesome-reveal";

function GeneralIndex({ rootLink, setResponse, setAlert }) {
  const [animateService, setAnimateService] = useState(false);
  const navigate = useNavigate();

  return (
    <div>
      <section className='homesection'>
        <img src={rootLink + "resources/images/src/banner.jpg"} />

        <h1>
          Welcome to <span>ZICOPAWA</span> <br /> Data Hub
        </h1>
        <div className='welcomeButtons'>
          <button
            onClick={() => {
              navigate("/register");
            }}
            className='started'
          >
            Get Started
          </button>
          <button
            onClick={() => {
              navigate("/login");
            }}
            className='login'
          >
            Log In
          </button>
        </div>

        <div className='homeArrow'>
          <ArrowBack
            onClick={() => {
              setAnimateService(true);
            }}
          />
        </div>
      </section>

      <section
        className={animateService ? "servicesSection show" : "servicesSection"}
      >
        <h2>Our Services</h2>

        <div className='servicesContainer'>
          <div className='card'>
            <img src={rootLink + "resources/images/src/data.jpg"} />
            <div className='title'>
              <Wifi />
              <p>Data Subscription</p>
            </div>
            <Link to={"/register"}>Buy Now</Link>
          </div>
          <div className='card'>
            <img src={rootLink + "resources/images/src/airtime.jpg"} />
            <div className='title'>
              <Smartphone />
              <p>Airtime Top Up</p>
            </div>
            <Link to={"/register"}>Buy Now</Link>
          </div>
          <div className='card'>
            <img src={rootLink + "resources/images/src/tv.jpg"} />
            <div className='title'>
              <Tv />
              <p>TV Subscription</p>
            </div>
            <Link to={"/register"}>Buy Now</Link>
          </div>
          <div className='card'>
            <img src={rootLink + "resources/images/src/bet.jpg"} />
            <div className='title'>
              <Wifi />
              <p>Bet Funding</p>
            </div>
            <Link to={"/register"}>Buy Now</Link>
          </div>
        </div>
      </section>

      <section className='specialSection'>
        <h2>What makes us special</h2>

        <div className='specialContainer'>
          <Fade cascade damping={0.1} triggerOnce>
            <div className='card afford'>
              <Paid />
              <div className='text'>
                <h3>Affordability</h3>
                <p>
                  We provide one of the best prices on the market because we
                  want to keep you connected.
                </p>
              </div>
            </div>

            <div className='card speed'>
              <Speed />
              <div className='text'>
                <h3>Speed</h3>
                <p>
                  One of the fastest services in this category is ours. We offer
                  seamless services at light-speed.
                </p>
              </div>
            </div>
            <div className='card interface'>
              <Computer />
              <div className='text'>
                <h3>Friendly Interface</h3>
                <p>
                  Utilize our platform and get an incredible experience with our
                  lovely interface.
                </p>
              </div>
            </div>
            <div className='card secure'>
              <Security />
              <div className='text'>
                <h3>Security</h3>
                <p>
                  Everything you do is safe, and we always keep your digital
                  wallet protected.
                </p>
              </div>
            </div>
          </Fade>
        </div>
      </section>
    </div>
  );
}

export default GeneralIndex;
