import React, { useState, useEffect } from "react";
import Nav from "../../../components/nav/Nav";
import Loading1 from "../../../components/Loading/Loading1";
import DeleteNetwork from "../../../components/modal/DataManager/DeleteNetwork";
import { useParams, useNavigate } from "react-router-dom";
import AddDataPlan from "../../../components/modal/DataPlan/AddDataPlan";
import EditDataPlan from "../../../components/modal/DataPlan/EditDataPlan";
import DeleteDataPlan from "../../../components/modal/DataPlan/DeleteDataPlan";
import { Delete, Edit, PlayArrow, Pause, ArrowBack } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import DisablePlan from "../../../components/modal/DataPlan/Disable";

function DataPlans({ rootLink, response, setResponse, alert, setAlert }) {
  const { network } = useParams();
  const [addDataPlan, setAddDataPlan] = useState(false);
  const [content, setContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [reference, setReference] = useState("");
  const [editDataPlan, setEditDataPlan] = useState(false);
  const [deleteDataPlan, setDeleteDataPlan] = useState(false);
  const [disablePlan, setDisablePlan] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadDataPlans();
  }, []);

  //LOAD DATA PLANS
  const loadDataPlans = () => {
    const formData = new FormData();
    formData.append("loadDataPlans", network);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/dataPlan.php", true);
    http.onload = function () {
      setContent(JSON.parse(this.responseText));

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD DATA PLANS

  //EDIT MODAL
  const openEditModal = (ref) => {
    setReference(ref);
    setEditDataPlan(true);
  };

  //END OF EDIT MODAL

  //DISABLE MODAL
  const openDisableModal = (ref) => {
    setReference(ref);
    setDisablePlan(true);
  };

  //END OF DISABLE MODAL

  //DELETE MODAL
  const openDeleteModal = (ref) => {
    setReference(ref);
    setDeleteDataPlan(true);
  };

  //END OF DELETE MODAL

  return (
    <>
      <div className='firstWrapper'>
        <div className='backButton'>
          <IconButton onClick={() => navigate("../")}>
            <ArrowBack />
          </IconButton>
          <h1 className='firstHeader'>{network} Data Plans</h1>
        </div>

        <div className='firstContent'>
          <div onClick={() => setAddDataPlan(true)} className='addButton'>
            New Data Plan
          </div>

          {content.length === "" ? (
            <h2 className='center'>No data plan is available</h2>
          ) : (
            <>
              <div>
                {content.map((item) => {
                  return (
                    <div
                      key={item.ref}
                      className={
                        item.disabled === "true"
                          ? "listItem disabled"
                          : "listItem"
                      }
                    >
                      <div className='left'>
                        <div className='bigText'>{item.data_plan}</div>
                        <div className='smalltext'>Code: {item.data_code}</div>
                        <div className='smallText'>
                          Price: &#8358;{item.data_price}
                        </div>
                      </div>
                      <div className='right flex'>
                        <IconButton onClick={() => openEditModal(item.ref)}>
                          <Edit color='primary' />
                        </IconButton>
                        <IconButton onClick={() => openDisableModal(item.ref)}>
                          {item.disabled === "false" ? (
                            <Pause color='primary' />
                          ) : (
                            <PlayArrow />
                          )}
                        </IconButton>
                        <IconButton onClick={() => openDeleteModal(item.ref)}>
                          <Delete color='error' />
                        </IconButton>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}

          {addDataPlan ? (
            <AddDataPlan
              modal={addDataPlan}
              setModal={setAddDataPlan}
              setResponse={setResponse}
              setAlert={setAlert}
              rootLink={rootLink}
              reload={loadDataPlans}
              network={network}
            />
          ) : null}

          {editDataPlan ? (
            <EditDataPlan
              reference={reference}
              modal={editDataPlan}
              setModal={setEditDataPlan}
              setResponse={setResponse}
              setAlert={setAlert}
              rootLink={rootLink}
              reload={loadDataPlans}
              network={network}
            />
          ) : null}

          {disablePlan ? (
            <DisablePlan
              reference={reference}
              modal={disablePlan}
              setModal={setDisablePlan}
              setResponse={setResponse}
              setAlert={setAlert}
              rootLink={rootLink}
              reload={loadDataPlans}
              network={network}
            />
          ) : null}

          {deleteDataPlan ? (
            <DeleteDataPlan
              reference={reference}
              modal={deleteDataPlan}
              setModal={setDeleteDataPlan}
              setResponse={setResponse}
              setAlert={setAlert}
              rootLink={rootLink}
              reload={loadDataPlans}
              network={network}
            />
          ) : null}

          <Loading1 loading={isLoading} />
        </div>
      </div>
    </>
  );
}

export default DataPlans;
