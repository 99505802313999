import React, { useState, useEffect } from "react";
import AddNetwork from "../../../components/modal/DataManager/AddNetwork";
import Loading1 from "../../../components/Loading/Loading1";
import EditNetwork from "../../../components/modal/DataManager/EditNetwork";
import DeleteNetwork from "../../../components/modal/DataManager/DeleteNetwork";
import DisableNetwork from "../../../components/modal/DataManager/Disable";
import { Link, useNavigate } from "react-router-dom";
import {
  Delete,
  Edit,
  Pause,
  PlayArrow,
  Stop,
  ArrowBack,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";

function DataManagerIndex({
  rootLink,
  response,
  setResponse,
  alert,
  setAlert,
}) {
  const [addNetwork, setAddNetwork] = useState(false);
  const [content, setContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [reference, setReference] = useState("");
  const [editNetwork, setEditNetwork] = useState(false);
  const [deleteNetwork, setDeleteNetwork] = useState(false);
  const [disableNetwork, setDisableNetwork] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadNetworks();
  }, []);

  //LOAD NETWORKS
  const loadNetworks = () => {
    const formData = new FormData();
    formData.append("loadNetWorks", "load");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/dataManager.php", true);
    http.onload = function () {
      setContent(JSON.parse(this.responseText));

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD NETWORKS

  //EDIT MODAL
  const openEditModal = (ref) => {
    setReference(ref);
    setEditNetwork(true);
  };

  //END OF EDIT MODAL

  //DISABLE MODAL
  const openDisableModal = (ref) => {
    setReference(ref);
    setDisableNetwork(true);
  };

  //END OF DISABLE MODAL

  //DELETE MODAL
  const openDeleteModal = (ref) => {
    setReference(ref);
    setDeleteNetwork(true);
  };

  //END OF DELETE MODAL

  return (
    <>
      <div className='firstWrapper'>
        <div className='backButton'>
          <IconButton onClick={() => navigate("../../")}>
            <ArrowBack />
          </IconButton>
          <h1 className='firstHeader'>Data Manager</h1>
        </div>
        <div className='firstContent'>
          <div onClick={() => setAddNetwork(true)} className='addButton'>
            New Network
          </div>

          {content.length === "" ? (
            <h2 className='center'>No network is available</h2>
          ) : (
            <>
              {content.map((item) => {
                return (
                  <div
                    key={item.ref}
                    className={
                      item.disabled === "true"
                        ? "listItem disabled"
                        : "listItem"
                    }
                  >
                    <div className='left'>
                      <Link to={"../" + item.network}>
                        <div className='bigText'>{item.network}</div>
                        <div class='smallText'>Code: {item.network_code}</div>
                      </Link>
                    </div>
                    <div className='right flex'>
                      <IconButton onClick={() => openEditModal(item.ref)}>
                        <Edit color='primary' />
                      </IconButton>
                      <IconButton onClick={() => openDisableModal(item.ref)}>
                        {item.disabled === "false" ? (
                          <Pause color='primary' />
                        ) : (
                          <PlayArrow />
                        )}
                      </IconButton>
                      <IconButton onClick={() => openDeleteModal(item.ref)}>
                        <Delete color='error' />
                      </IconButton>
                    </div>
                  </div>
                );
              })}
            </>
          )}

          {addNetwork ? (
            <AddNetwork
              modal={addNetwork}
              setModal={setAddNetwork}
              setResponse={setResponse}
              setAlert={setAlert}
              rootLink={rootLink}
              reload={loadNetworks}
            />
          ) : null}

          {editNetwork ? (
            <EditNetwork
              reference={reference}
              modal={editNetwork}
              setModal={setEditNetwork}
              setResponse={setResponse}
              setAlert={setAlert}
              rootLink={rootLink}
              reload={loadNetworks}
            />
          ) : null}

          {disableNetwork ? (
            <DisableNetwork
              reference={reference}
              modal={disableNetwork}
              setModal={setDisableNetwork}
              setResponse={setResponse}
              setAlert={setAlert}
              rootLink={rootLink}
              reload={loadNetworks}
            />
          ) : null}

          {deleteNetwork ? (
            <DeleteNetwork
              reference={reference}
              modal={deleteNetwork}
              setModal={setDeleteNetwork}
              setResponse={setResponse}
              setAlert={setAlert}
              rootLink={rootLink}
              reload={loadNetworks}
            />
          ) : null}

          <Loading1 loading={isLoading} />
        </div>
      </div>
    </>
  );
}

export default DataManagerIndex;
