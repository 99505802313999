import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

function AdjustBalance({
  rootLink,
  setResponse,
  setAlert,
  username,
  modal,
  setModal,
  loadDetails,
  loadTransactions,
}) {
  const [amount, setAmount] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [action, setAction] = useState("");
  const [balance, setBalance] = useState("");

  useEffect(() => {
    loadContent();
  }, []);

  //LOAD CONTENT
  const loadContent = () => {
    const formData = new FormData();
    formData.append("loadDetails", username);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/users.php", true);
    http.onload = function () {
      setBalance(JSON.parse(this.response).balance);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //LOAD CONTENT

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (action === "") {
      const msg = "Select Add or Remove";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (amount === "") {
      const msg = "Amount is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (isNaN(amount)) {
      const msg = "Amount must be a number";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (action && amount && !isNaN(amount)) {
      setLoadingBtn(true);

      const formData = new FormData();

      formData.append("AdjustBalance", username);
      formData.append("amount", amount);
      formData.append("action", action);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/admin/users.php", true);
      http.onload = function () {
        const jsonData = JSON.parse(this.responseText);

        setResponse(jsonData);
        loadDetails();
        setModal(false);
        setAlert(true);
        setLoadingBtn(false);
        loadTransactions();
      };
      http.send(formData);
      //END OF AJAX
    }
  };
  //END OF FORM SUBMIT

  return (
    <Dialog open={modal} fullWidth>
      <DialogTitle sx={{ textAlign: "center" }}>Adjust Balance</DialogTitle>

      <>
        <DialogContent>
          <div>Current Balance: &#8358;{balance}</div>

          <input
            onChange={(e) => setAction(e.target.value)}
            type='radio'
            name='action'
            id='add'
            value={"Add"}
            style={{ width: "fit-content", display: "inline-block" }}
          />
          <label htmlFor='add'>Add</label>
          <br />
          <input
            onChange={(e) => setAction(e.target.value)}
            type='radio'
            name='action'
            id='remove'
            value={"Remove"}
            style={{ width: "fit-content", display: "inline-block" }}
          />
          <label htmlFor='remove'>Remove</label>
          <br />
          {action === "" ? null : <label htmlFor='amount'>Amount</label>}
          <input
            type={action === "" ? "hidden" : "number"}
            onChange={(e) => setAmount(e.target.value)}
            name='amount'
            placeholder='Amount'
          />
          <DialogActions
            sx={{
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Button
              color='error'
              variant='contained'
              onClick={() => {
                setModal(false);
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loadingBtn ? true : false}
              onClick={formSubmit}
              variant='contained'
            >
              Save
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </>
    </Dialog>
  );
}

export default AdjustBalance;
