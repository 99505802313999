import { useState, createContext, useLayoutEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Nav from "./components/nav/Nav";
import AdminRouter from "./pages/admin/router";
import GeneralRouter from "./pages/general/router";
import UserRouter from "./pages/user/router";
import Register from "./components/Login/Register";
import "./styles/overall/login.css";
import "./styles/overall/layout.css";
import "./styles/overall/loader.css";
import "./styles/overall/preloader.css";
import "./styles/overall/404.css";
import "./styles/overall/pagination.css";
import "./styles/overall/backButton.css";
import "./styles/overall/footer.css";
export const RootLink = createContext(null);

function App() {
  const [alert, setAlert] = useState(false);
  const [response, setResponse] = useState({
    status: "",
    response: "",
  });

  //const rootLink = "http://localhost/react/datasite/public/";
  const rootLink = "https://zicopawa.com.ng/";

  return (
    <RootLink.Provider value={rootLink}>
      <Routes>
        <Route path='/*' element={<GeneralRouter rootLink={rootLink} />} />
        <Route path='/admin/*' element={<AdminRouter rootLink={rootLink} />} />
        <Route path='/user/*' element={<UserRouter rootLink={rootLink} />} />
        <Route path='/register' element={<Register rootLink={rootLink} />} />
      </Routes>
    </RootLink.Provider>
  );
}

export default App;
