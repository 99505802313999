import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { ArrowBack } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ConfirmTransfer from "../../../components/modal/User/Transfer/Confirm";
import { Helmet } from "react-helmet";
import SiteHelmet from "../../../components/Helmet/Helmet";

function TransferIndex({ rootLink, response, setResponse, alert, setAlert }) {
  const [companies, setCompanies] = useState([]);
  const [username, setUsername] = useState("");
  const [networkCode, setCompanyCode] = useState("");
  const [receiver, setReceiver] = useState("");
  const [receiverName, setReceiverName] = useState("");
  const [amount, setAmount] = useState("");
  const [smallLoader, setSmallLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const user = Cookies.get("id");
  const navigate = useNavigate();

  //LOAD CUSTOMER DETAILS
  const loadUserDetails = () => {
    if (receiver.length >= 2 && receiverName === "") {
      setReceiverName("");

      setSmallLoader(true);
      const formData = new FormData();
      formData.append("loadUserDetails", receiver);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/user/transfer.php", true);
      http.onload = function () {
        const jsonData = JSON.parse(this.responseText);

        if (jsonData === null) {
          const msg = "User does not exist";

          const arr = { status: "error", response: msg };

          setResponse(arr);
          setAlert(true);
        } else {
          setReceiverName(jsonData.first_name + " " + jsonData.last_name);
          setUsername(jsonData.username);
        }

        setTimeout(() => {
          setSmallLoader(false);
        }, 1000);
      };
      http.send(formData);
    }
  };
  //END OF LOAD CUSTOMER DETAILS

  //HANDLE CUSTOMER NAME
  const handleCustomerName = (input) => {
    setReceiverName("");

    if (input.length >= 2) {
      setSmallLoader(true);
      const formData = new FormData();
      formData.append("loadUserDetails", input);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/user/transfer.php", true);
      http.onload = function () {
        const jsonData = JSON.parse(this.responseText);

        setReceiverName(jsonData.first_name + " " + jsonData.last_name);
        setUsername(jsonData.username);

        setTimeout(() => {
          setSmallLoader(false);
          setUsername(jsonData.username);
        }, 1000);
      };
      http.send(formData);
    }
  };
  //END OF HANDLE CUSTOMER NAME

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (isNaN(amount)) {
      const msg = "Amount is invalid";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (amount === "") {
      const msg = "Amount is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (amount < 50) {
      const msg = "Minimum amount is 50";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (receiver === "") {
      const msg = "Username, email or phone number is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (
      receiverName &&
      username &&
      !isNaN(amount) &&
      amount &&
      amount >= 50 &&
      receiver
    ) {
      setModal(true);
    }
  };
  //END OF FORM SUBMIT

  return (
    <>
      <SiteHelmet page={"Fund Transfer"} />

      <div className='firstWrapper'>
        <div className='backButton'>
          <IconButton onClick={() => navigate("../../")}>
            <ArrowBack />
          </IconButton>
          <h1 className='firstHeader'>Transfer Fund</h1>
        </div>

        <div className='firstContent padding10'>
          <form onSubmit={formSubmit}>
            <label htmlFor='amount'>Amount</label>
            <input
              type='number'
              onChange={(e) => setAmount(e.target.value)}
              placeholder='Amount'
              name='amount'
            />

            <label htmlFor='receiver'>Username / Phone Number / Email</label>
            <input
              type='text'
              onChange={(e) => {
                setReceiver(e.target.value);
                handleCustomerName(e.target.value);
              }}
              onBlur={loadUserDetails}
              placeholder='Username / Phone Number / Email'
              name='receiver'
            />

            {!smallLoader ? (
              <div
                className={
                  receiverName === "" ? "cardName hide" : "cableName show"
                }
              >
                {receiverName}
              </div>
            ) : (
              <div className='smallLoader'></div>
            )}

            <br />

            <button
              className={
                username === "" ||
                receiver === "" ||
                amount === "" ||
                amount < 50 ||
                isNaN(amount) ||
                receiverName === ""
                  ? "width100 disabled"
                  : "width100"
              }
              disabled={
                username === "" ||
                receiver === "" ||
                amount === "" ||
                amount < 50 ||
                isNaN(amount) ||
                receiverName === ""
                  ? true
                  : false
              }
              onClick={formSubmit}
            >
              Transfer
            </button>
          </form>
        </div>

        {modal ? (
          <ConfirmTransfer
            username={username}
            amount={amount}
            receiver={receiver}
            receiverName={receiverName}
            modal={modal}
            setModal={setModal}
            setResponse={setResponse}
            setAlert={setAlert}
            rootLink={rootLink}
            user={user}
          />
        ) : null}
      </div>
    </>
  );
}

export default TransferIndex;
