import React from "react";
import { Routes, Route } from "react-router-dom";
import EmailIndex from ".";
import ViewEmails from "./view";

function EmailRouter({ rootLink, response, setResponse, alert, setAlert }) {
  return (
    <Routes>
      <Route
        path={"/"}
        element={
          <EmailIndex
            rootLink={rootLink}
            response={response}
            setResponse={setResponse}
            alert={alert}
            setAlert={setAlert}
          />
        }
      />
      <Route
        path={"/:id"}
        element={
          <ViewEmails
            rootLink={rootLink}
            response={response}
            setResponse={setResponse}
            alert={alert}
            setAlert={setAlert}
          />
        }
      />
    </Routes>
  );
}

export default EmailRouter;
