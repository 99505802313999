import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  Phone,
  Person,
  Email,
  CalendarMonth,
  Reply,
  ArrowBack,
} from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import HTMLReactParser from "html-react-parser";

function ViewEmails({ rootLink, response, setResponse, alert, setAlert }) {
  const [content, setContent] = useState([]);
  const [replies, setReplies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    loadMessage();
  }, []);

  //LOAD MESSAGE DETAILS
  const loadMessage = () => {
    const formData = new FormData();
    formData.append("loadEmail", id);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/email.php", true);
    http.onload = function () {
      setContent(JSON.parse(this.responseText));

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD MESSAGE DETAILS

  return (
    <>
      <div className='firstWrapper'>
        <div className='backButton'>
          <IconButton onClick={() => navigate("../")}>
            <ArrowBack />
          </IconButton>
          <h1 className='firstHeader'>View Email</h1>
        </div>

        <div className='firstContent'>
          <div
            className='subject'
            style={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            {content.subject}
          </div>
          <div className='message'>
            {content.message ? HTMLReactParser(content.message) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewEmails;
