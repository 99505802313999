import React from "react";
import AirtimeIndex from ".";
import { Routes, Route } from "react-router-dom";

function AirtimeRouter({ rootLink, response, setResponse, alert, setAlert }) {
  return (
    <Routes>
      <Route
        path='/'
        element={
          <AirtimeIndex
            rootLink={rootLink}
            response={response}
            setResponse={setResponse}
            alert={alert}
            setAlert={setAlert}
          />
        }
      />
    </Routes>
  );
}

export default AirtimeRouter;
