import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import GeneralIndex from ".";
import ContactRouter from "./contact/router";
import Nav from "../../components/nav/Nav";
import ResetPassword from "./ResetPassword";
import Login from "./Login";
import TermsIndex from "./terms";
import PoliciesIndex from "./policies";
import PageNotFound from "./404";
import Footer from "../../components/Footer/Footer";
import "../../styles/general/nav.css";
import "../../styles/general/color.css";
import "../../styles/general/contact.css";
import "../../styles/general/body.css";
import "../../styles/general/index.css";
import { Helmet } from "react-helmet";
import SiteHelmet from "../../components/Helmet/Helmet";

function GeneralRouter({ rootLink }) {
  const [alert, setAlert] = useState(false);
  const [response, setResponse] = useState({
    status: "",
    response: "",
  });
  const { pathname } = useLocation();

  useEffect(
    function () {
      window.scrollTo(0, 0);
    },
    [pathname]
  );

  return (
    <>
      {pathname !== "/login" ? (
        <Nav
          response={response}
          setResponse={setResponse}
          alert={alert}
          setAlert={setAlert}
        />
      ) : null}

      <SiteHelmet />

      <div class='body'>
        <Routes>
          <Route
            path='/'
            element={
              <GeneralIndex
                rootLink={rootLink}
                response={response}
                setResponse={setResponse}
                alert={alert}
                setAlert={setAlert}
              />
            }
          />
          <Route
            path='/contact'
            element={
              <ContactRouter
                rootLink={rootLink}
                response={response}
                setResponse={setResponse}
                alert={alert}
                setAlert={setAlert}
              />
            }
          />

          <Route
            path='/login'
            element={
              <Login
                rootLink={rootLink}
                response={response}
                setResponse={setResponse}
                alert={alert}
                setAlert={setAlert}
              />
            }
          />

          <Route
            path='/terms'
            element={
              <TermsIndex
                rootLink={rootLink}
                response={response}
                setResponse={setResponse}
                alert={alert}
                setAlert={setAlert}
              />
            }
          />

          <Route
            path='/policy'
            element={
              <PoliciesIndex
                rootLink={rootLink}
                response={response}
                setResponse={setResponse}
                alert={alert}
                setAlert={setAlert}
              />
            }
          />
          <Route
            path='/reset/:token'
            element={
              <ResetPassword
                rootLink={rootLink}
                response={response}
                setResponse={setResponse}
                alert={alert}
                setAlert={setAlert}
              />
            }
          />

          <Route
            path='*'
            element={
              <PageNotFound
                rootLink={rootLink}
                response={response}
                setResponse={setResponse}
                alert={alert}
                setAlert={setAlert}
              />
            }
          />
        </Routes>
      </div>
      {pathname !== "/login" && (
        <Footer rootLink={rootLink} margin={"general"} />
      )}
    </>
  );
}

export default GeneralRouter;
