import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import SearchUser from "../../../components/modal/Users/Search";
import {
  AccountBalance,
  Wifi,
  Smartphone,
  Password,
  Lock,
  LockOpen,
  Person,
  Tv,
  Lightbulb,
  Edit,
  ArrowBack,
  MoveDown,
  Receipt,
} from "@mui/icons-material";
import { IconButton, Stack, Button } from "@mui/material";
import AdjustBalance from "../../../components/modal/Users/AdjustBalance";
import ResetPassword from "../../../components/modal/Users/ResetPassword";
import BanUser from "../../../components/modal/Users/BanUser";
import UnbanUser from "../../../components/modal/Users/UnbanUser";
import ModalData from "../../../components/modal/QuickActions/Data";
import ModalAirtime from "../../../components/modal/QuickActions/Airtime";
import EditDetails from "../../../components/modal/Users/EditDetails";
import ReactTimeAgo from "react-time-ago";
import ModalTV from "../../../components/modal/QuickActions/TV";
import ModalBetting from "../../../components/modal/QuickActions/Betting";

function ViewUser({ rootLink, response, setResponse, alert, setAlert }) {
  const [content, setContent] = useState([]);
  const [details, setDetails] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { username } = useParams();
  const [balanceModal, setBalanceModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [banModal, setBanModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [unbanModal, setUnbanModal] = useState(false);
  const [dataModal, setDataModal] = useState(false);
  const [tvModal, setTvModal] = useState(false);
  const [betModal, setBetModal] = useState(false);
  const [airtimeModal, setAirtimeModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadDetails();
    loadTransactions();
  }, [username]);

  //LOAD USER
  const loadDetails = () => {
    const formData = new FormData();
    formData.append("loadDetails", username);
    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/users.php", true);
    http.onload = function () {
      setDetails(JSON.parse(this.responseText));

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD USER

  //LOAD TRANSACTIONS
  const loadTransactions = () => {
    const formData = new FormData();
    formData.append("last5transactions", username);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/users.php", true);
    http.onload = function () {
      var data = JSON.parse(this.responseText);
      var container = [...data];
      setTransactions(container);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD TRANSACTIONS

  return (
    <>
      <SearchUser
        rootLink={rootLink}
        setContent={setContent}
        content={content}
      />

      <IconButton onClick={() => navigate("../")}>
        <ArrowBack />
      </IconButton>

      <div className='userContainer'>
        {!details ? (
          <center>
            <h2>Invalid Username</h2>
          </center>
        ) : (
          <>
            <div className='person'>
              <Person style={{ fontSize: "150px" }} />
            </div>

            <div className='infoCard'>
              <div className='balance'>&#8358;{details.balance}</div>
              <div className='name'>
                {details.first_name + " " + details.last_name}
              </div>
              <div className='item'>{details.username}</div>
              <div className='item'>{details.user_id}</div>
              <div className='item'>{details.email}</div>
              <div className='item'>{details.phone}</div>
            </div>
            <div className='userAction'>
              <Button
                onClick={() => setBalanceModal(true)}
                style={{ color: "black" }}
                size={"small"}
              >
                <AccountBalance /> Adjust Balance
              </Button>
              <Button
                onClick={() => setEditModal(true)}
                style={{ color: "black" }}
              >
                <Edit /> Edit Details
              </Button>
              <Button
                onClick={() => setPasswordModal(true)}
                style={{ color: "black" }}
              >
                <Password /> Change Password
              </Button>
              {details.ban === "no" ? (
                <Button
                  onClick={() => setBanModal(true)}
                  style={{ color: "black" }}
                >
                  <Lock /> Ban User
                </Button>
              ) : (
                <Button
                  onClick={() => setUnbanModal(true)}
                  style={{ color: "black" }}
                >
                  <LockOpen /> Unban User
                </Button>
              )}
            </div>

            <br />

            <center>
              <h2 style={{ fontSize: "30px" }}>Quick Actions</h2>
            </center>

            <div className='quickAction'>
              <div onClick={() => setAirtimeModal(true)} className='action'>
                <Smartphone />
                <p>Airtime</p>
              </div>
              <div onClick={() => setDataModal(true)} className='action'>
                <Wifi />
                <p>Data</p>
              </div>
              <div onClick={() => setTvModal(true)} className='action'>
                <Tv />
                <p>TV Subscription</p>
              </div>
              <div onClick={() => setBetModal(true)} className='action'>
                <Receipt />
                <p>Bet Funding</p>
              </div>
            </div>

            {transactions.length > 0 ? (
              <div style={{ marginTop: "30px" }}>
                <center>
                  <h2 style={{ fontSize: "30px" }}>Transactions</h2>
                </center>
                {transactions.map((item) => {
                  return (
                    <Link
                      to={"../../../admin/transactions/" + item.ref}
                      key={item.ref}
                      className='dashboardTransactions'
                    >
                      <div className='title'>
                        <div className='icon'>
                          {item.transaction === "Deposit" ||
                          item.transaction === "Debit" ? (
                            <AccountBalance />
                          ) : item.transaction === "Transfer" ? (
                            <MoveDown />
                          ) : item.transaction === "TV Subscription" ? (
                            <Tv />
                          ) : item.transaction === "Data" ? (
                            <Wifi />
                          ) : item.transaction === "Bet Funding" ? (
                            <Receipt />
                          ) : item.transaction === "Airtime" ? (
                            <Smartphone />
                          ) : null}
                        </div>

                        <div className='nameWraper'>
                          <div className='name'>{item.transaction}</div>
                          <div
                            className={
                              item.status === "Successful" ||
                              item.status === "Completed"
                                ? "status success"
                                : item.status === "Failed" ||
                                  item.status === "Cancelled"
                                ? "status failed"
                                : "status pending"
                            }
                          >
                            {item.status}
                          </div>
                        </div>
                      </div>

                      <div className='details'>
                        <div className='amount'>&#8358;{item.amount}</div>
                        <div className='date'>
                          <ReactTimeAgo date={item.started} />
                        </div>
                      </div>
                    </Link>
                  );
                })}

                <div className='viewMore'>
                  <Link to={"./transactions"}>View More</Link>
                </div>
                <br />
              </div>
            ) : null}
          </>
        )}
      </div>

      <ModalAirtime
        rootLink={rootLink}
        setResponse={setResponse}
        setAlert={setAlert}
        username={username}
        loadDetails={loadDetails}
        modal={airtimeModal}
        setModal={setAirtimeModal}
        loadTransactions={loadTransactions}
      />
      <ModalData
        rootLink={rootLink}
        setResponse={setResponse}
        setAlert={setAlert}
        username={username}
        loadDetails={loadDetails}
        modal={dataModal}
        setModal={setDataModal}
        loadTransactions={loadTransactions}
      />

      <ModalTV
        rootLink={rootLink}
        setResponse={setResponse}
        setAlert={setAlert}
        username={username}
        loadDetails={loadDetails}
        modal={tvModal}
        setModal={setTvModal}
        loadTransactions={loadTransactions}
      />

      <ModalBetting
        rootLink={rootLink}
        setResponse={setResponse}
        setAlert={setAlert}
        username={username}
        loadDetails={loadDetails}
        modal={betModal}
        setModal={setBetModal}
        loadTransactions={loadTransactions}
      />

      <AdjustBalance
        rootLink={rootLink}
        setResponse={setResponse}
        setAlert={setAlert}
        username={username}
        loadDetails={loadDetails}
        modal={balanceModal}
        setModal={setBalanceModal}
        loadTransactions={loadTransactions}
      />
      <EditDetails
        rootLink={rootLink}
        setResponse={setResponse}
        setAlert={setAlert}
        username={username}
        modal={editModal}
        setModal={setEditModal}
        loadDetails={loadDetails}
        loadTransactions={loadTransactions}
      />

      <ResetPassword
        rootLink={rootLink}
        setResponse={setResponse}
        setAlert={setAlert}
        username={username}
        loadDetails={loadDetails}
        modal={passwordModal}
        setModal={setPasswordModal}
      />
      <BanUser
        rootLink={rootLink}
        setResponse={setResponse}
        setAlert={setAlert}
        username={username}
        loadDetails={loadDetails}
        modal={banModal}
        setModal={setBanModal}
      />
      <UnbanUser
        rootLink={rootLink}
        setResponse={setResponse}
        setAlert={setAlert}
        username={username}
        loadDetails={loadDetails}
        modal={unbanModal}
        setModal={setUnbanModal}
      />
    </>
  );
}

export default ViewUser;
