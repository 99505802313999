import React, { useState, useEffect } from "react";
import Loading1 from "../../../components/Loading/Loading1";
import { Link, useNavigate } from "react-router-dom";
import SearchTransactions from "../../../components/modal/Transactions/Search";
import ReactPaginate from "react-paginate";
import { IconButton } from "@mui/material";
import {
  AccountBalance,
  Wifi,
  Smartphone,
  Password,
  Lock,
  LockOpen,
  Person,
  Tv,
  Lightbulb,
  Subscript,
  ArrowBack,
  MoveDown,
  Receipt,
} from "@mui/icons-material";
import ReactTimeAgo from "react-time-ago";

function TransactionsIndex({
  rootLink,
  response,
  setResponse,
  alert,
  setAlert,
}) {
  const [content, setContent] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    lastStatistics();
    loadTransactions();
  }, []);

  //LOAD STATISTICS
  const lastStatistics = () => {
    const formData = new FormData();
    formData.append("loadStatistics", "load");
    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/transactions.php", true);
    http.onload = function () {
      setDetails(JSON.parse(this.responseText));

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD STATISTICS

  //LOAD TRANSACTIONS
  const loadTransactions = () => {
    const formData = new FormData();
    formData.append("loadTransactions", "load");
    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/transactions.php", true);
    http.onload = function () {
      setTransactions(JSON.parse(this.responseText));

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD TRANSACTIONS

  //PAGINATION
  const itemsPerPage = 10;
  const pagesVisited = pageNumber * itemsPerPage;

  const displayItems = transactions
    .slice(pagesVisited, pagesVisited + itemsPerPage)
    .map((item) => {
      return (
        <Link
          to={"./" + item.ref}
          key={item.ref}
          className='dashboardTransactions'
        >
          <div className='title'>
            <div className='icon'>
              {item.transaction === "Deposit" ||
              item.transaction === "Debit" ? (
                <AccountBalance />
              ) : item.transaction === "Transfer" ? (
                <MoveDown />
              ) : item.transaction === "TV Subscription" ? (
                <Tv />
              ) : item.transaction === "Data" ? (
                <Wifi />
              ) : item.transaction === "Bet Funding" ? (
                <Receipt />
              ) : item.transaction === "Airtime" ? (
                <Smartphone />
              ) : null}
            </div>

            <div className='nameWraper'>
              <div className='name'>{item.transaction}</div>
              <div
                className={
                  item.status === "Successful" || item.status === "Completed"
                    ? "status success"
                    : item.status === "Failed" || item.status === "Cancelled"
                    ? "status failed"
                    : "status pending"
                }
              >
                {item.status}
              </div>
            </div>
          </div>

          <div className='details'>
            <div className='amount'>&#8358;{item.amount}</div>
            <div className='date'>
              <ReactTimeAgo date={item.started} />
            </div>
          </div>
        </Link>
      );
    });

  const pageCount = Math.ceil(transactions.length / itemsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  //END OF PAGINATION

  return (
    <>
      <SearchTransactions
        rootLink={rootLink}
        setContent={setContent}
        content={content}
      />

      <div className='firstWrapper'>
        <div className='backButton'>
          <IconButton onClick={() => navigate("../../")}>
            <ArrowBack />
          </IconButton>
          <h1 className='firstHeader'>Transactions</h1>
        </div>

        <div className='firstContent'>
          <center>
            <h2>Statistics</h2>
          </center>
          <div className='statContainer'>
            <div className='stat'>
              <div className='statHeader'>
                Deposit - &#8358;
                {details.total_deposit ? details.total_deposit : 0}
              </div>
              <div className='statCard'>
                <div className='statItem'>
                  <div className='name'>Successful</div>
                  <div className='amount'>
                    &#8358;
                    {details.successful_deposit
                      ? details.successful_deposit
                      : 0}
                  </div>
                </div>
                <div className='statItem'>
                  <div className='name'>Processing</div>
                  <div className='amount'>
                    &#8358;
                    {details.pending_deposit ? details.pending_deposit : 0}
                  </div>
                </div>
                <div className='statItem'>
                  <div className='name'>Failed</div>
                  <div className='amount'>
                    &#8358;
                    {details.failed_deposit ? details.failed_deposit : 0}
                  </div>
                </div>
              </div>
            </div>
            <div className='stat'>
              <div className='statHeader'>
                Airtime - &#8358;
                {details.total_airtime ? details.total_airtime : 0}
              </div>
              <div className='statCard'>
                <div className='statItem'>
                  <div className='name'>Successful</div>
                  <div className='amount'>
                    &#8358;
                    {details.successful_airtime
                      ? details.successful_airtime
                      : 0}
                  </div>
                </div>
                <div className='statItem'>
                  <div className='name'>Processing</div>
                  <div className='amount'>
                    &#8358;
                    {details.pending_airtime ? details.pending_airtime : 0}
                  </div>
                </div>
                <div className='statItem'>
                  <div className='name'>Failed</div>
                  <div className='amount'>
                    &#8358;
                    {details.failed_airtime ? details.failed_airtime : 0}
                  </div>
                </div>
              </div>
            </div>
            <div className='stat'>
              <div className='statHeader'>
                Data - &#8358;{details.total_data ? details.total_data : 0}
              </div>
              <div className='statCard'>
                <div className='statItem'>
                  <div className='name'>Successful</div>
                  <div className='amount'>
                    &#8358;
                    {details.successful_data ? details.successful_data : 0}
                  </div>
                </div>
                <div className='statItem'>
                  <div className='name'>Processing</div>
                  <div className='amount'>
                    &#8358;{details.pending_data ? details.pending_data : 0}
                  </div>
                </div>
                <div className='statItem'>
                  <div className='name'>Failed</div>
                  <div className='amount'>
                    &#8358;{details.failed_data ? details.failed_data : 0}
                  </div>
                </div>
              </div>
            </div>
            <div className='stat'>
              <div className='statHeader'>
                TV Subscription - &#8358;
                {details.total_tv ? details.total_tv : 0}
              </div>
              <div className='statCard'>
                <div className='statItem'>
                  <div className='name'>Successful</div>
                  <div className='amount'>
                    &#8358;
                    {details.successful_tv ? details.successful_tv : 0}
                  </div>
                </div>
                <div className='statItem'>
                  <div className='name'>Processing</div>
                  <div className='amount'>
                    &#8358;{details.pending_tv ? details.pending_tv : 0}
                  </div>
                </div>
                <div className='statItem'>
                  <div className='name'>Failed</div>
                  <div className='amount'>
                    &#8358;{details.failed_tv ? details.failed_tv : 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {transactions.length > 0 ? (
          <div style={{ marginTop: "30px" }} className='transactionsSection'>
            <h2>Transactions</h2>
            {displayItems}

            <br />

            {transactions.length < itemsPerPage + 1 ? null : (
              <div className='pagination'>
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationContainer"}
                  previousLinkClassName={"prevBtn"}
                  nextLinkClassName={"nextBtn"}
                  activeClassName={"activePagination"}
                />
              </div>
            )}
          </div>
        ) : null}
      </div>
    </>
  );
}

export default TransactionsIndex;
