import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SiteHelmet from "../../components/Helmet/Helmet";
import Loading1 from "../../components/Loading/Loading1";
import LoginPage from "../../components/Login/Login";

function Login({ rootLink }) {
  const [loggedin, setLoggedin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <SiteHelmet page={"Login"} />

      <div style={{ marginTop: "-10px" }}>
        <LoginPage
          rootLink={rootLink}
          setLoggedin={setLoggedin}
          loggedin={loggedin}
          setIsLoading={setIsLoading}
        />
      </div>

      <Loading1 loading={isLoading} />
    </>
  );
}

export default Login;
