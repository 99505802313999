import React, { useState, useEffect } from "react";
import ConfirmTV from "../../../components/modal/User/TV/Confirm";
import Cookies from "js-cookie";
import { ArrowBack } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import SiteHelmet from "../../../components/Helmet/Helmet";
import Loading1 from "../../../components/Loading/Loading1";

function TVIndex({ rootLink, response, setResponse, alert, setAlert }) {
  const [billers, setBillers] = useState([]);
  const [biller, setBiller] = useState("");
  const [packages, setPackage] = useState([]);
  const [packageRef, setPackageRef] = useState("");
  const [card, setCard] = useState("");
  const [amount, setAmount] = useState("");
  const [cardName, setCardName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [smallLoader, setSmallLoader] = useState(false);
  const user = Cookies.get("id");
  const secretKey = Cookies.get("token");
  const navigate = useNavigate();

  useEffect(() => {
    loadBiller();
  }, []);

  //LOAD BILLER
  const loadBiller = () => {
    const formData = new FormData();
    formData.append("loadCables", "load");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/user/tv.php", true);
    http.onload = function () {
      setBillers(JSON.parse(this.responseText));

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD BILLER

  //LOAD PACKAGES
  const loadPackages = (biller) => {
    const formData = new FormData();
    formData.append("loadPackages", biller);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/user/tv.php", true);
    http.onload = function () {
      setPackage(JSON.parse(this.responseText));
    };
    http.send(formData);
  };
  //END OF LOAD PACKAGES

  //LOAD AMOUNT
  const loadAmount = (ref) => {
    const formData = new FormData();
    formData.append("loadPackageDetails", ref);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/user/tv.php", true);
    http.onload = function () {
      setAmount(JSON.parse(this.responseText).price);
      setPackageRef(JSON.parse(this.responseText).ref);
    };
    http.send(formData);
  };
  //END OF LOAD AMOUNT

  //LOAD CARD DETAILS
  const loadCardDetails = () => {
    setCardName("");
    if (card.length >= 8) {
      if (biller === "") {
        const msg = "Biller is required";

        const arr = { status: "error", response: msg };
        setResponse(arr);
        setAlert(true);
      }

      if (packageRef === "") {
        const msg = "Package is required";

        const arr = { status: "error", response: msg };
        setResponse(arr);
        setAlert(true);
      }

      if (amount === "") {
        const msg = "Amount is required";

        const arr = { status: "error", response: msg };
        setResponse(arr);
        setAlert(true);
      }

      if (card === "") {
        const msg = "Smart card number is required";

        const arr = { status: "error", response: msg };
        setResponse(arr);
        setAlert(true);
      }

      if (isNaN(card)) {
        const msg = "Smart card number is invalid";

        const arr = { status: "error", response: msg };
        setResponse(arr);
        setAlert(true);
      }

      if (biller && packageRef && amount && card && !isNaN(card)) {
        setSmallLoader(true);
        const formData = new FormData();
        formData.append("loadCardDetails", card);
        formData.append("biller", biller);

        //AJAX
        let http = new XMLHttpRequest();
        http.open("POST", rootLink + "backend/user/tv.php", true);
        http.onload = function () {
          const jsonData = JSON.parse(this.responseText);

          if (jsonData.status === "success") {
            setCardName(jsonData.response);
          } else {
            setResponse(jsonData);
            setAlert(true);
          }

          setTimeout(() => {
            setSmallLoader(false);
          }, 1000);
        };
        http.send(formData);
      }
    }
  };
  //END OF LOAD CARD DETAILS

  //HANDLE CARD HOLDER
  const handleCardholder = (input) => {
    setCardName("");

    if (input.length >= 10) {
      setSmallLoader(true);
      const formData = new FormData();
      formData.append("loadCardDetails", input);
      formData.append("biller", biller);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/user/tv.php", true);
      http.onload = function () {
        const jsonData = JSON.parse(this.responseText);

        if (jsonData.status === "success") {
          setCardName(jsonData.response);
        }

        setTimeout(() => {
          setSmallLoader(false);
        }, 1000);
      };
      http.send(formData);
    }
  };
  //END OF HANDLE CARD HOLDER

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (biller === "") {
      const msg = "Biller is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (packageRef === "") {
      const msg = "Package is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (amount === "") {
      const msg = "Amount is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (card === "") {
      const msg = "Smart card number is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (isNaN(card)) {
      const msg = "Smart card number is invalid";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (biller && packageRef && amount && card && !isNaN(card) && cardName) {
      setModal(true);
    }
  };
  //END OF FORM SUBMIT

  return (
    <>
      <SiteHelmet page={"TV Subscription"} />

      <div className='firstWrapper'>
        <div className='backButton'>
          <IconButton onClick={() => navigate("../../")}>
            <ArrowBack />
          </IconButton>
          <h1 className='firstHeader'>TV Subscription</h1>
        </div>

        <div className='firstContent padding10'>
          <form onSubmit={formSubmit}>
            <label htmlFor='biller'>Biller</label>
            <select
              onChange={(e) => {
                setBiller(e.target.value);
                loadPackages(e.target.value);
              }}
              name='biller'
            >
              <option value=''>Select Biller</option>
              {billers.map((item) => {
                return (
                  <React.Fragment key={item.ref}>
                    <option disabled={item.disabled === "true" ? true : false}>
                      {item.cable}
                    </option>
                  </React.Fragment>
                );
              })}
            </select>

            <label htmlFor='data'>Package</label>
            <select
              onChange={(e) => {
                setPackageRef(e.target.value);
                loadAmount(e.target.value);
              }}
              name='data'
            >
              <option value=''>Select Package</option>
              {packages.map((item) => {
                return (
                  <React.Fragment key={item.ref}>
                    <option
                      value={item.ref}
                      disabled={item.disabled === "true" ? true : false}
                    >
                      {item.package}
                    </option>
                  </React.Fragment>
                );
              })}
            </select>

            <label htmlFor='amount'>Amount</label>
            <input
              type='number'
              value={amount}
              name='amount'
              placeholder='Amount'
              readOnly
            />

            <label htmlFor='card'>Smart Card Number</label>
            <input
              type='number'
              onChange={(e) => {
                setCard(e.target.value);
                handleCardholder(e.target.value);
              }}
              onBlur={loadCardDetails}
              placeholder='Smart Card Number'
              name='card'
            />

            {!smallLoader ? (
              <div
                className={cardName === "" ? "cardName hide" : "cableName show"}
              >
                {cardName}
              </div>
            ) : (
              <div className='smallLoader'></div>
            )}

            <br />

            <button
              className={
                biller === "" ||
                packageRef === "" ||
                amount === "" ||
                card === "" ||
                isNaN(card) ||
                cardName === ""
                  ? "width100 disabled"
                  : "width100"
              }
              onClick={formSubmit}
              disabled={
                biller &&
                packageRef &&
                amount &&
                card &&
                !isNaN(card) &&
                cardName
                  ? false
                  : true
              }
            >
              Buy
            </button>
          </form>
        </div>

        {modal ? (
          <ConfirmTV
            reference={packageRef}
            card={card}
            modal={modal}
            setModal={setModal}
            setResponse={setResponse}
            setAlert={setAlert}
            rootLink={rootLink}
            user={user}
            secretKey={secretKey}
            cardName={cardName}
          />
        ) : null}
      </div>

      <Loading1 loading={isLoading} />
    </>
  );
}
export default TVIndex;
