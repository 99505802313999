import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

function ModalTV({
  rootLink,
  setResponse,
  setAlert,
  username,
  modal,
  setModal,
  loadDetails,
  loadTransactions,
}) {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [billers, setBillers] = useState([]);
  const [biller, setBiller] = useState("");
  const [packages, setPackage] = useState([]);
  const [packageName, setPackageName] = useState("");
  const [packageCode, setPackageCode] = useState("");
  const [packageRef, setPackageRef] = useState("");
  const [reference, setReference] = useState("");
  const [price, setPrice] = useState("");
  const [cableCode, setCableCode] = useState("");
  const [cableRef, setCableRef] = useState("");
  const [cable, setCable] = useState("");
  const [card, setCard] = useState("");
  const [amount, setAmount] = useState("");
  const [content, setContent] = useState("");
  const [cardName, setCardName] = useState("");
  const [smallLoader, setSmallLoader] = useState(false);
  const [user, setUser] = useState("");
  const [balance, setBalance] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    loadUserDetails();
    loadBiller();
  }, []);

  //LOAD USER DETAILS
  const loadUserDetails = () => {
    const formData = new FormData();
    formData.append("loadUserDetails", username);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/checker.php", true);
    http.onload = function () {
      setBalance(JSON.parse(this.responseText).balance);
      setSecretKey(JSON.parse(this.responseText).secret_key);
      setUser(JSON.parse(this.responseText).user_id);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD USER DETAILS

  //LOAD BILLER
  const loadBiller = () => {
    const formData = new FormData();
    formData.append("loadCables", "load");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/user/tv.php", true);
    http.onload = function () {
      setBillers(JSON.parse(this.responseText));

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD BILLER

  //LOAD PACKAGES
  const loadPackages = (biller) => {
    const formData = new FormData();
    formData.append("loadPackages", biller);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/user/tv.php", true);
    http.onload = function () {
      setPackage(JSON.parse(this.responseText));
    };
    http.send(formData);
  };
  //END OF LOAD PACKAGES

  //LOAD AMOUNT
  const loadAmount = (ref) => {
    const formData = new FormData();
    formData.append("loadPackageDetails", ref);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/user/tv.php", true);
    http.onload = function () {
      setAmount(JSON.parse(this.responseText).price);
      setPackageRef(JSON.parse(this.responseText).ref);
    };
    http.send(formData);
  };
  //END OF LOAD AMOUNT

  //LOAD PACKAGE DETAILS
  const loadPackageDetails = () => {
    const formData = new FormData();
    formData.append("loadPackageDetails", reference);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/user/tv.php", true);
    http.onload = function () {
      setCableCode(JSON.parse(this.responseText).cable_code);
      setCableRef(JSON.parse(this.responseText).ref);
      setPrice(JSON.parse(this.responseText).price);
      setPackageCode(JSON.parse(this.responseText).code);
      setPackageName(JSON.parse(this.responseText).package);
      setCable(JSON.parse(this.responseText).cable);

      setTimeout(() => {
        setContent(JSON.parse(this.responseText).data_plan);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD PACKAGE DETAILS

  //LOAD CARD DETAILS
  const loadCardDetails = () => {
    setCardName("");
    if (card.length >= 8) {
      if (biller === "") {
        const msg = "Biller is required";

        const arr = { status: "error", response: msg };
        setResponse(arr);
        setAlert(true);
      }

      if (packageRef === "") {
        const msg = "Package is required";

        const arr = { status: "error", response: msg };
        setResponse(arr);
        setAlert(true);
      }

      if (amount === "") {
        const msg = "Amount is required";

        const arr = { status: "error", response: msg };
        setResponse(arr);
        setAlert(true);
      }

      if (card === "") {
        const msg = "Smart card number is required";

        const arr = { status: "error", response: msg };
        setResponse(arr);
        setAlert(true);
      }

      if (isNaN(card)) {
        const msg = "Smart card number is invalid";

        const arr = { status: "error", response: msg };
        setResponse(arr);
        setAlert(true);
      }

      if (biller && packageRef && amount && card && !isNaN(card)) {
        setSmallLoader(true);
        const formData = new FormData();
        formData.append("loadCardDetails", card);
        formData.append("biller", biller);

        //AJAX
        let http = new XMLHttpRequest();
        http.open("POST", rootLink + "backend/user/tv.php", true);
        http.onload = function () {
          const jsonData = JSON.parse(this.responseText);

          if (jsonData.status === "success") {
            setCardName(jsonData.response);
          } else {
            setResponse(jsonData);
            setAlert(true);
          }

          setTimeout(() => {
            setSmallLoader(false);
          }, 1000);
        };
        http.send(formData);
      }
    }
  };
  //END OF LOAD CARD DETAILS

  //HANDLE CARD HOLDER
  const handleCardholder = (input) => {
    setCardName("");

    if (input.length >= 10) {
      setSmallLoader(true);
      const formData = new FormData();
      formData.append("loadCardDetails", input);
      formData.append("biller", biller);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/user/tv.php", true);
      http.onload = function () {
        const jsonData = JSON.parse(this.responseText);

        if (jsonData.status === "success") {
          setCardName(jsonData.response);
        }

        setTimeout(() => {
          setSmallLoader(false);
        }, 1000);
      };
      http.send(formData);
    }
  };
  //END OF HANDLE CARD HOLDER

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (biller === "") {
      const msg = "Biller is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (packageRef === "") {
      const msg = "Package is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (amount === "") {
      const msg = "Amount is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (amount > balance) {
      const msg = "Insufficient fund";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (card === "") {
      const msg = "Smart card number is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (isNaN(card)) {
      const msg = "Smart card number is invalid";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (
      biller &&
      packageRef &&
      amount &&
      amount <= balance &&
      card &&
      !isNaN(card) &&
      cardName
    ) {
      setLoadingBtn(true);

      const formData = new FormData();
      formData.append("tv_package", reference);
      formData.append("card_number", card);
      formData.append("user_id", user);
      formData.append("package_code", packageCode);
      formData.append("cable_code", cableCode);
      formData.append("cardName", cardName);
      formData.append("api_key", secretKey);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "api/tv.php", true);
      http.onload = function () {
        setResponse(JSON.parse(this.responseText));
        setModal(false);
        setAlert(true);
        setLoadingBtn(false);
      };
      http.send(formData);
    }
  };
  //END OF FORM SUBMIT

  return (
    <Dialog open={modal} fullWidth>
      <DialogTitle sx={{ textAlign: "center" }}>TV Subscription</DialogTitle>

      <>
        <DialogContent>
          <label htmlFor='biller'>Biller</label>
          <select
            onChange={(e) => {
              setBiller(e.target.value);
              loadPackages(e.target.value);
            }}
            name='biller'
          >
            <option value=''>Select Biller</option>
            {billers.map((item) => {
              return (
                <React.Fragment key={item.ref}>
                  <option disabled={item.disabled === "true" ? true : false}>
                    {item.cable}
                  </option>
                </React.Fragment>
              );
            })}
          </select>

          <label htmlFor='data'>Package</label>
          <select
            onChange={(e) => {
              setPackageRef(e.target.value);
              setReference(e.target.value);
              loadAmount(e.target.value);
              loadPackageDetails();
            }}
            name='data'
          >
            <option value=''>Select Package</option>
            {packages.map((item) => {
              return (
                <React.Fragment key={item.ref}>
                  <option
                    value={item.ref}
                    disabled={item.disabled === "true" ? true : false}
                  >
                    {item.package}
                  </option>
                </React.Fragment>
              );
            })}
          </select>

          <label htmlFor='amount'>Amount</label>
          <input
            type='number'
            value={amount}
            name='amount'
            placeholder='Amount'
            readOnly
          />

          <label htmlFor='card'>Smart Card Number</label>
          <input
            type='number'
            onChange={(e) => {
              setCard(e.target.value);
              handleCardholder(e.target.value);
            }}
            onBlur={loadCardDetails}
            placeholder='Smart Card Number'
            name='card'
          />

          {!smallLoader ? (
            <div
              className={cardName === "" ? "cardName hide" : "cableName show"}
            >
              {cardName}
            </div>
          ) : (
            <div className='smallLoader'></div>
          )}

          <DialogActions
            sx={{
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Button
              color='error'
              variant='contained'
              onClick={() => {
                setModal(false);
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loadingBtn ? true : false}
              onClick={formSubmit}
              variant='contained'
              disabled={
                biller &&
                packageRef &&
                amount &&
                amount <= balance &&
                card &&
                !isNaN(card) &&
                cardName
                  ? false
                  : true
              }
            >
              Pay
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </>
    </Dialog>
  );
}

export default ModalTV;
