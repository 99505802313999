import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

function ModalBetting({
  rootLink,
  setResponse,
  setAlert,
  username,
  modal,
  setModal,
  loadDetails,
  loadTransactions,
}) {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState("");
  const [companyCode, setCompanyCode] = useState("");
  const [companyRef, setCompanyRef] = useState("");
  const [customerID, setCustomerID] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [content, setContent] = useState("");
  const [amount, setAmount] = useState("");
  const [smallLoader, setSmallLoader] = useState(false);
  const [user, setUser] = useState("");
  const [balance, setBalance] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    loadUserDetails();
    loadCompanies();
  }, []);

  //LOAD USER DETAILS
  const loadUserDetails = () => {
    const formData = new FormData();
    formData.append("loadUserDetails", username);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/checker.php", true);
    http.onload = function () {
      setBalance(JSON.parse(this.responseText).balance);
      setSecretKey(JSON.parse(this.responseText).secret_key);
      setUser(JSON.parse(this.responseText).user_id);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD USER DETAILS

  //LOAD COMPANIES
  const loadCompanies = () => {
    const formData = new FormData();
    formData.append("loadCompanies", "load");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/user/betting.php", true);
    http.onload = function () {
      setCompanies(JSON.parse(this.responseText));
      setCompanyCode(JSON.parse(this.responseText).company_code);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD COMPANIES

  //LOAD CUSTOMER DETAILS
  const loadCustomerDetails = () => {
    setCustomerName("hello");
    if (customerID.length >= 5) {
      if (company === "") {
        const msg = "Betting company is required";

        const arr = { status: "error", response: msg };
        setResponse(arr);
        setAlert(true);
      }

      if (customerID === "") {
        const msg = "Customer ID is required";

        const arr = { status: "error", response: msg };
        setResponse(arr);
        setAlert(true);
      }

      if (amount === "") {
        const msg = "Amount is required";

        const arr = { status: "error", response: msg };
        setResponse(arr);
        setAlert(true);
      }

      if (isNaN(amount)) {
        const msg = "Amount is invalid";

        const arr = { status: "error", response: msg };
        setResponse(arr);
        setAlert(true);
      }

      if (isNaN(customerID)) {
        const msg = "Customer ID is invalid";

        const arr = { status: "error", response: msg };
        setResponse(arr);
        setAlert(true);
      }

      if (
        company &&
        customerID &&
        amount &&
        amount <= balance &&
        !isNaN(amount) &&
        !isNaN(customerID)
      ) {
        setSmallLoader(true);
        const formData = new FormData();
        formData.append("loadCustomerDetails", customerID);
        formData.append("company", company);

        //AJAX
        let http = new XMLHttpRequest();
        http.open("POST", rootLink + "backend/user/betting.php", true);
        http.onload = function () {
          const jsonData = JSON.parse(this.responseText);

          if (jsonData.status === "success") {
            setCustomerName(jsonData.response);
          } else {
            setResponse(jsonData);
            setAlert(true);
          }

          setTimeout(() => {
            setSmallLoader(false);
          }, 1000);
        };
        http.send(formData);
      }
    }
  };
  //END OF LOAD CUSTOMER DETAILS

  //HANDLE CUSTOMER NAME
  const handleCustomerName = (input) => {
    setCustomerName("");

    if (input.length >= 5) {
      setSmallLoader(true);
      const formData = new FormData();
      formData.append("loadCustomerDetails", input);
      formData.append("company", company);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/user/betting.php", true);
      http.onload = function () {
        const jsonData = JSON.parse(this.responseText);

        if (jsonData.status === "success") {
          setCustomerName(jsonData.response);
        }

        setTimeout(() => {
          setSmallLoader(false);
        }, 1000);
      };
      http.send(formData);
    }
  };
  //END OF HANDLE CUSTOMER NAME

  //LOAD BETTING COMPANY
  const loadBettingDetails = () => {
    const formData = new FormData();
    formData.append("loadThisCompany", company);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/user/betting.php", true);
    http.onload = function () {
      setCompanyCode(JSON.parse(this.responseText).company_code);
      setCompanyRef(JSON.parse(this.responseText).ref);

      setTimeout(() => {
        setContent(JSON.parse(this.responseText).data_plan);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD BETTING COMPANY

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (company === "") {
      const msg = "Betting company is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (isNaN(amount)) {
      const msg = "Amount is invalid";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (amount === "") {
      const msg = "Amount is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (amount < 50) {
      const msg = "Minimum amount is 50";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (amount > balance) {
      const msg = "Insufficient fund";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (customerID === "") {
      const msg = "Customer ID is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (company && !isNaN(amount) && amount && amount >= 50 && customerID) {
      setLoadingBtn(true);

      const formData = new FormData();
      formData.append("FundBet", "Fund");
      formData.append("company", company);
      formData.append("company_code", companyCode);
      formData.append("amount", amount);
      formData.append("customer_id", customerID);
      formData.append("customer_name", customerName);
      formData.append("user_id", user);
      formData.append("api_key", secretKey);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "api/betting.php", true);
      http.onload = function () {
        setResponse(JSON.parse(this.responseText));
        setModal(false);
        setAlert(true);
        setLoadingBtn(false);
        loadDetails();
        loadTransactions();
      };
      http.send(formData);
    }
  };
  //END OF FORM SUBMIT

  return (
    <Dialog open={modal} fullWidth>
      <DialogTitle sx={{ textAlign: "center" }}>Bet Funding</DialogTitle>

      <>
        <DialogContent>
          <label htmlFor='company'>Betting Company</label>
          <select
            onChange={(e) => {
              setCompany(e.target.value);
              loadBettingDetails();
            }}
            name='company'
          >
            <option value=''>Select Betting Company</option>
            {companies.map((item) => {
              return (
                <React.Fragment key={item.ref}>
                  <option disabled={item.disabled === "true" ? true : false}>
                    {item.company}
                  </option>
                </React.Fragment>
              );
            })}
          </select>

          <label htmlFor='amount'>Amount</label>
          <input
            type='number'
            onChange={(e) => setAmount(e.target.value)}
            placeholder='Amount'
            name='amount'
          />

          <label htmlFor='customerID'>Customer ID</label>
          <input
            type='text'
            onChange={(e) => {
              setCustomerID(e.target.value);
              handleCustomerName(e.target.value);
            }}
            onBlur={loadCustomerDetails}
            placeholder='Customer ID'
            name='customerID'
          />

          {!smallLoader ? (
            <div
              className={
                customerName === "" ? "cardName hide" : "cableName show"
              }
            >
              {customerName}
            </div>
          ) : (
            <div className='smallLoader'></div>
          )}

          <DialogActions
            sx={{
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Button
              color='error'
              variant='contained'
              onClick={() => {
                setModal(false);
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loadingBtn ? true : false}
              onClick={formSubmit}
              variant='contained'
              disabled={
                company &&
                customerID &&
                amount &&
                amount <= balance &&
                !isNaN(amount) &&
                !isNaN(customerID)
                  ? false
                  : true
              }
            >
              Pay
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </>
    </Dialog>
  );
}

export default ModalBetting;
