import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import PaystackPop from "@paystack/inline-js";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { IconButton, Alert, AlertTitle } from "@mui/material";
import ConfirmDeposit from "../../../components/modal/User/Deposit/Confirm";
import { Helmet } from "react-helmet";
import SiteHelmet from "../../../components/Helmet/Helmet";
import Loading1 from "../../../components/Loading/Loading1";
import { LoadingButton } from "@mui/lab";

function BankDeposit({ rootLink, response, setResponse, alert, setAlert }) {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();
  const [details, setDetails] = useState([]);
  const [loader, setLoader] = useState(false);
  const user = Cookies.get("id");
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [nin, setNin] = useState([""]);
  const [bvn, setBvn] = useState([""]);

  useEffect(() => {
    const formData = new FormData();
    formData.append("userDetails", user);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/checker.php", true);
    http.onload = function () {
      setDetails(JSON.parse(this.responseText));
      setUsername(JSON.parse(this.responseText).username);
      setUserId(JSON.parse(this.responseText).user_id);
      setEmail(JSON.parse(this.responseText).email);
      setFullName(
        JSON.parse(this.responseText).first_name +
          " " +
          JSON.parse(this.responseText).last_name
      );

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  }, []);

  //GENERATE ACCOUNT NUMBER
  const generateAccount = () => {
    setLoader(true);

    const formData = new FormData();
    formData.append("generateAccount", "generate");
    formData.append("email", email);
    formData.append("fullName", fullName);
    formData.append("bvn", bvn);
    formData.append("nin", nin);
    formData.append("userId", userId);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/generate.php", true);
    http.onload = function () {
      if (JSON.parse(this.responseText).status === "error") {
        setResponse(JSON.parse(this.responseText));
        setAlert(true);
      } else {
        setDetails(JSON.parse(this.responseText));
      }

      setTimeout(() => {
        setLoader(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF GENERATE ACCOUNT NUMBER

  return (
    <>
      <SiteHelmet page={"Account Details"} />

      <div className='firstWrapper'>
        <center>
          <h1>Account Details</h1>
        </center>

        <div className='firstContent padding10'>
          {details.account_number !== "" ? (
            <div style={{ fontSize: "18px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  gap: "10px",
                  margin: "10px",
                }}
              >
                <div>Account Number:</div>
                <div style={{ fontWeight: "bold", fontSize: "22px" }}>
                  {details.account_number}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  gap: "10px",
                  margin: "10px",
                }}
              >
                <div>Bank:</div>
                <div style={{ fontWeight: "bold", fontSize: "22px" }}>
                  {details.bank}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  gap: "10px",
                  margin: "10px",
                }}
              >
                <div>Account Name:</div>
                <div style={{ fontWeight: "bold", fontSize: "22px" }}>
                  MFY / Zicopawa Enterprise
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className='firstContent padding10'>
                <form onSubmit={generateAccount}>
                  <label htmlFor='bvn'>BVN</label>
                  <input
                    type='number'
                    onChange={(e) => setBvn(e.target.value)}
                    placeholder='BVN'
                    required
                  />

                  <label htmlFor='nin'>NIN</label>
                  <input
                    type='number'
                    onChange={(e) => setNin(e.target.value)}
                    placeholder='NIN'
                    required
                  />

                  <LoadingButton
                    className={"width100"}
                    disabled={
                      nin.length !== 11 || bvn.length !== 11 ? true : false
                    }
                    loading={loader ? true : false}
                    onClick={generateAccount}
                    variant='contained'
                  >
                    Create Account
                  </LoadingButton>
                </form>
              </div>
            </>
          )}
        </div>

        <Alert sx={{ marginTop: "30px" }} severity='info'>
          <AlertTitle>
            <strong>Note</strong>
          </AlertTitle>
          <font color='red'>
            <strong>-</strong> BVN and NIN are compulsory.
          </font>
          <br />
          <font color='red'>
            <strong>-</strong> Using wrong BVN and NIN may will lead to loss of
            fund and banning of account.
          </font>
          <br />
          <strong>-</strong> USSD Code to retrieve NIN - <strong>*346#</strong>
          <br />
          <strong>-</strong> USSD Code to retrieve BVN -{" "}
          <strong>*565*0#</strong>
          <br />
          <strong>-</strong> Minimum deposit amount -{" "}
          <strong>&#8358;100</strong>
          <br />
          <strong>-</strong> Transaction fee of 1.1% will be charged from any
          amount you send to this account.
          <br />
          <strong>-</strong> Money sent to this account can not be withdrawn. It
          can only be used for transactions on this platform.
        </Alert>
      </div>

      <Loading1 loading={isLoading} />
    </>
  );
}

export default BankDeposit;
