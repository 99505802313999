import {
  Email,
  Facebook,
  Instagram,
  LocationOn,
  Phone,
  Telegram,
  Twitter,
  WhatsApp,
} from "@mui/icons-material";
import React, { useState, useLayoutEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

function Footer({ rootLink, margin }) {
  const [content, setContent] = useState([]);
  const navigate = useNavigate();
  const year = new Date();

  useLayoutEffect(() => {
    loadContent();
  }, []);

  //LOAD CONTENT
  const loadContent = () => {
    const formData = new FormData();
    formData.append("loadFooterContent", "load");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/checker.php", true);
    http.onload = function () {
      setContent(JSON.parse(this.responseText));
    };
    http.send(formData);
  };
  //END OF LOAD CONTENT

  return (
    <>
      <div className='footer'>
        <h2
          className={
            margin === "general" ? "footerHeader general" : "footerHeader"
          }
        >
          ZICOPAWA DATA HUB
        </h2>
        <p className='footerAbout'>
          We offer fast and seamless services, at the best price in the market.
        </p>

        <div
          className={
            margin === "general" ? "footerContent general" : "footerContent"
          }
        >
          {content.length > 0 ? (
            <>
              <div className='contact'>
                <h3
                  onClick={() => {
                    navigate("/contact");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Contact Us
                </h3>
                <div className='contactContent'>
                  {content[2].name !== "" ? (
                    <div className='item'>
                      <LocationOn />
                      <p>{content[2].name}</p>
                    </div>
                  ) : null}

                  {content[1].name !== "" ? (
                    <div className='item'>
                      <Phone />
                      <p>
                        <a href={"tel:" + content[1].name}>{content[1].name}</a>
                      </p>
                    </div>
                  ) : null}

                  {content[0].name !== "" ? (
                    <div className='item'>
                      <Email />
                      <p>
                        <a href={"mailto:" + content[0].name}>
                          {content[0].name}
                        </a>
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className='socialContent'>
                {content.map((item) => {
                  return (
                    <React.Fragment key={item.id}>
                      {item.link !== "" ? (
                        <a
                          className={
                            item.platform === "whatsapp"
                              ? "whatsapp"
                              : item.platform === "facebook"
                              ? "facebook"
                              : item.platform === "twitter"
                              ? "twitter"
                              : item.platform === "telegram"
                              ? "telegram"
                              : item.platform === "instagram"
                              ? "instagram"
                              : null
                          }
                          href={item.link}
                        >
                          {item.platform === "whatsapp" ? (
                            <WhatsApp />
                          ) : item.platform === "facebook" ? (
                            <Facebook />
                          ) : item.platform === "twitter" ? (
                            <Twitter />
                          ) : item.platform === "telegram" ? (
                            <Telegram />
                          ) : item.platform === "instagram" ? (
                            <Instagram />
                          ) : null}
                        </a>
                      ) : null}
                    </React.Fragment>
                  );
                })}
              </div>
            </>
          ) : null}
          <div className='service'>
            <h3>Services</h3>
            <div className='serviceContent'>
              <Link to='/user/data'>Data</Link>
              <Link to='/user/airtime'>Airtime</Link>
              <Link to='/user/tv'>TV Subscription</Link>
              <Link to='/user/betting'>Bet Funding</Link>
            </div>
          </div>
        </div>

        <div
          className={margin === "general" ? "developer general" : "developer"}
        >
          Designed and developed by{" "}
          <a
            style={{ color: "red", fontWeight: "bold" }}
            href='https://web.facebook.com/ageeedev/'
          >
            Ageee Dev
          </a>
          <br />
          <br />
          &copy; {year.getFullYear()} ZICOPAWA |{" "}
          <a href='/#/terms'>Terms of Service</a> |{" "}
          <a href='/#/policy'>Privacy Policy</a>
        </div>
      </div>
    </>
  );
}

export default Footer;
