import React, { useState, useRef, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

function EditUpdate({
  rootLink,
  setResponse,
  setAlert,
  loadUpdates,
  modal,
  setModal,
  id,
}) {
  const [link, setLink] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadContent();
  }, []);

  //LOAD CONTENT
  const loadContent = () => {
    const formData = new FormData();
    formData.append("loadUpdate", id);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/updates.php", true);
    http.onload = function () {
      setLink(JSON.parse(this.responseText).link);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //LOAD CONTENT

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    setLoadingBtn(true);
    const formData = new FormData();

    formData.append("EditUpdate", id);
    formData.append("link", link);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/updates.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);

      setResponse(jsonData);
      loadUpdates();
      setModal(false);
      setAlert(true);
      setLoadingBtn(false);
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF FORM SUBMIT

  return (
    <Dialog open={modal} fullWidth>
      <DialogTitle sx={{ textAlign: "center" }}>Edit Image</DialogTitle>

      <>
        <DialogContent>
          <label htmlFor='link'>Link</label>
          <input
            type='text'
            onChange={(e) => setLink(e.target.value)}
            value={link}
            name='link'
            placeholder='Link'
          />

          <DialogActions
            sx={{
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Button
              color='error'
              variant='contained'
              onClick={() => {
                setModal(false);
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loadingBtn ? true : false}
              onClick={formSubmit}
              variant='contained'
            >
              Edit
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </>
    </Dialog>
  );
}

export default EditUpdate;
