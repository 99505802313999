import React from "react";
import { Routes, Route } from "react-router-dom";
import AirtimeManagerIndex from ".";

function AirtimeManagerRouter({
  rootLink,
  response,
  setResponse,
  alert,
  setAlert,
}) {
  return (
    <Routes>
      <Route
        path='/'
        element={
          <AirtimeManagerIndex
            rootLink={rootLink}
            response={response}
            setResponse={setResponse}
            alert={alert}
            setAlert={setAlert}
          />
        }
      />
    </Routes>
  );
}

export default AirtimeManagerRouter;
