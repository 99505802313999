import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import PaystackPop from "@paystack/inline-js";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { IconButton, Alert, AlertTitle } from "@mui/material";
import ConfirmDeposit from "../../../components/modal/User/Deposit/Confirm";
import { Helmet } from "react-helmet";
import SiteHelmet from "../../../components/Helmet/Helmet";
import Loading1 from "../../../components/Loading/Loading1";

function DepositIndex({ rootLink, response, setResponse, alert, setAlert }) {
  const [amount, setAmount] = useState("");
  const [method, setMethod] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const [details, setDetails] = useState([]);
  const user = Cookies.get("id");
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    const formData = new FormData();
    formData.append("userDetails", user);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/checker.php", true);
    http.onload = function () {
      setDetails(JSON.parse(this.responseText));
      setUsername(JSON.parse(this.responseText).username);
      setEmail(JSON.parse(this.responseText).email);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  }, [user]);

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (method === "Card") {
      if (amount === "") {
        const msg = "Amount is required";

        const arr = { status: "error", response: msg };
        setResponse(arr);
        setAlert(true);
      }

      if (amount < 100) {
        const msg = "Minimum deposit amount is 100";

        const arr = { status: "error", response: msg };
        setResponse(arr);
        setAlert(true);
      }

      if (isNaN(amount)) {
        const msg = "Amount is invalid";

        const arr = { status: "error", response: msg };
        setResponse(arr);
        setAlert(true);
      }

      if (method === "") {
        const msg = "Select a payment method";

        const arr = { status: "error", response: msg };
        setResponse(arr);
        setAlert(true);
      }
      if (amount && amount >= 100 && !isNaN(amount) && method) {
        setModal(true);
      }
    } else {
      navigate("/user/deposit/bank");
    }
  };
  //END OF FORM SUBMIT

  return (
    <>
      <SiteHelmet page={"Deposit"} />

      <div className='firstWrapper'>
        <div className='backButton'>
          <IconButton onClick={() => navigate("../../")}>
            <ArrowBack />
          </IconButton>
          <h1 className='firstHeader'>Deposit Fund</h1>
        </div>

        <div className='firstContent padding10'>
          <form onSubmit={formSubmit}>
            <label htmlFor='amount'>Amount</label>
            <input
              type='number'
              onChange={(e) => setAmount(e.target.value)}
              placeholder='Amount'
            />

            <label htmlFor='method'>Payment Method</label>
            <select onChange={(e) => setMethod(e.target.value)}>
              <option>Select payment method</option>
              <option value='Card'>Card Payment (Paystack)</option>
              <option value='Bank Transfer'>Internet/Bank Transfer</option>
            </select>
            <button
              className={
                amount === "" || method === "" || isNaN(amount) || amount < 100
                  ? "width100 disabled"
                  : "width100"
              }
              disabled={
                method && amount && amount >= 100 && !isNaN(amount)
                  ? false
                  : true
              }
              onClick={formSubmit}
            >
              Deposit
            </button>
          </form>
        </div>

        <Alert sx={{ marginTop: "30px" }} severity='info'>
          <AlertTitle>
            <strong>Transaction Fees (Card Deposit)</strong>
          </AlertTitle>
          <strong>-</strong> Minimum deposit amount -{" "}
          <strong>&#8358;100</strong>
          <br />
          <strong>-</strong> For amount below &#8358;2500 - <strong>2%</strong>
          <br />
          <strong>-</strong> For &#8358;2500 and above -{" "}
          <strong>2% + &#8358;100</strong>
        </Alert>

        {modal ? (
          <ConfirmDeposit
            rootLink={rootLink}
            modal={modal}
            setModal={setModal}
            username={username}
            email={email}
            amount={amount}
            method={method}
            setAlert={setAlert}
            setResponse={setResponse}
          />
        ) : null}
      </div>

      <Loading1 loading={isLoading} />
    </>
  );
}

export default DepositIndex;
