import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Wifi,
  Smartphone,
  Tv,
  Receipt,
  History,
  Message,
  Campaign,
  Email,
  LinkOff,
  People,
  Slideshow,
  Gavel,
  Policy,
} from "@mui/icons-material";
import Loading1 from "../../components/Loading/Loading1";

function AdminIndex({ rootLink, response, setResponse, alert, setAlert }) {
  const [users, setUsers] = useState("");
  const [api, setApi] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadBalances();
  }, []);

  //LOAD ADMIN DASHBOARD BALANCE
  const loadBalances = () => {
    const formData = new FormData();
    formData.append("loadAdminBalance", "load");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/checker.php", true);
    http.onload = function () {
      setUsers(JSON.parse(this.responseText).users);
      setApi(JSON.parse(this.responseText).api);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD ADMIN DASHBOARD BALANCE

  //HIDE BALANCE
  const hide = () => {
    if (api && users) {
      setApi("");
      setUsers("");
    } else {
      loadBalances();
    }
  };
  //END OF HIDE BALANCE

  return (
    <div className='adminDashboard'>
      <h1>Admin Dashboard</h1>

      <div className='infoCard'>
        <div className='info'>
          <p>API Balance</p>
          <div onClick={hide} className='amount'>
            {api ? api !== "" ? <>&#8358;{api}</> : "*****" : "*****"}
          </div>
        </div>
        <div className='info'>
          <p>Users Balance</p>
          <div onClick={hide} className='amount'>
            {users ? <>&#8358;{users}</> : "*****"}
          </div>
        </div>
      </div>

      <div className='linkCards'>
        <Link className='linkCard' to={"./data"}>
          <Wifi />
          <p>Data Manager</p>
        </Link>

        <Link className='linkCard' to={"./airtime"}>
          <Smartphone />
          <p>Airtime Manager</p>
        </Link>

        <Link className='linkCard' to={"./tv"}>
          <Tv />
          <p>TV Manager</p>
        </Link>

        <Link className='linkCard' to={"./betting"}>
          <Receipt />
          <p>Betting Manager</p>
        </Link>

        <Link className='linkCard' to={"./transactions"}>
          <History />
          <p>Transactions</p>
        </Link>

        <Link className='linkCard' to={"./users"}>
          <People />
          <p>Users</p>
        </Link>

        <Link className='linkCard' to={"./messages"}>
          <Message />
          <p>Messages</p>
        </Link>

        <Link className='linkCard' to={"./announcement"}>
          <Campaign />
          <p>Announcement</p>
        </Link>

        <Link className='linkCard' to={"./email"}>
          <Email />
          <p>Bulk Email</p>
        </Link>

        <Link className='linkCard' to={"./socials"}>
          <LinkOff />
          <p>Socials</p>
        </Link>

        <Link className='linkCard' to={"./updates"}>
          <Slideshow />
          <p>Image Slider</p>
        </Link>

        <Link className='linkCard' to={"./terms"}>
          <Gavel />
          <p>Terms of Service</p>
        </Link>

        <Link className='linkCard' to={"./policies"}>
          <Policy />
          <p>Privacy Policy</p>
        </Link>
      </div>

      <Loading1 loading={isLoading} />
    </div>
  );
}

export default AdminIndex;
