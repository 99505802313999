import React, { useState, useEffect } from "react";
import Nav from "../../../components/nav/Nav";
import Loading1 from "../../../components/Loading/Loading1";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ArrowBack } from "@mui/icons-material";

function SocialIndex({ rootLink, response, setResponse, alert, setAlert }) {
  const [phone, setPhone] = useState("");
  const [facebook, setFacebook] = useState("");
  const [facebookLink, setFacebookLink] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [whatsappLink, setWhatsappLink] = useState("");
  const [twitter, setTwitter] = useState("");
  const [twitterLink, setTwitterLink] = useState("");
  const [instagram, setInstagram] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [telegram, setTelegram] = useState("");
  const [telegramLink, setTelegramLink] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [ready, setReady] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadSocials();
  }, []);

  //LOAD CONTENT
  const loadSocials = () => {
    const formData = new FormData();
    formData.append("loadSocials", "load");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/socials.php", true);
    http.onload = function () {
      setEmail(JSON.parse(this.responseText)[0].name);
      setPhone(JSON.parse(this.responseText)[1].name);
      setAddress(JSON.parse(this.responseText)[2].name);
      setWhatsapp(JSON.parse(this.responseText)[3].name);
      setWhatsappLink(JSON.parse(this.responseText)[3].link);
      setFacebook(JSON.parse(this.responseText)[4].name);
      setFacebookLink(JSON.parse(this.responseText)[4].link);
      setInstagram(JSON.parse(this.responseText)[5].name);
      setInstagramLink(JSON.parse(this.responseText)[5].link);
      setTwitter(JSON.parse(this.responseText)[6].name);
      setTwitterLink(JSON.parse(this.responseText)[6].link);
      setTelegram(JSON.parse(this.responseText)[7].name);
      setTelegramLink(JSON.parse(this.responseText)[7].link);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD CONTENT

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("saveSocial", "save");
    formData.append("phone", phone);
    formData.append("address", address);
    formData.append("email", email);
    formData.append("whatsapp", whatsapp);
    formData.append("whatsappLink", whatsappLink);
    formData.append("facebook", facebook);
    formData.append("facebookLink", facebookLink);
    formData.append("instagram", instagram);
    formData.append("instagramLink", instagramLink);
    formData.append("twitter", twitter);
    formData.append("twitterLink", twitterLink);
    formData.append("telegram", telegram);
    formData.append("telegramLink", telegramLink);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/socials.php", true);
    http.onload = function () {
      setResponse(JSON.parse(this.responseText));
      setAlert(true);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };

  //END OF FORM SUBMIT

  return (
    <>
      <div className='firstWrapper'>
        <div className='backButton'>
          <IconButton onClick={() => navigate("../../")}>
            <ArrowBack />
          </IconButton>
          <h1 className='firstHeader'>Social Details</h1>
        </div>

        <div className='firstContent'>
          <form onSubmit={formSubmit}>
            <br />
            <h3 style={{ textAlign: "center" }}>Phone Number</h3>
            <label htmlFor='phone'>Phone Number</label>
            <input
              type='number'
              onChange={(e) => setPhone(e.target.value)}
              placeholder='Phone Number'
              name='phone'
              value={phone}
            />

            <br />
            <h3 style={{ textAlign: "center" }}>Address</h3>

            <label htmlFor='address'>Address</label>
            <input
              type='text'
              value={address}
              name='address'
              onChange={(e) => setAddress(e.target.value)}
              placeholder='Address'
            />

            <br />
            <h3 style={{ textAlign: "center" }}>Email</h3>

            <label htmlFor='email'>Email</label>
            <input
              type='text'
              value={email}
              name='email'
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Email'
            />

            <br />
            <h3 style={{ textAlign: "center" }}>WhatsApp</h3>

            <label htmlFor='whatsapp'>WhatsApp Number</label>
            <input
              type='text'
              value={whatsapp}
              name='whatsapp'
              onChange={(e) => setWhatsapp(e.target.value)}
              placeholder='WhatsApp Number'
            />

            <label htmlFor='whatsappLink'>WhatsApp Link</label>
            <input
              type='text'
              value={whatsappLink}
              name='whatsappLink'
              onChange={(e) => setWhatsappLink(e.target.value)}
              placeholder='WhatsApp Link'
            />

            <br />
            <h3 style={{ textAlign: "center" }}>Facebook</h3>

            <label htmlFor='facebook'>Facebook</label>
            <input
              type='text'
              value={facebook}
              name='facebook'
              onChange={(e) => setFacebook(e.target.value)}
              placeholder='Facebook'
            />

            <label htmlFor='facebookLink'>Facebook Link</label>
            <input
              type='text'
              value={facebookLink}
              name='facebookLink'
              onChange={(e) => setFacebookLink(e.target.value)}
              placeholder='Facebook Link'
            />

            <br />
            <h3 style={{ textAlign: "center" }}>Instagram</h3>

            <label htmlFor='instagram'>Instagram</label>
            <input
              type='text'
              value={instagram}
              name='instagram'
              onChange={(e) => setInstagram(e.target.value)}
              placeholder='Instagram'
            />

            <label htmlFor='instagramLink'>Instagram Link</label>
            <input
              type='text'
              value={instagramLink}
              name='instagramLink'
              onChange={(e) => setInstagramLink(e.target.value)}
              placeholder='Instagram Link'
            />

            <br />
            <h3 style={{ textAlign: "center" }}>Twitter</h3>

            <label htmlFor='twitter'>Twitter</label>
            <input
              type='text'
              value={twitter}
              name='twitter'
              onChange={(e) => setTwitter(e.target.value)}
              placeholder='Twitter'
            />

            <label htmlFor='twitterLink'>Twitter Link</label>
            <input
              type='text'
              value={twitterLink}
              name='twitterLink'
              onChange={(e) => setTwitterLink(e.target.value)}
              placeholder='Twitter Link'
            />

            <br />
            <h3 style={{ textAlign: "center" }}>Telegram</h3>

            <label htmlFor='telegram'>Telegram</label>
            <input
              type='text'
              value={telegram}
              name='telegram'
              onChange={(e) => setTelegram(e.target.value)}
              placeholder='Telegram'
            />

            <label htmlFor='telegramLink'>Telegram Link</label>
            <input
              type='text'
              value={telegramLink}
              name='telegramLink'
              onChange={(e) => setTelegramLink(e.target.value)}
              placeholder='Telegram Link'
            />

            <button onClick={formSubmit} className='width100'>
              Save
            </button>
          </form>
        </div>

        <Loading1 loading={isLoading} />
      </div>
    </>
  );
}

export default SocialIndex;
