import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useState, useEffect } from "react";
import Loading1 from "../../Loading/Loading1";

function DisablePlan({
  modal,
  rootLink,
  setResponse,
  setModal,
  setAlert,
  reload,
  reference,
}) {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [plan, setPlan] = useState([]);
  const [action, setAction] = useState("");

  useEffect(() => {
    loadDataPlan();
  }, []);

  //LOAD NETWORK
  const loadDataPlan = () => {
    const formData = new FormData();
    formData.append("loadDataPlan", reference);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/dataPlan.php", true);
    http.onload = function () {
      setTimeout(() => {
        const disable = JSON.parse(this.responseText).disabled;

        if (disable === "false") {
          setAction("true");
        } else {
          setAction("false");
        }

        setPlan(JSON.parse(this.responseText));
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD NETWORK

  //DISABLE
  const deleteItem = () => {
    setLoadingBtn(true);

    const formData = new FormData();
    formData.append("DisablePlan", reference);
    formData.append("action", action);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/dataPlan.php", true);
    http.onload = function () {
      setResponse(JSON.parse(this.responseText));
      setAlert(true);
      setLoadingBtn(false);

      if (JSON.parse(this.responseText).status === "success") {
        reload();
        setModal(false);
      }
    };
    http.send(formData);
  };
  //END OF DISABLE

  return (
    <Dialog open={modal}>
      {plan === "" ? (
        <Loading1 loading={isLoading} />
      ) : (
        <>
          <DialogTitle sx={{ textAlign: "center" }}>
            {action === "true" ? "Disable" : "Enable"} {plan.data_plan} Plan
          </DialogTitle>

          <DialogContent>
            <DialogActions
              sx={{
                justifyContent: "center",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              <Button
                color='error'
                variant='outlined'
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={loadingBtn ? true : false}
                onClick={deleteItem}
                variant='contained'
                color={action === "true" ? "error" : "primary"}
              >
                {action === "true" ? "Disable" : "Enable"}{" "}
              </LoadingButton>
            </DialogActions>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}

export default DisablePlan;
