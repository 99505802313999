import React, { useEffect } from "react";
import Cookies from "js-cookie";
import PaystackPop from "@paystack/inline-js";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";

function ConfirmDeposit({
  rootLink,
  modal,
  setModal,
  username,
  email,
  amount,
  method,
  setAlert,
  setResponse,
}) {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [total, setTotal] = useState(0);
  const [paymentKey, setPaymentKey] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    loadPaymentKey();

    if (amount < 2500) {
      setTotal(amount * 1.02);
    } else {
      setTotal(Math.round(amount * 1.02 + 100));
    }
  }, []);

  //LOAD PAYMENT KEY
  const loadPaymentKey = () => {
    const formData = new FormData();
    formData.append("loadPaymentKey", "load");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/deposit/server.php", true);
    http.onload = function () {
      setPaymentKey(this.responseText);
    };
    http.send(formData);
  };
  //END OF LOAD PAYMENT KEY

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();
    setLoadingBtn(true);

    if (amount === "") {
      const msg = "Amount is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
      setLoadingBtn(false);
    }

    if (amount < 100) {
      const msg = "Minimum deposit amount is 100";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (isNaN(amount)) {
      const msg = "Amount is invalid";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
      setLoadingBtn(false);
    }

    if (method === "") {
      const msg = "Select a payment method";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
      setLoadingBtn(false);
    }
    if (amount && amount >= 100 && !isNaN(amount) && method) {
      setLoadingBtn(true);

      const formData = new FormData();
      formData.append("startDeposit", "Deposit");
      formData.append("amount", amount);
      formData.append("method", method);
      formData.append("username", username);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/deposit/server.php", true);
      http.onload = function () {
        const jsonData = JSON.parse(this.responseText);

        //START PAYMENT
        if (jsonData.status === "success") {
          const ref = jsonData.ref;

          //PAYSTACK
          const paystack = new PaystackPop();

          paystack.newTransaction({
            key: paymentKey,
            amount: total * 100,
            email,
            onSuccess(transaction) {
              let txnRef = transaction.reference;

              const formData1 = new FormData();
              formData1.append("SuccessTxn", ref);
              formData1.append("txnRef", txnRef);
              formData1.append("username", username);

              //AJAX
              let http = new XMLHttpRequest();
              http.open("POST", rootLink + "backend/deposit/server.php", true);
              http.onload = function () {
                const final = JSON.parse(this.responseText);

                setResponse(final);
                setAlert(true);
                setModal(false);

                setTimeout(() => {
                  navigate("/user/transactions/" + final.ref);
                }, 2000);
              };
              http.send(formData1);
              //END OF AJAX
            },
            onCancel() {
              const formData1 = new FormData();
              formData1.append("CancelTxn", ref);
              formData1.append("username", username);

              //AJAX
              let http = new XMLHttpRequest();
              http.open("POST", rootLink + "backend/deposit/server.php", true);
              http.onload = function () {
                const jsonData = JSON.parse(this.responseText);

                setResponse(jsonData);
                setAlert(true);
                setModal(false);
              };
              http.send(formData1);
              //END OF AJAX
            },
          });

          //PAYSTACK
        } else {
          setResponse(jsonData);
          setAlert(true);

          setModal(false);
        }
        //START PAYMENT
      };
      http.send(formData);
    }
  };
  //END OF FORM SUBMIT

  return (
    <Dialog open={modal} fullWidth>
      <DialogContent>
        <center>
          Payment Method
          <h2>{method}</h2>
          Amount
          <h2>&#8358;{amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h2>
          Amount + Charges
          <h2>&#8358;{total}</h2>
        </center>
      </DialogContent>

      <DialogActions
        sx={{
          justifyContent: "center",
          flexWrap: "wrap",
          gap: "5px",
        }}
      >
        <Button
          color='error'
          variant='contained'
          onClick={() => {
            setModal(false);
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={loadingBtn ? true : false}
          variant='contained'
          onClick={formSubmit}
        >
          Continue
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDeposit;
