import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useState, useEffect } from "react";
import Loading1 from "../../../Loading/Loading1";

function ConfirmTransfer({
  modal,
  rootLink,
  setResponse,
  setModal,
  setAlert,
  receiver,
  amount,
  receiverName,
  user,
  username,
}) {
  const [sender, setSender] = useState("");
  const [balance, setBalance] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [content, setContent] = useState("");
  const [secretKey, setSecretKey] = useState("");

  useEffect(() => {
    loadSecretKey();
  }, []);

  //LOAD SECRET KEY
  const loadSecretKey = () => {
    const formData = new FormData();
    formData.append("loadSecretKey", user);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/user/data.php", true);
    http.onload = function () {
      setSecretKey(JSON.parse(this.responseText).secret_key);
      setBalance(JSON.parse(this.responseText).balance);
      setSender(JSON.parse(this.responseText).username);
    };
    http.send(formData);
  };
  //END OF LOAD SECRET KEY

  //TRANSFER FUND
  const continuePurchase = () => {
    if (balance < amount) {
      const msg = "Insufficient fund";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (balance >= amount) {
      setLoadingBtn(true);

      const formData = new FormData();
      formData.append("TranserFund", "Fund");
      formData.append("receiver", receiver);
      formData.append("receiver_name", receiverName);
      formData.append("amount", amount);
      formData.append("sender", sender);
      formData.append("user_id", user);
      formData.append("api_key", secretKey);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/user/transfer.php", true);
      http.onload = function () {
        setResponse(JSON.parse(this.responseText));
        setModal(false);
        setAlert(true);
        setLoadingBtn(false);
      };
      http.send(formData);
    }
  };
  //END OF TRANSFER FUND

  return (
    <Dialog open={modal} fullWidth>
      <DialogContent sx={{ wordWrap: "break-word" }}>
        <center>
          Amount
          <h2>&#8358;{amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h2>
          Receiver's Name
          <h2>{receiverName}</h2>
          Username
          <h2>{receiver}</h2>
        </center>
      </DialogContent>

      <DialogActions
        sx={{
          justifyContent: "center",
          flexWrap: "wrap",
          gap: "5px",
        }}
      >
        <Button
          color='error'
          variant='contained'
          onClick={() => {
            setModal(false);
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={loadingBtn ? true : false}
          variant='contained'
          onClick={continuePurchase}
        >
          Continue
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmTransfer;
