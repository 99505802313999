import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useState, useEffect } from "react";
import Loading1 from "../../../Loading/Loading1";
import { useNavigate } from "react-router-dom";

function ConfirmAirtime({
  modal,
  rootLink,
  setResponse,
  setModal,
  setAlert,
  network,
  amount,
  phone,
  user,
}) {
  const [networkCode, setNetworkCode] = useState("");
  const [networkRef, setNetworkRef] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [content, setContent] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    loadSecretKey();
    loadAirtimeDetails();
  }, []);

  //LOAD SECRET KEY
  const loadSecretKey = () => {
    const formData = new FormData();
    formData.append("loadSecretKey", user);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/user/data.php", true);
    http.onload = function () {
      setSecretKey(JSON.parse(this.responseText).secret_key);

      setTimeout(() => {
        setContent(JSON.parse(this.responseText).data_plan);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD SECRET KEY

  //LOAD AIRTIME NETWORK
  const loadAirtimeDetails = () => {
    const formData = new FormData();
    formData.append("loadThisNetwork", network);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/user/airtime.php", true);
    http.onload = function () {
      setNetworkCode(JSON.parse(this.responseText).network_code);
      setNetworkRef(JSON.parse(this.responseText).ref);
    };
    http.send(formData);
  };
  //END OF LOAD AIRTIME NETWORK

  //PURCHASE AIRTIME
  const continuePurchase = () => {
    setLoadingBtn(true);

    const formData = new FormData();
    formData.append("PurchaseAirtime", "Buy");
    formData.append("network", network);
    formData.append("network_code", networkCode);
    formData.append("amount", amount);
    formData.append("mobile_number", phone);
    formData.append("user_id", user);
    formData.append("api_key", secretKey);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "api/airtime.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);

      setResponse(JSON.parse(this.responseText));
      setModal(false);
      setAlert(true);
      setLoadingBtn(false);

      if (jsonData.status === "success") {
        setTimeout(() => {
          navigate("/user/transactions/" + jsonData.ref);
        }, 3000);
      }
    };
    http.send(formData);
  };
  //END OF PURCHASE AIRTIME

  return (
    <Dialog open={modal} fullWidth>
      <DialogContent>
        <center>
          Network
          <h2>{network}</h2>
          Amount
          <h2>&#8358;{amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h2>
          Phone Number
          <h2>{phone}</h2>
        </center>
      </DialogContent>

      <DialogActions
        sx={{
          justifyContent: "center",
          flexWrap: "wrap",
          gap: "5px",
        }}
      >
        <Button
          color='error'
          variant='contained'
          onClick={() => {
            setModal(false);
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={loadingBtn ? true : false}
          variant='contained'
          onClick={continuePurchase}
        >
          Continue
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmAirtime;
