import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import {
  Phone,
  Smartphone,
  Tv,
  Lightbulb,
  Wifi,
  AccountBalance,
  CurrencyExchange,
  ArrowBack,
  MoveDown,
  Receipt,
} from "@mui/icons-material";
import ReactTimeAgo from "react-time-ago";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import Loading1 from "../../../components/Loading/Loading1";
import ReactPaginate from "react-paginate";

function TransactionsIndex({
  rootLink,
  response,
  setResponse,
  alert,
  setAlert,
}) {
  const [content, setContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const user = Cookies.get("id");
  const navigate = useNavigate();

  useEffect(() => {
    loadTransactions();
  }, []);

  //LOAD TRANSACTIONS
  const loadTransactions = () => {
    const formData = new FormData();
    formData.append("loadTransactions", user);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/user/transactions.php", true);
    http.onload = function () {
      setContent(JSON.parse(this.responseText));

      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    };
    http.send(formData);
  };
  //END OF LOAD TRANSACTIONS

  //PAGINATION
  const itemsPerPage = 15;
  const pagesVisited = pageNumber * itemsPerPage;

  const displayItems = content
    .slice(pagesVisited, pagesVisited + itemsPerPage)
    .map((item) => {
      return (
        <Link
          to={"./" + item.ref}
          key={item.ref}
          className='dashboardTransactions'
        >
          <div className='title'>
            <div className='icon'>
              {item.transaction === "Deposit" ||
              item.transaction === "Debit" ? (
                <AccountBalance />
              ) : item.transaction === "Transfer" ? (
                <MoveDown />
              ) : item.transaction === "TV Subscription" ? (
                <Tv />
              ) : item.transaction === "Data" ? (
                <Wifi />
              ) : item.transaction === "Bet Funding" ? (
                <Receipt />
              ) : item.transaction === "Airtime" ? (
                <Smartphone />
              ) : null}
            </div>

            <div className='nameWraper'>
              <div className='name'>{item.transaction}</div>
              <div
                className={
                  item.status === "Successful" || item.status === "Completed"
                    ? "status success"
                    : item.status === "Failed" || item.status === "Cancelled"
                    ? "status failed"
                    : "status pending"
                }
              >
                {item.status}
              </div>
            </div>
          </div>

          <div className='details'>
            <div className='amount'>&#8358;{item.amount}</div>
            <div className='date'>
              <ReactTimeAgo date={item.started} />
            </div>
          </div>
        </Link>
      );
    });

  const pageCount = Math.ceil(content.length / itemsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  //END OF PAGINATION

  return (
    <div className='firstWrapper'>
      <div className='backButton'>
        <IconButton onClick={() => navigate("../../")}>
          <ArrowBack />
        </IconButton>
        <h1 className='firstHeader'>Transactions</h1>
      </div>

      <div className='firstContent longScroll'>
        {displayItems}

        <br />

        {content.length < itemsPerPage + 1 ? null : (
          <div className='pagination'>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationContainer"}
              previousLinkClassName={"prevBtn"}
              nextLinkClassName={"nextBtn"}
              activeClassName={"activePagination"}
            />
          </div>
        )}
      </div>
      <Loading1 loading={isLoading} />
    </div>
  );
}

export default TransactionsIndex;
