import React from "react";
import { Routes, Route } from "react-router-dom";
import BettingManagerIndex from ".";

function BettingManagerRouter({
  rootLink,
  response,
  setResponse,
  alert,
  setAlert,
}) {
  return (
    <Routes>
      <Route
        path='/'
        element={
          <BettingManagerIndex
            rootLink={rootLink}
            response={response}
            setResponse={setResponse}
            alert={alert}
            setAlert={setAlert}
          />
        }
      />
    </Routes>
  );
}

export default BettingManagerRouter;
