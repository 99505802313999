import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Home } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Helmet } from "react-helmet";
import SiteHelmet from "../../components/Helmet/Helmet";

function PageNotFound({ rootLink, response, setResponse, alert, setAlert }) {
  const navigate = useNavigate();
  return (
    <>
      <SiteHelmet page={"Page not found"} />

      <div className='body404'>
        <h2>Page not found</h2>

        <IconButton
          onClick={() => {
            navigate("/user/");
          }}
        >
          <Home />
        </IconButton>
      </div>
    </>
  );
}

export default PageNotFound;
