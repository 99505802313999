import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  AccountBalance,
  Wifi,
  Smartphone,
  Password,
  Lock,
  LockOpen,
  Update,
  Status,
  ArrowBack,
} from "@mui/icons-material";
import { IconButton, Stack, Button, ButtonGroup } from "@mui/material";
import AdjustBalance from "../../../components/modal/Users/AdjustBalance";
import ResetPassword from "../../../components/modal/Users/ResetPassword";
import BanUser from "../../../components/modal/Users/BanUser";
import UnbanUser from "../../../components/modal/Users/UnbanUser";
import TransactionStatus from "../../../components/modal/Transactions/Status";
import SearchTransactions from "../../../components/modal/Transactions/Search";
import SearchUser from "../../../components/modal/Users/Search";
import Loading1 from "../../../components/Loading/Loading1";

function ViewUserTransaction({
  rootLink,
  response,
  setResponse,
  alert,
  setAlert,
}) {
  const [content, setContent] = useState([]);
  const [details, setDetails] = useState([]);
  const [transaction, setTransaction] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const { username } = useParams();
  const [orderID, setOrderID] = useState("");
  const [balanceModal, setBalanceModal] = useState(false);
  const [status, setStatus] = useState("");
  const [statusModal, setStatusModal] = useState(false);
  const [unbanModal, setUnbanModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadContent();
  }, [id]);

  //LOAD TRANSACTIONS
  const loadContent = () => {
    const formData = new FormData();
    formData.append("loadTransaction", id);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/transactions.php", true);
    http.onload = function () {
      setTransaction(JSON.parse(this.responseText));
      setOrderID(JSON.parse(this.responseText).order_id);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD TRANSACTIONS

  //SATUS
  const openStatus = (input) => {
    setStatusModal(true);
    setStatus(input);
  };
  //END OF STATUS

  return (
    <div className='transactionView'>
      <SearchUser
        rootLink={rootLink}
        setContent={setContent}
        content={content}
      />

      <IconButton onClick={() => navigate("../" + username + "/transactions")}>
        <ArrowBack />
      </IconButton>

      {!transaction ? (
        <center>
          <h2>Error 404</h2>
        </center>
      ) : (
        <>
          <div className='head'>
            <div className='transaction'>{transaction.transaction}</div>
            <div className='amount'>&#8358;{transaction.amount}</div>
            <div
              className={
                transaction.status === "Completed" ||
                transaction.status === "Successful"
                  ? "status success"
                  : transaction.status === "Failed" ||
                    transaction.status === "Cancelled"
                  ? "status failed"
                  : "status"
              }
            >
              {transaction.status}
            </div>
          </div>

          <div className='body'>
            <div className='items'>
              <p>Transaction ID:</p>
              {transaction.ref}
            </div>
            {transaction.method ? (
              <div className='items'>
                <p>Method:</p>
                {transaction.method}
              </div>
            ) : null}
            {transaction.order_id ? (
              <div className='items'>
                <p>Order ID:</p>
                {transaction.order_id}
              </div>
            ) : null}
            {transaction.beneficiary ? (
              transaction.transaction === "Deposit" &&
              transaction.method === "Fund Transfer" ? (
                <div className='items'>
                  <p>Sender:</p>
                  {transaction.beneficiary}
                </div>
              ) : (
                <div className='items'>
                  <p>Beneficiary:</p>
                  {transaction.beneficiary}
                </div>
              )
            ) : null}
            {transaction.receiver && transaction.transaction === "Transfer" ? (
              <div className='items'>
                <p>Receiver's Name:</p>
                {transaction.receiver}
              </div>
            ) : null}

            {transaction.beneficiary ? (
              <div className='items'>
                <p>Beneficiary:</p>
                {transaction.beneficiary}
              </div>
            ) : null}
            {transaction.operator ? (
              <div className='items'>
                <p>Network:</p>
                {transaction.operator}
              </div>
            ) : null}
            {transaction.package ? (
              <div className='items'>
                <p>Data Plan:</p>
                {transaction.package}
              </div>
            ) : null}
            <div className='items'>
              <p>Date:</p>
              {transaction.started}
            </div>
            <div className='items'>
              <p>User:</p>
              <Link to={"../../../admin/users/" + transaction.user}>
                {transaction.user}
              </Link>
            </div>
          </div>

          {transaction.status === "Pending" ||
          transaction.status === "Processing" ? (
            <div className='body'>
              <center>
                <h3>Manual Status Update</h3>

                <ButtonGroup variant='outlined' sx={{ marginTop: "10px" }}>
                  <Button color='error' onClick={() => openStatus("Failed")}>
                    Failed
                  </Button>
                  <Button color='error' onClick={() => openStatus("Cancelled")}>
                    Cancelled
                  </Button>
                  <Button onClick={() => openStatus("Completed")}>
                    Completed
                  </Button>
                </ButtonGroup>
              </center>
            </div>
          ) : null}

          <TransactionStatus
            rootLink={rootLink}
            setResponse={setResponse}
            setAlert={setAlert}
            id={id}
            loadContent={loadContent}
            status={status}
            modal={statusModal}
            setModal={setStatusModal}
            username={transaction.user}
            orderID={orderID}
          />
        </>
      )}

      <Loading1 loading={isLoading} />
    </div>
  );
}

export default ViewUserTransaction;
