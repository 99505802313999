import React, { useState, useEffect } from "react";
import AddCable from "../../../components/modal/TVManager/AddCable";
import Loading1 from "../../../components/Loading/Loading1";
import EditCable from "../../../components/modal/TVManager/EditCable";
import DeleteCable from "../../../components/modal/TVManager/DeleteCable";
import { Link, useNavigate } from "react-router-dom";
import { Delete, Edit, Pause, PlayArrow, ArrowBack } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import DisableCable from "../../../components/modal/TVManager/Disable";

function TVManagerIndex({ rootLink, response, setResponse, alert, setAlert }) {
  const [addCable, setAddCable] = useState(false);
  const [content, setContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [reference, setReference] = useState("");
  const [editCable, setEditCable] = useState(false);
  const [deleteCable, setDeleteCable] = useState(false);
  const [disableCable, setDisableCable] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadCables();
  }, []);

  //LOAD CABLES
  const loadCables = () => {
    const formData = new FormData();
    formData.append("loadCables", "load");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/admin/tvManager.php", true);
    http.onload = function () {
      setContent(JSON.parse(this.responseText));

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    http.send(formData);
  };
  //END OF LOAD CABLES

  //EDIT MODAL
  const openEditModal = (ref) => {
    setReference(ref);
    setEditCable(true);
  };

  //END OF EDIT MODAL

  //DISABLE MODAL
  const openDisableModal = (ref) => {
    setReference(ref);
    setDisableCable(true);
  };

  //END OF DISABLE MODAL

  //DELETE MODAL
  const openDeleteModal = (ref) => {
    setReference(ref);
    setDeleteCable(true);
  };

  //END OF DELETE MODAL

  return (
    <>
      <div className='firstWrapper'>
        <div className='backButton'>
          <IconButton onClick={() => navigate("../../")}>
            <ArrowBack />
          </IconButton>
          <h1 className='firstHeader'>TV Manager</h1>
        </div>
        <div className='firstContent'>
          <div onClick={() => setAddCable(true)} className='addButton'>
            New Cable
          </div>

          {content.length === "" ? (
            <h2 className='center'>No cable is available</h2>
          ) : (
            <>
              {content.map((item) => {
                return (
                  <div key={item.ref} className='listItem'>
                    <div className='left'>
                      <Link to={"../" + item.cable}>
                        <div className='bigText'>{item.cable}</div>
                        <div>Code: {item.cable_code}</div>
                      </Link>
                    </div>
                    <div className='right flex'>
                      <IconButton onClick={() => openEditModal(item.ref)}>
                        <Edit color='primary' />
                      </IconButton>
                      <IconButton onClick={() => openDisableModal(item.ref)}>
                        {item.disabled === "false" ? (
                          <Pause color='primary' />
                        ) : (
                          <PlayArrow />
                        )}
                      </IconButton>
                      <IconButton onClick={() => openDeleteModal(item.ref)}>
                        <Delete color='error' />
                      </IconButton>
                    </div>
                  </div>
                );
              })}
            </>
          )}

          {addCable ? (
            <AddCable
              modal={addCable}
              setModal={setAddCable}
              setResponse={setResponse}
              setAlert={setAlert}
              rootLink={rootLink}
              reload={loadCables}
            />
          ) : null}

          {editCable ? (
            <EditCable
              reference={reference}
              modal={editCable}
              setModal={setEditCable}
              setResponse={setResponse}
              setAlert={setAlert}
              rootLink={rootLink}
              reload={loadCables}
            />
          ) : null}

          {disableCable ? (
            <DisableCable
              reference={reference}
              modal={disableCable}
              setModal={setDisableCable}
              setResponse={setResponse}
              setAlert={setAlert}
              rootLink={rootLink}
              reload={loadCables}
            />
          ) : null}

          {deleteCable ? (
            <DeleteCable
              reference={reference}
              modal={deleteCable}
              setModal={setDeleteCable}
              setResponse={setResponse}
              setAlert={setAlert}
              rootLink={rootLink}
              reload={loadCables}
            />
          ) : null}

          <Loading1 loading={isLoading} />
        </div>
      </div>
    </>
  );
}

export default TVManagerIndex;
