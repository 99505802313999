import { Snackbar, Alert, AlertProps, IconButton } from "@mui/material";
import { forwardRef, useState, useContext, useEffect } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import Person from "@mui/icons-material/Person";
import Menu from "@mui/icons-material/Menu";
import Cookies from "js-cookie";
import { RootLink } from "../../App";

const SnackbarAlert = forwardRef(function SnackbarAlert(props, ref) {
  return <Alert elevation={6} ref={ref} {...props} />;
});

function NavUser({ response, setResponse, alert, setAlert }) {
  const [dropMenu, setDropMenu] = useState(false);
  const [details, setDetails] = useState([]);
  const [sideMenu, setSideMenu] = useState(false);
  const rootLink = useContext(RootLink);
  const navigate = useNavigate();
  const user = Cookies.get("id");

  if (alert === true) {
    setTimeout(() => {
      setAlert(false);
    }, 5000);
  }

  //USER DETAILS
  useEffect(() => {
    const formData = new FormData();
    formData.append("userDetails", user);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/checker.php", true);
    http.onload = function () {
      setDetails(JSON.parse(this.responseText));
    };
    http.send(formData);
  }, [user]);
  //END OF USER DETAILS

  //SWITCH TOGGLE SIDE MENU
  const toggleSideMenu = () => {
    if (sideMenu === true) {
      setSideMenu(false);
    } else {
      setSideMenu(true);
    }
    setDropMenu(false);
  };
  //END OF SWITCH TOGGLE SIDE MENU

  //SWITCH TOGGLE DROP MENU
  const toggleDropMenu = () => {
    if (dropMenu === true) {
      setDropMenu(false);
    } else {
      setDropMenu(true);
    }
    setSideMenu(false);
  };
  //END OF SWITCH TOGGLE DROP MENU

  //LOG OUT
  const logout = () => {
    const msg = "Logged out successfully";

    const arr = { status: "success", response: msg };
    setResponse(arr);
    setAlert(true);

    setTimeout(() => {
      Cookies.remove("token");
      Cookies.remove("id");
      Cookies.remove("ref");
      navigate("/login");
    }, 2000);
  };
  //END OF LOG OUT

  return (
    <>
      <nav className='userNav'>
        <IconButton
          onClick={() => toggleSideMenu()}
          size='large'
          className='adminHamburger'
        >
          <Menu />
        </IconButton>

        <div className='adminLogo' onClick={() => navigate("/user/")}>
          <img src={rootLink + "resources/logo/zicopawa.png"} />
        </div>

        <IconButton className='person' onClick={() => toggleDropMenu()}>
          <Person />
        </IconButton>

        <div className='list'>
          <Link to='/user/deposit' onClick={() => setDropMenu(false)}>
            Deposit
          </Link>
          <Link to='/user/password' onClick={() => setDropMenu(false)}>
            Change Password
          </Link>
          <div
            onClick={() => {
              logout();
              setDropMenu(false);
            }}
          >
            Log Out
          </div>
        </div>
      </nav>

      <div className={dropMenu ? "dropMenu" : "dropMenu disabled"}>
        <Link to='/user/deposit' onClick={() => setDropMenu(false)}>
          Deposit
        </Link>
        <Link to='/user/password' onClick={() => setDropMenu(false)}>
          Change Password
        </Link>
        <div
          onClick={() => {
            logout();
            setDropMenu(false);
          }}
        >
          Log Out
        </div>
      </div>

      <div className={sideMenu ? "sideBar" : "sideBar disabled"}>
        <div className='sideDetails'>
          <div className='person'>
            <Person />
          </div>
          <div className='name' style={{ fontWeight: "bold" }}>
            {details.first_name ? details.first_name : null}{" "}
            {details.last_name ? details.last_name : null}
          </div>
          <div className='name'>
            <i>{details.username ? details.username : null}</i>
          </div>
          <div className='balance'>
            &#8358;{details.balance ? details.balance : 0}
          </div>

          <Link to='/user/deposit' onClick={() => setSideMenu(false)}>
            Deposit
          </Link>
        </div>

        <div className='linkWrapper'>
          <NavLink
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideMenu(false)}
            to='/user/'
          >
            Dashboard
          </NavLink>
          <NavLink
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideMenu(false)}
            to='/user/data'
          >
            Data
          </NavLink>
          <NavLink
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideMenu(false)}
            to='/user/airtime'
          >
            Airtime
          </NavLink>
          <NavLink
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideMenu(false)}
            to='/user/tv'
          >
            TV Subscription
          </NavLink>
          <NavLink
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideMenu(false)}
            to='/user/betting'
          >
            Bet Funding
          </NavLink>
          <NavLink
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideMenu(false)}
            to='/user/transfer'
          >
            Transfer Fund
          </NavLink>
          <NavLink
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideMenu(false)}
            to='/user/transactions'
          >
            Transactions
          </NavLink>
        </div>
      </div>

      <Snackbar
        open={alert}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <SnackbarAlert color={response.status}>
          {response.response}
        </SnackbarAlert>
      </Snackbar>
    </>
  );
}

export default NavUser;
